.statusCard {
    width: 170px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;

    &.orange {
        background-color: #ffe5cc;
        color: #ff7f00;
    }

    &.green {
        background-color: #d4f7dc;
        color: #3bb143;
    }

    &.blue {
        background-color: #e0ecff;
        color: #0056ff;
    }
    &.purple {
        background-color: #80008048;
        color: #800080;
    }
    &.gray {
        background-color: #d3d3d398;
        color: #706e6e;
    }

    &.black {
        background-color: #00000063;
        color: #000000;
    }

    .title {
        margin-right: 5px;
        font-weight: 600;
    }

    .quantity {
        font-weight: bold;
    }
}

@media screen and (max-width: 416px) {
    .statusCard {
        width: 140px;
        font-size: 12px;
    }
}
