@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.CondicoesComerciaisTitle{
    font-family: $font-familyV2;
    font-weight: 500;
    font-size: 20px;
    color: #606060;
    margin-bottom: 24px;
}
.divInfosCondicoesComerciais{
    font-family: $font-familyV2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {

        font-weight: 400;
        font-size: 16px;
        margin-right: 4px;
        color: #707070;
    }
    span {
        font-weight: 300;
        font-size: 15px;
        color: #A1A1A1;
        &#req-id {
            color: #30AA4C;
        }
    }
}
.divInfosCondicoesComerciaisBlock:nth-child(odd){
    @media screen and (max-width: 900px) {
   background-color: rgba(0,0,0,0.06);

}
}
.divInfosCondicoesComerciaisBlock{
    width: 33%;
    @media screen and (max-width: 900px) {
        width: 100%;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        padding: 4px 10px;
}
}

.client-comercial-conditions {
    color: #757575;
    .col {
        display: flex;
        justify-content: flex-start;
        border-radius: 3px;
        margin-right: 1rem;
        margin-left: 5px;
        font-size: 13px;
        label {
            font-weight: 400;
            margin-right: 4px;
        }
        span {
            font-weight: 600;
            line-height: 14px;
            color: $gray-5;
            &#req-id {
                color: $ternary-color;
            }
        }
    }
}
