@import 'styles/_variables';

.header-info-modal {
    background-color: $direct-sale-parameter-header_background;
    padding: 25px 0;

    .name {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        color: $primary-color;
    }

    .lottie {
        width: 5rem;
    }
}

.condition-body {
    margin: 20px 0;

    .form-condition {
        justify-content: flex-start;
        margin: 0 25px;

        .form-label-direct-sale-parameter {
            font-size: 1.3rem;
            color: $direct-sale-parameter-form-label;
        }

        .form-input-direct-sale-parameter {
            margin: 10px 0px;
        }

        .form-select-direct-sale-parameter {
            margin: 10px 0px;
            color: $direct-sale-parameter-form-color-select;
        }

        .form-check-label {
            margin: 15px;
        }

        .save {
            background-color: $direct-sale-parameter-save-button;
            border-color: $direct-sale-parameter-save-button;
            color: $direct-sale-parameter-title-button;
        }
    }
}
.navContainer {
    display: flex;
    gap: 20px;
}
.navItemModal {
    cursor: pointer;
    border: none;
    border-color: none;
    color: #202020 !important;

    font-family: $font-familyV2;
    font-weight: 400;
    padding: 15px 12px;

    &.active {
        border-color: none;
        background-color: $orange-3;
        color: #303030 !important;
        font-weight: 400;
        font-family: $font-familyV2;

        border-radius: 8px;
        padding: 15px 12px;

        svg {
            color: $orange !important;
        }
    }

    &:hover {
        border-radius: 8px;
        border-color: none;
        background-color: $orange-3;
        color: $orange !important;
    }

    svg {
        color: $orange;
        margin-right: 11px !important;
    }

    display: flex;
    align-items: center;
}

.tdContentPart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.listRepresentatives {
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden !important;

    .newTableRepresentatives {
        width: 100%;

        .tdPerfilDiv {
            display: flex;
            align-items: center;
        }
        .seller {
            color: #807368;
            margin-bottom: 3px;
            margin-right: 5px;
        }

        .tdSellerCustomers {
            display: flex;

            flex-direction: row;
            gap: 0.5rem;
            align-items: center;

            svg {
                font-size: 19px;
                color: rgba(65, 103, 242, 0.846);
                cursor: pointer;
            }
        }

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }
        }
    }
}

@media screen and (max-width: 1005px) {
    .tdCell {
        min-height: 28px;

        div {
            display: flex;
            align-items: center;

            justify-content: flex-end;
        }
    }

    .tdPerfilDiv {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 4px 0px;
    }
    .listRepresentatives {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }
    }

    .tdSellerCustomers {
        justify-content: flex-end;
    }
    .acoesButton {
        display: flex;
        justify-content: flex-end;
    }

    .tdpart {
        min-height: 33px;
    }

    .tdContentPart {
        max-width: 60px;
        min-height: 30px;
        flex-direction: flex-end;
        float: right;

        div {
            margin-bottom: 8px;
        }
    }
}
