@import '../../styles/variables';
@import '../../styles/mixins';

.custom-modal {
    @include fixed-center;
    background-color: $direct-sale-order-overlay-bg-color;
    box-sizing: border-box;
    height: 100%;
    padding: 70px;
    width: 100%;
    z-index: 200;
    .content {
        margin: 0 auto;
        background-color: $direct-sale-order-content-bg-color;
        height: fit-content;
        overflow-y: auto;
        overflow-x: hidden;
        width: 80%;
        .content-header {
            background-color: $direct-sale-order-content-header-bg-color;
            box-sizing: border-box;
            color: $direct-sale-order-content-header-color;
            font-size: 1.8rem;
            padding: 20px 25px;
            position: relative;
            width: 100%;
            .close {
                color: $direct-sale-order-content-header-color;
                position: absolute;
                right: 20px;
                top: 10px;
                .material-icons {
                    font-size: 4rem;
                }
            }
        }
        .modal-body {
            overflow: auto;
            max-height: calc(100vh - 200px);
            .boxes {
                line-height: 1.42857143;
                position: relative;
                .box {
                    border-bottom: 1px solid
                        $direct-sale-order-content-body-border-color;
                    margin: 0 0 20px;
                    padding: 10px 20px;
                    &:last-of-type {
                        border-bottom: none;
                    }
                    .box-body {
                        font-size: 1.1rem;
                        margin-bottom: 20px;
                        width: 100%;
                        .box-body__title {
                            margin-bottom: 5px;
                            display: block;
                        }
                        .row {
                            align-items: baseline;
                            display: flex;
                            .logo-status {
                                margin: 8px 0 15px;
                                width: 220px;
                                .status-confirmed {
                                    background-color: $direct-sale-order-content-body-status-bg-color;
                                    color: $direct-sale-order-content-body-color;
                                    display: block;
                                    margin: 0 0 0 10px;
                                    padding: 5px;
                                    width: fit-content;
                                    -webkit-print-color-adjust: exact;
                                }
                                .status-not-confirmed {
                                    background-color: #f39c12;
                                    color: $direct-sale-order-content-body-color;
                                    display: block;
                                    margin: 0 0 0 10px;
                                    padding: 5px;
                                    width: fit-content;
                                    -webkit-print-color-adjust: exact;
                                }
                            }
                            .company {
                                font-size: 1.2rem;
                                margin: 20px 0 5px;
                                width: 320px;
                                table {
                                    font-size: 1.1rem;
                                }
                            }
                            .order {
                                margin-top: 18px;
                                position: absolute;
                                right: 0;
                                width: 180px;
                            }
                        }
                        .table {
                            tbody {
                                tr {
                                    td {
                                        border: 1px solid
                                            $direct-sale-order-content-body-border-color;
                                        font-size: 0.9rem;
                                        text-align: left;
                                    }
                                    th {
                                        border: 1px solid
                                            $direct-sale-order-content-body-border-color;
                                        font-size: 0.9rem;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
