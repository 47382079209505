@import 'styles/_variables';
@import 'styles/_mixins';

.seller-participation-modal {
    :global {
        .modal-content {
            max-height: 90vh;
        }
    }
    .modal-dialog {
        min-width: 60vw;
        .modal-body {
            height: fit-content;
        }
    }
}

.cardParticipation {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    justify-content: flex-end;
}

.listSellerParticipation {
    margin-top: 20px;
    overflow-x: auto;

    .newTableSellerParticipation {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px !important;
            }
        }

        tbody {
            td {
                padding: 10px 8px !important;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }
            td:last-child {
                border-right: none;
            }
            tr:nth-child(even) {
                background-color: #f7f7f7;
            }
            .numCotacaoCard {
                width: 75px;
                display: flex;

                align-items: center;
                justify-content: center;
                padding: 6px 3px;
                background-color: $orange-3;
                border-radius: 4px;
                color: $orange;
                font-weight: 500;

                p {
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                    @media screen and (max-width: 900px) {
                        padding-left: 0;
                    }
                }
            }
        }

        .actions {
            > * {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
                // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17a2b8 !important;
                }

                &:nth-child(2) {
                    color: #ff7110;
                }

                &:nth-child(3) {
                    color: #30aa4c;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .togleFiltersContainer {
        display: flex;
    }

    .requestTableMobile {
        display: block;
    }

    .requestTableDesktop {
        display: none;
    }

    .cardParticipation {
        justify-content: center;
    }

    .listSellerParticipation {
        table {
            border: 0;
        }
        .newTableSellerParticipation {
            tbody {
                td {
                    padding: 0 !important;
                }
            }
        }
        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            padding: 0 !important;
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        tbody td {
            div {
                display: flex;
                justify-content: flex-end;
                min-width: unset !important;
            }
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            // text-transform: uppercase;
            vertical-align: middle;
        }

        .tdParticipacao {
        }

        .participation {
            width: unset;
            padding: 5px 20px;
        }

        table td:last-child {
            border-bottom: 0;

            svg {
                font-size: 22px;
            }
        }

        .client-name {
            padding: 7.5px 14px 7.5px 14px;
        }

        .divTdAcoes {
            gap: 30px;

            svg {
                font-size: 25px !important;
            }
        }

        .numCotacaoBox {
            padding: 6px 20px;
        }
    }
}
.showOnlyInMobile {
    display: none;

    @media screen and (max-width: 900px) {
        display: default;
    }
}
.hideOnMobile {
    display: default;

    @media screen and (max-width: 900px) {
        background-color: pink;
        display: none !important;
    }
}
.trMobile {
    display: none;

    @media screen and (max-width: 900px) {
        display: default;
    }
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.06);
    padding: 0 !important;
    margin: 2px;
    background-color: red;
    td {
        // padding: 0 50px;
    }
}
.storeNameMobile {
    display: flex;
    justify-content: space-between;
    font-weight: 600;

    // border-bottom: 1px solid linear-gradient(to right, $primary-color, transparent);
    border-bottom: 2px solid;
    border-image: linear-gradient(
            to right,
            $primary-color -30%,
            transparent 95%
        )
        100% 0%;
    padding: 7px 10px;
    gap: 12px;

    // background-image: linear-gradient(to right, $primary-color -100%, transparent 30%);
    .buttonStoreName {
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        width: 32px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: $primary-color;

        &:active {
            // background-color: ;

            opacity: 0.7;
            translate: 1px 1px;
        }
    }

    svg {
        color: #fff;
        font-size: 19px;
    }

    p {
        // padding-top: 3px;
        // padding: 2px 5px;
        font-size: 0.8rem;
        // margin-left: 1px;
    }
}
.rowIdStatus {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    p {
        font-weight: 500;
    }
    span {
        font-weight: 300;
    }
}
