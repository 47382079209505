.input-label {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 8px;
}
.input-field {
    @include border-radius;
    @include placeholder {
        color: $placeholder-input-color;
    }
    background-color: #fff;
    border: 1px solid $input-border-color;
    box-sizing: border-box;
    color: $input-color;
    //font-size: 1.5rem;
    padding: 8px;
    width: 20vw;
    height: 4.38vh;
}
