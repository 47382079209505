@import 'styles/_variables';

.dashboad-container {
    .highcharts-container{
        width: 100% !important;
    }

    .highcharts-root, [data-highcharts-chart] {
        width: 100% !important;
    }
    .table th {
        color: $primary-color;
    }
    .ranking-user {
        display: flex;
        flex-direction: column;
        span:first-child {
            font-weight: $bold;
            opacity: 0.6;
        }
        span:last-child {
            color: green;
            margin-top: 4px;
        }
    }
}

