@import '../../styles/variables';

.pageHeaderStyle {
    font-size: 32px; //before 16px
    font-family: $font-familyV2;
    font-weight: 500;
    color: #605F5F;
}
.pageHeaderContainer{
    margin-bottom: 10px;
}
@media screen and (max-width: 900px) {
    .pageHeaderStyle {
        font-size: 1.5rem; //before 16px
        font-family: $font-familyV2;
        font-weight: 500;
        color: #605F5F;
    }
}
