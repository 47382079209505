.cc-color-05 {
    background-color: rgba(48, 170, 76, 0.1) !important;
    padding: 3px;
    width: 100%;

    color: #30AA4C !important;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    -webkit-print-color-adjust: exact;
}

.cc-color-510 {
    background-color: rgba(255, 113, 16, 0.1) !important;
    padding: 3px;
    width: 100%;
    /* font-size: 90%; */
    /* font-weight: 700; */
    color: #FF7110 !important;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    -webkit-print-color-adjust: exact;
}

.cc-color-10 {
    background-color: rgba(96, 96, 96, 0.1) !important;
    padding: 3px;
    width: 100%;
    /* font-size: 90%; */
    /* font-weight: 700; */
    color: #505050 !important;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    -webkit-print-color-adjust: exact;
}

.cc-color-blue {
    -webkit-print-color-adjust: exact;
    background-color: rgba(23, 162, 184, 0.1) !important;
    padding: 3px;
    width: 100%;

    /* font-size: 90%; */
    /* font-weight: 700; */


    color: #17A2B8 !important;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}

.cc-disabled {
    pointer-events: none;
    opacity: 0.6;
}

.chevronDownEan {
    margin-right: 5px;
    font-size: 18px;
}

.trAccordion:hover {
    background-color: unset !important
}

