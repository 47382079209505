@import 'styles/_variables';
@import 'styles/_mixins';

.trMobile {
    @media screen and (max-width: 900px) {

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.06);
        padding: 7px 0;
        margin: 2px;

        td {
            // padding: 0 50px;
        }
    }
}

td.firstTdMobile {
    padding: 0px !important;

}

.headerMobileTable {
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    align-items: flex-start;
    font-weight: 600;
    // align-items: center;
    // border-bottom: 1px solid linear-gradient(to right, $primary-color, transparent);
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, $primary-color -30%, transparent 95%) 100% 0%;
    padding: 10px 5px 6px 5px;
    gap: 4px;
    background-color: #ff711030;
    span{
        font-weight: 300;
    }
    // background-image: linear-gradient(to right, $primary-color -100%, transparent 30%);

}

.infoStackMobileTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    p {
        font-weight: 300;
        font-size: 0.8rem;
    }

    span {
        font-weight: 400;
        font-size: 0.85rem;
    }
}

.rowBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.divActionsMobileTable {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    // width: 100%;
    flex: 1;

    svg {
        font-size: 22px !important;
    }
}

.buttonActionMobileTable {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 6px; // border: 1px solid ;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

    svg {
        font-size: 1rem;
        // color: rgba(255,0,0,0.7);
    }

    &:active {
        // background-color: ;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        opacity: 0.7;
        translate: 0px 1px;
    }
}

.rowBetweenGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 5px;
}

.tdMapProds {
    background-color: rgba(0, 0, 0, 0.03);
    // border: 1px solid #ff711050;
    border-radius: 8px;
    margin: 5px;
    padding: 10px;
}

.mapProdItem {
    display: flex;
    flex-direction: column;

}

.divMarcaMap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-weight: 400;
    }

    span {
        font-weight: 500;
    }

    padding-bottom: 4px;
    border-bottom: 1px solid ;
}

.divObsMap {
    display: flex;
    align-items: center;

    p {
        font-weight: 500;
    }

    span {
        font-weight: 500;
    }
}
