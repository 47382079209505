@import 'styles/_variables';

.pagination {
    margin-top: 0.5rem;
    justify-content: center;
    display: flex;
    padding-left: 0;
    list-style: none;
}
.pagination-desc {
    margin-top: 12px;
    font-size: 1rem;
    width: 100%;
    display: grid;
    text-align: center;
    justify-content: center;
    margin-bottom: 24px;
}

.page-item .page-link {
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 0 2px;
    border: 0px solid #cccccc;
    padding: 5px 10px;
    border-radius: 5px;
    color: $orange;
    text-decoration: none;
    padding: 2px;
    width: 32px;
    height: 32px;
}

.page-item a.page-link:hover {
    background-color: $orange;
    color: #ffffff;
}

.page-item.active .page-link {
    color: #ffffff;

    background-color: $orange;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
