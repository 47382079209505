@import '../../styles/variables';
@import '../../styles/mixins';

.request {
    // background-image: url('../../assets/images/request-abrasel-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    .request-box-container {
        align-items: center;
        height: 100%;
        justify-content: center;
        .request-box {

            @include border-radius;
            background-color: $sign-in-box-bg-color;
            padding: 20px;
            box-shadow: 2px 2px 3px 2px rgba(0,0,0,0.2);
            min-width: 500px;
            @media only screen and (max-width: 740px) {
                min-width: 90%;
            }
            .request-box-header {
                align-items: center;
                display: flex;
                flex-direction: column;
                padding: 0 0 20px;
                // border-bottom: 1px solid $sign-in-box-header-border-color;
                .request-box-logo {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                }
                .request-box-logo-sindibares{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                }
                .request-box-logo-vrsystem{
                    width: 100%;
                    display: flex;

                    // flex-direction: column;
                    flex-direction: row;
                    gap: 22px;
                    // flex-wrap: wrap;
                    margin: 0 25px;
                    align-items: center;
                    justify-content: center;
                    @media only screen and (max-width: 740px) {
                        flex-direction: column;
                    }

                }
                .logo {
                    width: 200px;
                    height: 80px;
                }
                .logoSindibares {
                    // width: 200px;
                    max-width: 100%;
                    max-height: 120px;
                    object-fit: contain;
                    @media only screen and (max-width: 740px) {

                    }
                }
                .logoVrsystem {
                    // width: 200px;
                    max-width: 100%;
                    max-height: 80px;
                    object-fit: contain;
                    @media only screen and (max-width: 740px) {

                    }
                }
                .title {
                    color: $sign-in-box-header-title-color;
                    font-size: 1.8rem;
                    margin-top: 20px;
                    text-transform: none;
                }
                .title-center {
                    color: $sign-in-box-header-title-color;
                    font-size: 1.8rem;
                    margin-top: 20px;
                    text-transform: none;
                    text-align: center;
                }
                .title-center-color {
                    background-color: $sign-in-box-header-title-color;
                    color: $sign-in-box-bg-color;
                    font-size: 1.8rem;
                    margin-top: 20px;
                    text-transform: none;
                    text-align: center;
                }
            }
            .request-box-body {
                margin: 10px 15px 0;
                .form {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    .field {
                        @include no-borders;
                        background-color: $sign-in-box-body-form-field-bg-color;
                        box-sizing: border-box;
                        height: 38px;
                        margin: 20px 20px 0;
                        padding-left: 10px;
                        width: 100%;
                    }
                    .send {
                        @include border-radius;
                        background-color: $sign-in-box-body-form-button-bg-color;
                        color: $sign-in-box-body-form-button-color;
                        font-size: 1.2rem;
                        font-weight: $bold;
                        margin-top: 10px;
                        padding: 10px 40px;
                    }
                }
            }
        }
    }
}

.checkmark__circle {

    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 10;
    stroke-miterlimit: 0;
    stroke: #ff7110;
    fill: none;
    animation: strokes 1s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {

    width: 156px;
    height: 156px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    /* box-shadow: inset 0px 0px 0px #ff7110; */

    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 2s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}
.containerSuccessScreen {
    // background-color: rgba(255, 234, 219, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    /* justify-content: center; */
    width: 100%;
    height: 100%;


}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;

    }
}

@keyframes strokes {
    20% {
        stroke-dashoffset: 0;

    }

    100% {
        stroke-dashoffset: 0;
        stroke-width: 50
    }
}

@keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill {
    100% {
        /* box-shadow: inset 0px 0px 0px 30px #7ac142 */

        box-shadow: 1px 0px 20px 0px #ff7110;
        -webkit-box-shadow: 1px 0px 20px 0px #ff7110;
        -moz-box-shadow: 1px 0px 20px 0px #ff7110;
    }
}
