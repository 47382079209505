@import '../../styles/variables';
@import '../../styles/mixins';

.request {
    background-image: url('../../assets/images/abrasel-logo.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    .request-box-container {
        align-items: center;
        height: 100%;
        justify-content: center;
        .request-box {
            @include border-radius;
            background-color: $sign-in-box-bg-color;
            padding: 20px;
            .request-box-header {
                align-items: center;
                display: flex;
                flex-direction: column;
                padding: 0 0 20px;
                // border-bottom: 1px solid $sign-in-box-header-border-color;
                .request-box-logo {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                }
                .logo {
                    width: 200px;
                    height: 80px;
                }
                .title {
                    color: $sign-in-box-header-title-color;
                    font-size: 1.8rem;
                    margin-top: 20px;
                    text-transform: none;
                }
            }
            .request-box-body {
                margin: 10px 15px 0;
                .form {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    .field {
                        @include no-borders;
                        background-color: $sign-in-box-body-form-field-bg-color;
                        box-sizing: border-box;
                        height: 38px;
                        margin: 20px 20px 0;
                        padding-left: 10px;
                        width: 100%;
                    }
                    .send {
                        @include border-radius;
                        background-color: $sign-in-box-body-form-button-bg-color;
                        color: $sign-in-box-body-form-button-color;
                        font-size: 1.2rem;
                        font-weight: $bold;
                        margin-top: 10px;
                        padding: 10px 40px;
                    }

                    .disabled {
                        background-color: $sign-in-box-body-form-field-bg-color;
                        color: $gray-dark !important;
                        cursor: not-allowed !important;
                    }
                }
            }
        }
    }
}
