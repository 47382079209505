// Required
@import "~bootstrap/scss/bootstrap";

// @import 'node_modules/bootstrap-vue/src/index';

// @import 'node_modules/bootstrap/scss/functions';
// @import 'node_modules/bootstrap/scss/variables';
// @import 'node_modules/bootstrap/scss/mixins';

// Optional
// @import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
// @import 'node_modules/bootstrap/scss/alert';
// @import 'node_modules/bootstrap/scss/forms';
// @import 'node_modules/bootstrap/scss/grid';
// @import 'node_modules/bootstrap/scss/nav';
// @import 'node_modules/bootstrap/scss/pagination';
// @import 'node_modules/bootstrap/scss/tables';
// @import 'node_modules/bootstrap/scss/utilities';
// @import 'node_modules/bootstrap/scss/modal';
// @import 'node_modules/bootstrap/scss/buttons';
// @import 'node_modules/bootstrap/scss/breadcrumb';

.alert {
    padding: 5px 10px;
    border-radius: 5px;
}

.col {
    // box-sizing: border-box;
}

.form-control[readonly],
.form-control[disabled] {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    cursor: not-allowed;
    opacity: 1;
    
}
