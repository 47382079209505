@import '../../styles/variables';

.nextPrevText {
    color: $orange;
    font-family: $font-familyV2;
}
.desc {
    margin-top: 12px;
    font-size: 0.9rem;
}
.pagination-container {
    .desc {
        margin-top: 12px;
        font-size: 0.9rem;
    }
    .paginationRequests {
        //font-size: 1.4rem;
        display: flex !important;
        flex-direction: row;
        .page-item {
            width: '100%';
            &.active {
                .page-link {
                    background-color: $pagination-active-bg-color;
                    border-color: $pagination-active-border-color;
                    color: $pagination-active-color;
                }
            }
            .page-link {
                cursor: pointer;
                color: $pagination-color;
                //margin: 0;
                // padding: 1rem 1.4rem;
                border-color: $gray-light;
            }
        }
    }
}
