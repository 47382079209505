@import '../../styles/variables';
@import '../../styles/mixins';

.c-select {

    width: 100%;
    padding: 10px 12px ;
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    font-family: $font-familyV2;
    font-size: 14px;
    outline-width: 0px;
    color:#898989 ;
   // @include c-input-base;
   // padding-left: 8px;
   // &:focus {
   //     outline-color: $primary-color;
   // }
}
.form-control:focus{
    box-shadow: none !important;
    border-color: #FF7110;
}



.selectLabel{
    color:#898989 ;
    font-size: 20px;
    font-weight: 400;
    font-family: $font-familyV2;
    margin-bottom: 4px;
}
