@import '../../styles/variables';

.customCheckContainer {
    display: flex;

    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.checkArea {
    height: 25px;
    width: 25px;
    border: 1px solid $orange;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.disabledArea {
    background-color: rgba(0, 0, 0, 0.08);
    border: 1px solid #f4cdb20c;
    cursor: not-allowed;
}
.CheckBIcon {
    color: $orange;
}
.checkDescription {
    margin-left: 4px;
    font-family: $font-familyV2;
    font-weight: 300;
    font-size: 16px;
}
