@import "styles/_variables";

.modalHeader{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 16px;
    background-color: $orange;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.modalTitle{
    font-size: 24px;
    font-family: $font-familyV2;
    color: white;
    font-weight: 400;

}
.modalBody{
    border-radius: 8px;
    height: 85vh;
    overflow-y: auto;
    
}
.closeIcon{
    cursor: pointer;
    color: #fff;
    font-size: 31px;
}