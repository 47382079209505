.cursor-pointer {
    cursor: pointer;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-grow {
    flex-grow: 1;
}

.flex-col {
    flex-direction: column;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.p-fixed {
    position: fixed;
}

.color-gray-2 {
    color: $gray-2;
}

.color-gray-3 {
    color: $gray-3;
}

.color-gray-4 {
    color: $gray-4;
}

.color-red {
    color: $red;
}

.color-orange {
    color: $orange;
}

.bg-light-gray {
    background-color: $gray-light;
}

.light {
    font-weight: 300;
}

.regular {
    font-weight: 400;
}

.semibold {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-32 {
    margin-top: 32px;
}
