@import 'styles/_variables';

.content_buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;

    margin-top: 18px;
}

.content_buttons button {
    width: 170px;
    max-width: 200px;
}

.filtro_data_container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 17px;
    align-items: flex-end;

    button {
        height: 45px;
    }
}

.inputs_data {
    display: flex;
    flex-direction: row;
    gap: 17px;
}

.NewListItemExtract {
    margin-top: 20px;
    overflow-x: auto;
    height: 46vh;

    table {
        width: 100%;

        thead,
        tfoot {
            th:first-child,
            td:first-child {
                padding-left: 10px;
                text-align: left;
            }
            th:last-child,
            td:last-child {
                padding-right: 10px;
            }

            tr {
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px;
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th,
            td {
                padding: 12px 10px;
                text-align: center;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;
                text-align: center;
                vertical-align: middle;
            }

            td:first-child {
                text-align: left;
            }

            tr:nth-child(even) {
                background-color: #f7f7f7; // Alternância de cores
            }

            tr td:first-child {
                padding-left: 10px;
            }
        }
    }
}

@media screen and (max-width: 1381px) {
    .content_buttons {
        gap: 12px;
    }
}

@media screen and (max-width: 900px) {
    .NewListItemExtract {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }

        tFoot {
            display: none;
        }

        .list-actionsGrupoP {
            justify-content: flex-end;
            gap: 22px;

            svg {
                font-size: 25px;
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .content_buttons {
        gap: 7px;
    }

    .filtro_data_container {
        flex-direction: column;
    }
}

@media screen and (max-width: 426px) {
    .content_buttons {
        gap: 7px;
    }
    .content_buttons button {
        width: 143px;
    }
    .filtro_data_container {
        flex-direction: column;
    }
}
