@mixin selection {
    ::-moz-selection {
        /* Code for Firefox */
        color: $selection-color;
        background: $selection-bg-color;
    }
    ::selection {
        color: $selection-color;
        background: $selection-bg-color;
    }
}

@mixin border-radius {
    border-radius: $border-radius;
}

@mixin no-borders {
    border: none;
    border-radius: 0px;
}

@mixin placeholder {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin absolute-center {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin fixed-center {
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin fixed {
    position: fixed;
    transform: translate(-50%, -50%);
}

@mixin c-input-base {
    height: $input-height;
    width: 100%;
    text-align: left;
    padding: 12px;
    //margin: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    font-family: $font-familyV2;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    box-sizing: border-box;
    &:disabled {
        background-color: #f8f8f8;
    }
}

@mixin section-title {
    display: flex;
    align-items: center;
    color: $gray-5;
    span.material-icons {
        margin-right: 5px;
        color: $secondary-color;
    }
}
