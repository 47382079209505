@import '../../styles/variables';
@import '../../styles/mixins';

.table {
    td {
        &:first-child {
            padding-left: 10px;
        }
    }
}

.list-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    :global(button) {
        width: fit-content;
        padding: 0 8px;
    }
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.iconsActionsExclude {
    cursor: pointer;
    color: '#ff1010' !important;
}

.iconsActionsEditPriceLevel {
    cursor: pointer;
    color: '#2daf82' !important;
}

.iconsActionsEdit {
    cursor: pointer;
    color: $orange !important;
}

.iconsActionsGroup {
    cursor: pointer;
    color: '#1088ff' !important;
}

.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: $secondary-color;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;

    bottom: 115%;
    left: 50%;
    margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}
.buttonItemAdd {
    margin-right: 3%;
}
.imageProduct {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    cursor: pointer;
    &:hover {
        transform: scale(1.5);
        transition: all 1.5s ease;
    }
}
