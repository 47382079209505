$pic_size: 40px;

.avatar {
    background-color: $gray-2;
    color: #fff;
    position: relative;
    top: 1px;
    vertical-align: middle;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    -webkit-font-smoothing: antialiased;

    &.avatar-48 {
        border-radius: $pic_size;
        max-height: $pic_size;
        width: $pic_size;
        height: 100%;
        font-size: 24px;
        line-height: $pic_size;
    }
}
