@import 'styles/_variables';

.header-info-modal {
    background-color: $direct-sale-parameter-header_background;
    padding: 25px 0;

    .name {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        color: $primary-color;
    }

    .lottie {
        width: 5rem;
    }
}

.table {
    border: none;

    thead th,
    tbody td {
        font-size: 12px;
        padding: 5px 10px !important;
    }
}

.modal-dialog {
    min-width: 90vw;

    .modal-body {
        height: fit-content;
        max-height: 90vh;
    }
}

.actions {
    display: flex;
    justify-content: center;

    button {
        white-space: nowrap;
    }
}

.status {
    display: block;
    font-weight: $bold;
    text-align: center;
    padding: 3px 9px;
    color: white;
    border-radius: 3px;
    font-size: 10px;
    margin: 5px 0;

    &.active {
        background-color: #00a65a;
    }

    &.pending {
        background-color: #f3ae76;
    }

    &.inactive {
        background-color: #dd4b39;
    }
}

.condition-body {
    margin: 20px 0;

    .form-condition {
        justify-content: flex-start;
        margin: 0 25px;

        .form-label-direct-sale-parameter {
            font-size: 1.3rem;
            color: $direct-sale-parameter-form-label;
        }

        .form-input-direct-sale-parameter {
            margin: 10px 0px;
        }

        .form-select-direct-sale-parameter {
            margin: 10px 0px;
            color: $direct-sale-parameter-form-color-select;
        }

        .form-check-label {
            margin: 15px;
        }

        .save {
            background-color: $direct-sale-parameter-save-button;
            border-color: $direct-sale-parameter-save-button;
            color: $direct-sale-parameter-title-button;
        }
    }
}

.modalv2-section-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #606060;
    margin-top: 32px;
    margin-bottom: 24px;
}

.modalv2-section-content {
    display: flex;
    border-bottom: 1px solid $orange-3;
    padding-bottom: 24px;
}

.modalv2-editweek-containear {
    display: flex;
    flex-direction: column;
    height: 120px;
    width: 160px;
    margin-right: 20px;
}

.modalv2-editweek-content {
    height: 70%;
    width: 150px;
    border: 1px solid #e5e5e5;
    border-radius: 10px 10px 0 0;
}

.modalv2-editweek-button {
    flex: 1;
    width: 150px;
    background: $orange-3;
    border: 1px solid #e5e5e5;
    border-radius: 0 0 10px 10px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: $orange;
}

input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
}

.modalv2-editweek-save {
    flex: 1;
    background: $orange;
    border: 1px solid #e5e5e5;
    border-radius: 0 0 10px 10px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: white;
}

.modalv2-editweek-content-weekday {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: #afafaf;
    margin-top: 3px;
}

.modalv2-editweek-content-hour {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.02em;
    color: #747373;
    margin-top: 10px;
}

.modalv2-editweek-content-hour-edit {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.02em;
    color: $orange;
    margin-top: 10px;
    width: 99%;
    border: none;
}

.modalv2-editweek-content-date-edit {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: -0.02em;
    color: $orange;
    margin-top: 25px;
    width: 100%;
    border: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
}

.modalv2-editminday {
    width: 170px;
}

.modalv2-row {
    display: flex;
    align-items: baseline;
}

.modalv2-section-addicon {
    font-size: 15px;
}

.modalv2-section-addicon-containear {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $orange-3;
    border-radius: 5.6px;
    color: $orange;
    margin-left: 10px;
    padding: 5px;
    cursor: pointer;
}

.modalv2-editweek-content-date {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #747373;
    margin-top: 30px;
    text-align: center;
    border: none;
    width: 100%;
}

.modalv2-button-row {
    display: flex;
    width: 150px;
}

.modalv2-editdate-button {
    flex: 1;
    background: $orange-3;
    border: 1px solid #e5e5e5;
    border-radius: 0 0 0 10px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: $orange;
    padding-bottom: 7px;
}

.modalv2-editdate-save {
    flex: 1;
    background: $orange;
    border-radius: 0 0 0 10px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: white;
    padding-bottom: 9px;
}

.modalv2-editdate-delete {
    color: white;
    background: $orange;
    border-radius: 0 0 10px 0;
    font-size: 18px;
    padding: 5px 5px 0 5px;
    cursor: pointer;
}

.rowtabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.formContainer {
    // display: flex;
    display: flex;
    flex-direction: row;
    // justify-content: stretch;
    flex-wrap: wrap;
    gap: 15px;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-bottom: 10px;
}

.checkboxInput {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.formButonsRow {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    gap: 25px;
}

.divactions {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.NewlistAddSales {
    margin-top: 20px;
    overflow-x: auto;

    .newTableAddSales {
        width: 100%;
        svg {
            cursor: pointer;
        }
        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .NewlistAddSales {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            // text-transform: uppercase;
            vertical-align: middle;
        }

        table td:last-child {
            border-bottom: 0;

            svg {
                font-size: 22px;
            }
        }
    }

    .formContainer {
        display: flex;
        flex-direction: column;
    }
    .divactions {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
    }
}
