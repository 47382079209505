@import '../variables';

.table {
    border-left: 1px solid rgb(222, 226, 230);
    border-right: 1px solid rgb(222, 226, 230);
    border-bottom: 1px solid rgb(222, 226, 230);

    thead {
        tr {
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;

            th {
                border: none;
                font-size: 11px;
                font-weight: $bold;
                color: #707070;
                padding: 10px 0 14px 0;
                text-align: left;
                text-transform: uppercase;
                vertical-align: middle;
                &.actions {
                    text-align: center;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                font-size: 12px;
                font-weight: $regular;
                padding: 9px 0;
                text-transform: uppercase;
                vertical-align: middle;

                &.actions div {
                    display: flex;
                    justify-content: center;
                    button {
                        width: 15px;
                        justify-content: center;
                        margin: 0 5px;
                        i,
                        span {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
}
