@import '../../styles/variables';
@import '../../styles/mixins';

.requests {

    .products-link {
        //border: 1px solid $primary-color;
        border-radius: 3px;
        display: flex;
        width: fit-content;
        margin: auto;
        padding: 5px 10px;
        color: #30AA4C;
        background-color: rgba(48, 170, 76, 0.1);
        ;

        svg {
            margin-right: 5px;
        }

        p {
            margin-top: 1px;
        }

        &:hover {
            color: white;
            background-color: rgba(48, 170, 76, 0.3);
        }
    }

    .actions {
        >* {
            font-size: 15px;
            margin-left: 5px;
            padding: 4px;
            cursor: pointer;
            border: 1px solid white;

            &:hover {
                filter: hue-rotate(75deg);
            }

            &:first-child {
                svg {
                    color: $primary-color  !important;
                }
            }

            &:nth-child(2) {
                color: $secondary-color;
            }

            &:nth-child(3) {
                color: $ternary-color;
            }
        }
    }

    .list {
        .table {
            tbody {
                .id {
                    text-decoration: underline;
                    color: $gray-dark-2;
                    font-weight: 600;
                }

                .products-count {
                    color: $green-light;
                    font-size: 12px;
                    font-weight: $bold;
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate3d(0, -20%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.azul {
    color: #0050b3;
    font-size: 12px;
    font-weight: 700 !important;
}

.laranja {
    color: #ff7110;
    font-size: 12px;
    font-weight: 700 !important;
}

.btn-acoes-campanha {
    font-size: 18px;
    color: $red;
    margin: auto;
}

.example-custom-input {
    cursor: pointer;
    padding: 5px 15px;
    border: 0;
    border-radius: 4px;
    background-color: rgba(23, 162, 184, 0.1);
    ;
    font: inherit;
    color: #17A2B8;
}

.NewlistCampagne {
    margin-top: 20px;
    overflow-x: auto;


    .newTableCampagne {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;

            }

            tr {

                // padding: 12px 0px !important;
                background-color: #FFFEFC;
                border: 1px solid #E5E5E5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605F5F;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605F5F;

                text-align: left;
                justify-content: center;
                vertical-align: middle;


            }

            td:last-child {
                border-right: none;

            }

            tr:nth-child(odd) {
                background-color: #F7F7F7;

            }

            tr {
                tr {
                    margin-top: 40 !important;

                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;

                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }

                .link-salesman {
                    align-items: center;
                    background-color: #00a65a;
                    border-radius: 4px;
                    color: #ffffff;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    width: 30px;
                }

                .status {
                    display: block;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        background-color: #00a65a;
                    }

                    &.pending {
                        background-color: #f3ae76;
                    }

                    &.inactive {
                        background-color: #dd4b39;
                    }
                }
            }
        }

        .actions {
            >* {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
                // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17A2B8 !important;
                }

                &:nth-child(2) {
                    color: #FF7110;
                }

                &:nth-child(3) {
                    color: #30AA4C;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .NewlistCampagne {


        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
            vertical-align: middle;

        }

        table td:last-child {
            border-bottom: 0;
            svg{
                font-size: 22px;
            }
        }

        .products-link {
            //border: 1px solid $primary-color;

            border-radius: 3px;
            display: flex;


            margin: unset;
            padding: 5px 10px;
            color: #30AA4C;


            svg {
                margin-right: 5px;
            }

            p {
                margin-top: 1px;
            }

            &:hover {
                color: white;
                background-color: rgba(48, 170, 76, 0.3);
            }
        }

        .products-count {
            width: 100%;
            text-align: right;
            div{

                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .TdDatePicker {}

    .ContainerDatePicker {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
    }

}
