@import '../../styles/variables';
@import '../../styles/mixins';

.sign-in {
    background-image: url('../../assets/images/sign-in-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow: hidden;

    .sign-in-box-container {
        align-items: center;
        height: 100%;
        justify-content: center;

        .sign-in-box {
            @include border-radius;
            background-color: $sign-in-box-bg-color;
            padding: 20px;
            min-width: 400px;
            @media only screen and (max-width: 740px) {
                min-width: unset;
                width: 100%;
            }

            .sign-in-box-header {
                align-items: center;
                display: flex;
                flex-direction: column;
                padding: 0 0 20px;
                border-bottom: 1px solid $sign-in-box-header-border-color;

                .logo {
                    width: 200px;
                }

                .title {
                    color: #ff7110;
                    font-size: 1.8rem;
                    margin-top: 20px;
                    text-transform: none;
                }
            }

            .sign-in-box-body {
                margin: 10px 15px 0;

                .form {
                    align-items: center;
                    display: flex;
                    flex-direction: column;

                    .field {
                        @include no-borders;
                        background-color: $sign-in-box-body-form-field-bg-color;
                        box-sizing: border-box;
                        height: 38px;
                        margin: 20px 20px 0;
                        padding-left: 10px;
                        width: 100%;
                    }

                    .send {
                        @include border-radius;
                        background-color: #ff7110;
                        color: $sign-in-box-body-form-button-color;
                        font-size: 1.2rem;
                        font-weight: $bold;
                        margin-top: 10px;
                        padding: 10px 40px;
                    }
                }
            }
        }
    }
}
