@import '../../styles/variables';
@import '../../styles/mixins';

.c-input {
    @include c-input-base;

    &.thin {
        height: 25px !important;
        font-size: 12px;
    }
}
.c-input:focus {
    box-shadow: none;
    border-color: $orange;
}
.c-search-group {
    .c-input {
        padding-left: 40px;
    }
    .c-search-icon {
        position: absolute;
        bottom: calc(50% - 27px);
        left: 19px;
    }
}

.autocomplete-options {
    position: absolute;
    width: auto;
    min-width: 100%;
    max-width: 100%;
    max-height: 350px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 4px 0;
}

.autocomplete-option {
    padding: 5px 0px 5px 10px;
    font-size: 14px;
    font-family: $font-familyV2;
    color: #898989;
    cursor: pointer;

    &:hover {
        background-color: $orange;
        color: white;
    }
}
