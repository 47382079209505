@import 'styles/_variables';
@import 'styles/_mixins';
@import 'styles/_variables';
@import 'styles/_mixins';

.profiles {
    margin: auto;
}

.shield {
    color: $primary-color;
    margin-right: 10px;
}

.actions {
    :global {
        svg {
            font-size: 15px;
            margin-left: 5px;
            padding: 4px;
            cursor: pointer;
            border: 1px solid white;
            &:hover {
                filter: hue-rotate(75deg);
            }
            &:first-child {
                color: $red;
            }
            &:nth-child(2) {
                color: $secondary-color;
            }
            &:nth-child(3) {
                color: $ternary-color;
            }
        }
    }
}

.infos-and-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}


.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color:  $secondary-color;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;

    bottom: 115%;
    left: 50%;
    margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}
.buttonItemAdd {
    margin-right: 3%;
}

.iconsActionsExclude {
    cursor: pointer;
    color: "#ff1010" !important;
}
.iconsActionsEdit {
    cursor: pointer;
    color: "#ff7110" !important;
}


.svgactionsProfiles {
    font-size: 20px;
    margin-left: 3px;
    cursor: pointer;
}




.newListPerfilMI {
    margin-top: 20px;
    overflow-x: auto;


    table {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;

            }

            tr {

                // padding: 12px 0px !important;
                background-color: #FFFEFC;
                border: 1px solid #E5E5E5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605F5F;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605F5F;

                text-align: left;
                justify-content: center;
                vertical-align: middle;


            }

            td:last-child {
                border-right: none;

            }

           // tr:nth-child(4n+4) {
            tr:nth-child(odd) {
                background-color: #F7F7F7;

            }

            tr {
                tr {
                    margin-top: 40 !important;

                }

                td:first-child {
                    padding-left: 10px;
                }
            }
        }
    }
}


@media screen and (max-width: 900px) {
    .newListPerfilMI {


        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }

    }


}
