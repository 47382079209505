@import 'styles/_variables';
@import 'styles/_mixins';

.content {
    padding: 1rem;
    font-size: 13px;
    white-space: nowrap;
    font-weight: 600;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .info {
        display: flex;
        flex-direction: column;
        .value {
            font-size: 1.3rem;
        }
        span {
            font-size: 10px;
            padding-bottom: 2px;
        }
    }
}

.card {
    color: white;
}

.icon {
    font-size: 3rem;
    text-align: right;
    padding: 1rem;
}
