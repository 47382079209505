@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.seller-comercial-conditions {
    color: #757575;
    .col:last-child {
        max-width: 7%;
        justify-content: center;
        align-items: end;
        flex-flow: column;
        display: flex;
    }
    .form-row button {
        height: $input-height;
    }
}
