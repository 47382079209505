@import 'styles/_variables';
@import 'styles/_mixins';

.contentContainer {
    display: flex;

    gap: 0.8rem;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.contentFilters {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: end;
}

.modal-dialog {
    min-width: 90vw;

    .modal-body {
        height: fit-content;
        max-height: 90vh;
    }
}

.cif,
.ciffob {
    font-weight: 600;
}

.table {
    border: none;

    thead th,
    tbody td {
        font-size: 12px;
        padding: 5px 10px !important;
    }
}

.actions {
    display: flex;

    button {
        white-space: nowrap;
    }
}

.price {
    svg {
        font-size: 20px !important;
    }
}

.obs {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 10px;
    border: 1px solid $secondary-color;
    color: $secondary-color;

    svg {
        margin-right: 5px;
        color: $primary-color;
        font-size: 17px;
    }
}

.acceptButtonNewClients {
    padding: 3px;
    border-radius: 50%;
    // border: 1px solid #2ACD72;
    font-family: $font-familyV2;
    font-weight: 500;
    font-size: 16px;
    color: #239444;
    background-color: rgba(48, 170, 76, 0.1);
    cursor: pointer;
    svg {
        font-size: 33px;
    }
}

.rejectButtonNewClients {
    padding: 3px;
    border-radius: 50%;
    // border: 1px solid #CD2A2A;
    font-family: $font-familyV2;
    font-weight: 500;
    font-size: 16px;
    color: #b3251c;
    background-color: rgba(230, 53, 53, 0.1);
    cursor: pointer;
    margin-left: 10px;
    svg {
        font-size: 33px;
    }
}

.listNewClients {
    width: 100%;
    // margin-top: 20px;
    // padding-left: 15px;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 78vh;

    .newTableNewClients {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
                text-justify: center;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
                div {
                    gap: 4px;
                    :first-child {
                        font-weight: 500;
                    }
                }
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;

                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }

                .link-salesman {
                    align-items: center;
                    background-color: #00a65a;
                    border-radius: 4px;
                    color: #ffffff;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    width: 30px;
                }

                .status {
                    display: block;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        background-color: #00a65a;
                    }

                    &.pending {
                        background-color: #f3ae76;
                    }

                    &.inactive {
                        background-color: #dd4b39;
                    }
                }
            }
        }

        .actions {
            > * {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
                // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17a2b8 !important;
                }

                &:nth-child(2) {
                    color: #ff7110;
                }

                &:nth-child(3) {
                    color: #30aa4c;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}
.filterStateContainer {
    display: flex;
    flex-direction: column;
    width: 250px;
    padding-right: 15px;
    border-right: 1px solid #ff7110;
    max-height: 78vh;
    overflow: auto;
}
.filterStateContainerList {
    display: flex;
    flex-direction: column;
    color: #605f5f;
    h5 {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        padding: 10px 8px;
        border-bottom: 1px solid #d1d1d1;
        margin-top: 15px;
        margin-bottom: 8px;
    }
    p {
        text-decoration: none;
        cursor: pointer;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        padding: 12px 5px 12px 10px;
        transition: 100ms ease;
        border-left: 2px solid transparent;
    }
    .active {
        border-left: 2px solid #ff7110;
        background-color: #ff711010 !important;
    }
    p:hover {
        font-weight: 500;
        padding: 12px 5px 12px 15px;
        transition: 100ms ease;
        background-color: #ff711010;
        border-left: 2px solid #ff7110;
    }
    :nth-child(even) {
        background-color: #f7f7f7;
    }
}
.containerSelectSeller {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 30px;
    h6 {
        font-size: 15px;
        margin-right: 10px;
    }
}
.actionDiv {
    display: flex;
    justify-content: center;
    gap: 22px;
}
.filterStateContainerMobile {
    display: none;
}

@media screen and (max-width: 900px) {
    .contentContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px;
        // overflow-x: hidden;
    }
    .filterStateContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        // padding-right: 15px;
        // border-right: 1px solid #ff7110;
        // max-height: 78vh;
        // overflow: auto;
    }

    .actionDiv {
        display: flex;
        justify-content: center;
        gap: 32px;
    }
    .acceptButtonNewClients {
        padding: 3px;
        border-radius: 50%;
        // border: 1px solid #2ACD72;
        font-family: $font-familyV2;
        font-weight: 500;
        font-size: 16px;
        color: #239444;
        background-color: rgba(48, 170, 76, 0.1);
        cursor: pointer;
        svg {
            font-size: 33px;
        }
    }

    .rejectButtonNewClients {
        padding: 3px;
        border-radius: 50%;
        // border: 1px solid #CD2A2A;
        font-family: $font-familyV2;
        font-weight: 500;
        font-size: 16px;
        color: #b3251c;
        margin-left: 40px;
        background-color: rgba(230, 53, 53, 0.1);
        cursor: pointer;
        svg {
            font-size: 33px;
        }
    }

    .containerSelectSeller {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        // justify-content: flex-end;
        width: 100%;
        gap: 10px;
        h6 {
            font-size: 15px;
            margin-right: 10px;
        }
    }

    .listNewClients {
        padding-left: 0;
        overflow-x: hidden;
        .newTableNewClients {
            overflow-x: none;
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }
        tbody td {
            div {
                display: flex;
                justify-content: flex-end;
                min-width: unset !important;
            }
        }
        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            // text-transform: uppercase;
            vertical-align: middle;
        }
    }

    .filterStateContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        // padding-right: 15px;
        // border-right: 1px solid #ff7110;
        // max-height: 78vh;
        // overflow: auto;
    }
    .filterStateContainerMobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        margin-bottom: 10px;
        border-bottom: 1px solid #ff7110;
        p {
            font-size: 15px;
            font-weight: 600;
        }
    }
    .filterStateContainerList {
        display: none;
    }
}
