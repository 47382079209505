@import '../../styles/variables';
@import '../../styles/mixins';

.c-input {
    width: 100%;
    padding: 12px;
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;

    outline-width: 0px;

    // &.as-label {
    //     border: none;
    //     padding: 0;
    //     background: none;
    //     box-shadow: none;
    // }
}
.c-label {
    margin-bottom: 2px;
}
.currencyInput {
    font-family: $font-familyV2;
    font-size: 14 !important;
    font-weight: 400;
    box-shadow: none;
    width: 100%;
    padding: 12px 10px;
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    height: 100%;
    outline-width: 0px;
    color: #606060;
}
.currencyInput:focus {
    box-shadow: none;
    border-color: $orange;
}
.currencyInputSymbol {
    color: #606060;
    position: absolute;
    left: 6px;
    font-size: 14px;
    font-family: $font-familyV2;
    bottom: -3px;
}
