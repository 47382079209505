.datePickerContainerOrdersPage{
    //background-color: red;
    width: 150px;
}
.containerDatePickerOrders{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap:15px;

}

.mainConteinerorderFilters{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 15px;
}

.ContaienrFilterOrdersPage{
    width: 250px;

}


@media only screen and (max-width: 740px) {
    .containerDatePickerOrders{
        display: flex;
        flex-direction: row;
        //flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap:2%;

    }

    .datePickerContainerOrdersPage{
        width: 49%;
    }


    .ContaienrFilterOrdersPage{
        width: 100%;

    }




}
