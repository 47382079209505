.newClientsFilterContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
}

.buttonsFilters {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 1.4rem;
    max-height: 40px;
}

.labelSegmento {
    font-size: 18px !important;
}

@media screen and (max-width: 465px) {
    .filterItemRamo {
        max-width: 218px;
    }

    .newClientsFilterContainer {
        flex-direction: column;
        gap: 0.7rem;
    }

    .buttonsFilters {
        margin-top: 0;
    }
}
