@import '../../styles/variables';
@import '../../styles/mixins';

.TesteRed {
    //background-color: red;
}

.TesteBlue {
    background-color: blue;
}

.tdSellers {
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: center;
}

.divSellers {
    width: 100%;
    max-height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.countSeller {
    width: 100%;
    max-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 5px 5px 4px;
    border-radius: 5px;
    background-color: $orange;
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-top: 4px;
    cursor: pointer;
}

.editIcon {
    font-size: 28px !important;
    cursor: pointer;
    color: #30aa4c !important;

    &:hover {
        color: $buying-companies-hover-icon;
    }
}

.iconButtonV3 {
    color: $orange;
    margin-right: 8px;
    font-size: 18px;
    margin-bottom: 1px;
}

.count_vend_th {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    cursor: pointer;

    div {
        margin-top: 1px;
    }

    span {
        white-space: nowrap;
    }
}

.buying-companies {
    .info-actions {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;

        .counters {
            width: 370px;

            .statuses {
                display: flex;
                justify-content: space-between;

                .status {
                    display: flex;
                    align-items: center;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        color: #00a65a;
                    }

                    &.pending {
                        color: #f3ae76;
                    }

                    &.inactive {
                        color: #dd4b39;
                    }

                    .material-icons {
                        font-size: 1.5rem;
                        margin-right: 5px;
                    }
                }
            }
        }

        .actions {
            .btn {
                margin-left: 10px;
            }
        }
    }

    .tableSpacer {
        height: 8px;
    }

    .actionsTd {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        & > :first-child {
            color: #17a2b8 !important;
        }

        & > :nth-child(2) {
            color: $orange !important;
        }

        & > :nth-child(3) {
            color: #30aa4c;
        }

        & > :nth-child(4) {
            color: #b53f3f;
        }
    }

    /* Para garantir que `.noActionsTd` não herda estilos de `.actionsTd` */
    .noActionsTd svg {
        color: $orange !important;
        font-size: 24px;
    }

    .list {
        margin-top: 20px;
        overflow-x: auto;

        .newTable {
            width: 100%;

            thead {
                th:first-child {
                    padding-left: 10px;
                }

                tr {
                    // padding: 12px 0px !important;
                    background-color: #fffefc;
                    border: 1px solid #e5e5e5;
                    font-size: 13px; //before 16px
                    font-family: $font-familyV2;
                    font-weight: 400;
                    color: #605f5f;
                }

                th {
                    padding: 12px 10px;
                }
            }

            tbody {
                td {
                    padding: 6px 8px;
                    font-family: $font-familyV2;
                    font-weight: 300;
                    font-size: 13px;
                    color: #605f5f;

                    text-align: left;
                    justify-content: center;
                    vertical-align: middle;
                }
                td:last-child {
                    border-right: none;
                }
                tr:nth-child(4n + 4) {
                    background-color: #f7f7f7;
                }

                tr {
                    tr {
                        margin-top: 40 !important;
                    }

                    td:first-child {
                        padding-left: 10px;
                    }

                    .actions {
                        justify-content: center;
                        vertical-align: middle;
                        .action {
                            margin: 0 5px 0 0;
                            padding: 3px 5px 0;

                            &.info {
                                color: $buying-companies-action-info-color;
                            }

                            .material-icons {
                                font-size: 1.8rem;
                            }
                        }
                    }

                    .link-salesman {
                        align-items: center;
                        background-color: #00a65a;
                        border-radius: 4px;
                        color: #ffffff;
                        display: flex;
                        height: 30px;
                        justify-content: center;
                        width: 30px;
                    }

                    .status {
                        display: block;
                        font-weight: $bold;
                        text-align: center;
                        padding: 3px 9px;
                        color: white;
                        border-radius: 3px;
                        font-size: 10px;

                        &.active {
                            background-color: #00a65a;
                        }

                        &.pending {
                            background-color: #f3ae76;
                        }

                        &.inactive {
                            background-color: #dd4b39;
                        }
                    }
                }
            }

            .actions {
                > * {
                    font-size: 17px;
                    margin-left: 5px;
                    padding: 4px;
                    cursor: pointer;
                    // border: 1px solid white;

                    &:hover {
                        filter: hue-rotate(75deg);
                    }

                    &:first-child {
                        color: #17a2b8 !important;
                    }

                    &:nth-child(2) {
                        color: $orange;
                    }

                    &:nth-child(3) {
                        color: #30aa4c;
                    }
                }

                svg {
                    font-size: 13px;
                }
            }

            .people {
                cursor: pointer;
                color: $secondary-color;

                &:hover {
                    color: $buying-companies-hover-icon;
                }
            }
        }
    }
}
.containerActionsButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.actionsItemButton {
    margin-left: 10px;
    margin-top: 10px;
}

.headOfficeNameContainer {
    display: flex;
    align-items: center;
    gap: 9.5px;
    text-transform: uppercase;
    svg {
        cursor: pointer;
        font-size: 24px;
    }
}
@media only screen and (max-width: 740px) {
    .containerActionsButtons {
        gap: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .actionsItemButton {
        margin-left: 0px;
        margin-top: 0px;
    }
}

@media screen and (max-width: 900px) {
    .divSellers {
        justify-content: flex-end;
        height: 11px;
    }

    .countSeller {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .list {
        .tableSpacer {
            display: none;
        }

        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }

        .headOfficeNameContainer {
            justify-content: flex-end;
        }
        .actionsTd {
            justify-content: flex-end;
            gap: 22px;
        }
    }
}
