@import 'styles/_variables';

$sidebarSize: 56px;
$sidebarSizeExpanded: 330px;

.sidebar {
    width: $sidebarSize;
    min-width: $sidebarSize;
    // border-right: 1px solid $gray-light-2;
    max-width: $sidebarSize;
    padding: 0 !important;

    > div {
        position: fixed;
        z-index: 99;
    }

    & .logo {
        height: calc(#{$sidebarSize + 20px});

        & img {
            width: 33px;
        }
    }

    .logoutContainer {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 2rem;
    }

    .menu {
        height: 95vh;
        font-family: $font-familyV2;
        // background-color: #fff;
        border-bottom-right-radius: 8px;
        width: $sidebarSize;
        display: flex;
        flex-direction: column;
        gap: 2px;
        -webkit-transition: ease-out 0.1s;
        -moz-transition: ease-out 0.1s;

        span {
            color: #a1a1a1;
            display: none;
            visibility: hidden;
            opacity: 0;
            animation: fadeInLeft 0.5s ease both;
        }

        svg {
            color: #a1a1a1;
            font-size: 20px;
        }
    }

    .menu:hover {
        box-shadow: 20px 3px 15px 0.4px rgba(0, 0, 0, 0.09);
        align-items: unset;
        background-color: #fff;
        width: $sidebarSizeExpanded;
        border-right: 1px solid #e7e7e7;

        span {
            text-transform: capitalize;
            display: unset;
            visibility: visible;
            opacity: 1;
            transition: visibility 2s linear, opacity 2s linear;
            margin-left: 8px;
            text-overflow: clip;
        }
    }

    .item {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 17px;
        height: 47px;

        &:hover {
            background-color: $orange-3;

            border-left: 3px solid $orange;
            padding-left: 14px;
        }
    }

    .itemRelatorio {
        .expandableItem {
            cursor: pointer;
            transition: height 2000ms;
            /* Adicionando a propriedade 'height' aqui */
        }

        .expanded {
            // height: 100%; /* Ou qualquer valor desejado */
            display: block;
            transition: 500ms;

            svg {
                color: $orange;
            }
        }

        .notExpanded {
            display: none;

            transition: 500ms;
        }

        /* Adicione esta regra para garantir uma transição suave no 'height' ao expandir/recolher */
        .expandableItem > div {
            transition: height 2000ms;
        }

        .relatorioICon {
            width: 100%;

            div {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                position: relative;

                padding-right: 20px;

                // svg {
                //     // color: #ff7110;
                // }

                .secondMinus {
                    position: absolute;
                    transform: rotate(90deg);
                    transition: 200ms;
                }

                .secondMinusRotate {
                    position: absolute;

                    transition: 200ms;
                }

                .firstMinus {
                    transition: 200ms;
                }

                .firstMinusRotate {
                    transform: rotate(-90deg);
                    transition: 200ms;
                    opacity: 0;
                }
            }
        }
        :hover {
            svg {
                color: $orange;
            }
        }
    }

    .itemActive {
        display: flex;
        align-items: center;
        padding-left: 17px;
        height: 47px;

        span {
            color: $orange;
        }

        svg {
            color: $orange;
        }

        background-color: $orange-3;
        border-left: 3px solid $orange;
        padding-left: 14px;
    }

    //    & .menu {
    //        display: flex;
    //        flex-direction: column;
    //        flex-grow: 1;

    //        & .item {
    //            justify-content: center;
    //            display: flex;
    //            span {
    //                width: 0;
    //                display: none;
    //                background-color: transparent;
    //                background-image: none;
    //            }
    //            &:hover {
    //                span {
    //                    display: flex;
    //                    background-color: $orange-3;
    //                    align-items: center;
    //                    padding: 0 2rem;
    //                    position: absolute;
    //                    transition: width 2s ease;
    //                    width: fit-content;
    //                    border-radius: 6px;
    //                    white-space: nowrap;
    //                    left: 2.1rem;
    //                    z-index: 1;
    //                    height: 44px;
    //                    color: $primary-color;
    //                    border: $primary-color;
    //                    font-size: 12px;
    //                    font-weight: bold;
    //                    text-transform: uppercase;
    //                }
    //            }
    //            .button {
    //             display: flex;
    //             flex-direction: row;
    //             justify-content: center;
    //             align-items: center;
    //                background-color: transparent;
    //                background-image: none;
    //                border-radius: 12px;
    //                height: 44px;
    //                width: 44px;

    //                &:hover,
    //                &.active {
    //                    background-color: $orange-3;

    //                    & svg {
    //                        color: $orange;
    //                    }
    //                }

    //                svg {
    //                    color: $gray-2;
    //                    font-size: 20px;
    //                    transition: color 0.3s ease;
    //                }
    //            }
    //        }
    //    }

    .user {
        height: calc(#{$sidebarSize + 32px});
        width: calc(#{$sidebarSize});

        position: fixed;
        bottom: 45px;

        .user-menu {
            z-index: 1;
            background-color: #fff;
            border: 1px solid $gray-light-2;
            border-radius: 8px;
            display: none;
            position: fixed;
            left: $sidebarSize;
            min-width: 200px;
            animation: fadeInLeft 0.3s ease-in both;

            &-header {
                background-color: $orange;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                color: #fff;
                padding: 17px 16px;

                h4 {
                    font-size: 16px;
                    font-weight: 400;
                }

                span {
                    font-weight: 700;
                }
            }

            &-content {
                padding: 0;
                font-size: 14px;

                &-item {
                    padding: 17px 16px;
                    border-bottom: 1px solid $gray-light-2;

                    &.logout {
                        width: 100%;
                        border-bottom: 0;
                    }
                }
            }
        }

        &:hover .user-menu {
            display: block;
        }
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }

    to {
        opacity: 1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}

.HeaderContainerMobile {
    display: none;
}

.burgerButton {
    cursor: pointer;
    color: white;
    font-size: 30px;
    position: absolute;
    left: 20px;
}

.clubLogo {
}

.userContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.userDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #e5e5e5;
}

.userDescription {
    margin-left: 20px;
}

.userNameText {
    font-family: $font-familyV2;
    font-weight: 400;
    font-size: 20px;
    color: $orange;
    margin-bottom: 4px;
}

.userCompanyDescription {
    font-family: $font-familyV2;
    font-weight: 400;
    font-size: 16px;
    color: $orange;
}

.userAvatar {
    font-size: 32px;
    color: #898989;
}

.closeButton {
    cursor: pointer;
    font-size: 27px;
    color: #898989;
}

///link container/////

.linksList {
    padding: 15px;
}

.LinkContainer {
    cursor: pointer;

    padding: 10px 20px;
    margin: 5px 0px;

    width: 80%;
}

.LinkContainerSelected {
    padding: 10px 20px;
    margin: 5px 0px;

    width: 80%;
    border-radius: 10px;

    background-color: $orange;

    .LinkIcon {
        color: #fff;
    }

    .linkText {
        font-family: $font-familyV2;
        font-weight: 700;
        color: #fff;
    }
}

.navlinkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.LinkContainer:hover {
    .LinkIcon,
    .linkText {
        color: $orange-4;
    }
}

.LinkIcon {
    color: #ababab;
    font-size: 22px !important;
}

.linkText {
    font-family: $font-familyV2;
    font-weight: 400;

    margin-left: 25px;
    color: #ababab;
    font-size: 18px;
}

.signoutContainer {
    margin-bottom: 80px;
    padding: 10px;
    justify-self: end;
}

.expandedHeader {
    display: none;
}

.sideBarDesktop {
    position: fixed;
    height: 100vh;
    border-right: 1px solid $gray-light-2;
}

.navSidebarMenu {
    // position: fixed;
    // z-index: 99;
    // height: 100vh;
}

.expandedHeaderBackground {
    display: none;
}

.bgGrayDivSideBar {
    height: 100vh;
    width: 20vw;
    background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 740px) {
    .expandedHeaderBackground {
        display: flex;
        flex-direction: row;

        position: fixed;
        top: 0;
        //z-index: 98;
        width: 100vw;
        height: 100vh;
        // background-color: rgba(0,0,0,0.5);
    }

    .sideBarDesktop {
        display: none;
    }

    .sidebar {
        width: 0px;
        min-width: 0px;
        border-right: none;
        //display: contents;
    }

    .HeaderContainerMobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        position: absolute;

        width: 100vw;
        height: 8vh;
        background-color: $orange-4;
    }

    .expandedHeaderUls {
        overflow-y: auto;
    }

    .expandedHeader {
        animation: fadeInLeft 500ms ease;
        display: flex;
        flex-direction: column;

        width: 80%;
        height: 100vh;
        background-color: #fff;
        justify-content: space-between;
    }

    .clubLogo {
        height: 70%;
    }

    .signoutContainer {
        margin-bottom: 0;
    }
}

@media screen and (max-height: 768px) {
    .sidebar {
        .menu {
            overflow-x: auto;
            white-space: nowrap;
            scrollbar-width: none;
            -ms-overflow-style: none;
        }
        .menu:hover {
            scrollbar-width: auto;
            -ms-overflow-style: auto;
        }
    }
}
