@import 'styles/_variables';

.col-lg-12 {
    text-align: right;
}
.nav-tabs {
    padding-bottom: 5px;
    border-bottom: 0.5px solid #e5e5e5;
    display: flex;
    gap: 15px;
}

.provider-edit {
    padding: 25px 0;

    .nav-item {
        border: none;
        border-color: none;
        color: #202020 !important;

        font-family: $font-familyV2;
        font-weight: 400;
        padding: 15px 12px;
        &.active {
            border-color: none;
            background-color: $orange-3;
            color: #303030 !important;
            font-weight: 400;
            font-family: $font-familyV2;

            border-radius: 8px;
            padding: 15px 12px;
            svg {
                color: $orange !important;
            }
        }
        &:hover {
            border-color: none;
            background-color: $orange-3;
            color: $orange !important;
        }
        svg {
            color: $orange;
            margin-right: 11px !important;
        }
        display: flex;
        align-items: center;
    }
    .title {
        margin-bottom: 20px;
    }
    .form {
        .feedbacks {
            box-sizing: border-box;
        }
        .form-group {
            box-sizing: border-box;
            .dropdown {
                width: 100%;
            }
        }
    }
}
@media only screen and (max-width: 740px) {
    .nav-tabs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4px;
    }
    .provider-edit {
        padding: 15px 0;

        .nav-item {
            border: none;
            border-color: none;
            color: #202020 !important;

            font-family: $font-familyV2;
            font-weight: 400;
            padding: 10px 10px;
            &.active {
                border-color: none;
                background-color: $orange-3;
                color: #303030 !important;
                font-weight: 400;
                font-family: $font-familyV2;

                border-radius: 8px;
                padding: 10px 10px;
                svg {
                    color: $orange !important;
                }
            }
            &:hover {
                border-color: none;
                background-color: $orange-3;
                color: $orange !important;
            }
            svg {
                color: $orange;
                margin-right: 11px !important;
            }
            display: flex;
            align-items: center;
        }
        .title {
            margin-bottom: 20px;
        }
        .form {
            .feedbacks {
                box-sizing: border-box;
            }
            .form-group {
                box-sizing: border-box;
                .dropdown {
                    width: 100%;
                }
            }
        }
    }
}
