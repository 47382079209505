@import 'styles/_variables';
@import 'styles/_mixins';

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 150px;
        margin-right: 20px;
    }
}

@media print {
    .company {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        align-items: center;
        display: flex;
    }
}

.company {
    td {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.divContainerOrderDetailsDSOD {
    margin-left: 15px;
}

.divTitleValue {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}
.divTitleValueDetailsBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    align-items: center;
    justify-content: space-between;
}

.DSODvalueDescriptionBox {
    font-family: $font-familyV2;
    font-size: 14px;
    font-weight: 400;
    color: #707070;
    margin-right: 3px;
    border: 1px solid #ababab;
    text-align: center;
    padding: 5px;
    min-width: 230px;
    // width: 70%
}
.DSODTitleDescription {
    font-family: $font-familyV2;
    font-size: 16px;
    font-weight: 400;
    color: #707070;
    margin-right: 3px;
}

.DSODvalueDescription {
    font-family: $font-familyV2;
    font-size: 12px;
    font-weight: 300;
    color: #707070;
    text-align: center;
    line-height: 1.4em;
}

.divTitleValueTitle {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    border-bottom: 1px solid #dadada;
    padding-bottom: 6px;
}

.titleDSODTitleDescription {
    font-family: $font-familyV2;
    font-size: 20px;
    font-weight: 400;
    color: #707070;
    margin-right: 3px;
}

.titleDSODvalueDescription {
    font-family: $font-familyV2;
    font-size: 20px;
    font-weight: 400;
    color: #707070;
    text-align: center;
}

.listDirectSalesOrderDetails {
    margin-top: 20px;
    overflow-x: auto;

    .NewTableDirectSalesOrderDetails {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 10px;
                font-family: $font-familyV2;
                font-weight: 400;
                font-size: 12px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(even) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;

                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }

                .link-salesman {
                    align-items: center;
                    background-color: #00a65a;
                    border-radius: 4px;
                    color: #ffffff;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    width: 30px;
                }

                .status {
                    display: block;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        background-color: #00a65a;
                    }

                    &.pending {
                        background-color: #f3ae76;
                    }

                    &.inactive {
                        background-color: #dd4b39;
                    }
                }
            }
        }

        .actions {
            > * {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
                // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17a2b8 !important;
                }

                &:nth-child(2) {
                    color: $orange;
                }

                &:nth-child(3) {
                    color: #30aa4c;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}

.sectionContainerDSOD {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

@media only screen and (max-width: 840px) {
    .sectionContainerDSOD {
        display: flex;
        flex-direction: column;
    }

    .divContainerOrderDetailsDSOD {
        margin-left: 0px;
    }

    .entregaRotadiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 25px;
        // align-items: center;
        // justify-content: flex-end;
        // text-align: center;
    }

    .divStatusVendedor {
        // background-color: pink;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        justify-content: flex-end;
    }

    .listDirectSalesOrderDetails {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //   text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }
    }

    td .orderIdContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .pixPaymentContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 5px;
    }

    .externalOrder {
        padding: 2px 7px;
    }

    .TdAcoes {
    }

    .divTdAcoesDSO {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;

        svg {
            font-size: 25px !important;
        }
    }

    .divIntegrationDSO {
        display: flex;
        gap: 22px;
        justify-content: flex-end;

        svg {
            font-size: 22px;
        }
    }
}
