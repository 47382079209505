@import 'styles/_variables';
@import 'styles/_mixins';

.statuses {
    display: flex;
    justify-content: flex-end;

    .status {
        font-size: 15px;
        display: flex;
        align-items: center;
        font-weight: $regular;
        margin-right: 15px;

        &.master {
            color: #0050b3;
        }

        &.supervisor {
            color: #00a65a;
        }

        &.salesman {
            color: #f3ae76;
        }

        .material-icons {
            margin-right: 5px;
        }
    }
}

.tdDivActionsRepresentatives {
    display: flex;
    align-items: center;
    justify-content: center;
}

.actionsRepresentatives {
    svg {
        font-size: 15px;
        margin-left: 5px;
        padding: 4px;
        cursor: pointer;
        border: 1px solid white;

        &:hover {
            filter: hue-rotate(75deg);
        }

        &:first-child {
            color: $red;
        }

        &:nth-child(2) {
            color: $secondary-color;
        }

        &:nth-child(3) {
            color: $ternary-color;
        }
    }
}

.profile-icon {
    color: #807368;

    svg {
        font-size: 15px;
        margin-right: 10px;

        &.master {
            color: #0050b3;
        }

        &.supervisor {
            color: #00a65a;
        }

        &.seller {
            // color: #807368;
            // margin-right: 3px;
        }
    }
}

.iconPerfil {
    margin-right: 5px;
}

.infos-and-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: $secondary-color;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;

    bottom: 115%;
    left: 50%;
    margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.buttonItemAdd {
    margin-right: 3%;
}

.iconsActionsExclude {
    cursor: pointer;
    color: '#ff1010' !important;
}

.iconsActionsEdit {
    cursor: pointer;
    color: '#ff7110' !important;
}

.tdPerfilDiv {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    min-width: 160px;
}

.listRepresentatives {
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden !important;

    .newTableRepresentatives {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;

                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }

                .link-salesman {
                    align-items: center;
                    background-color: #00a65a;
                    border-radius: 4px;
                    color: #ffffff;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    width: 30px;
                }

                .status {
                    display: block;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        background-color: #00a65a;
                    }

                    &.pending {
                        background-color: #f3ae76;
                    }

                    &.inactive {
                        background-color: #dd4b39;
                    }
                }
            }

            .tdParticipacaoRepre {
                display: flex;
                align-items: center;
                justify-content: center;

                min-width: 70px;
                height: 100%;
            }

            .participationTd {
                width: 100%;
                color: white;
                font-weight: 400;
                font-size: 14px;
                border-radius: 4px;
                padding: 4px 5px;

                // opacity: 0.7;
                // cursor: pointer;

                &.high {
                    background-color: #eaf7ed;
                    color: #17a2b8;
                }

                &.low {
                    background-color: #faeaea;
                    color: #cd2a2a;
                }

                &.medium {
                    background-color: #fff1e7;
                    color: #ff7110;
                }
            }

            .divClients {
                width: 100%;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .countClients {
                width: 100%;
                max-width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px 4px 4px 4px;
                border-radius: 5px;
                background-color: $orange;
                color: white;
                font-weight: 500;
                font-size: 14px;
                // margin-top: 4px;
            }
        }

        .svgactionsRepresentatives {
            font-size: 20px;
            margin-left: 3px;
            cursor: pointer;
        }

        .actionsRepresentatives {
            svg {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
                // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17a2b8 !important;
                }

                &:nth-child(2) {
                    color: #ff7110;
                }

                &:nth-child(3) {
                    color: #30aa4c;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}

.perfildesc {
    text-transform: uppercase;
}
.tdContainer {
    margin-top: 3px;
}
.participationTdDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    cursor: pointer;

    div {
        margin-top: 1px;
    }
}

.filterQtdClients {
    display: flex;
    flex: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    cursor: pointer;

    span {
        white-space: nowrap;
    }
}

@media screen and (max-width: 1016px) {
    .listRepresentatives {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }

        .list-actionsGrupoP {
            justify-content: flex-end;
            gap: 22px;

            svg {
                font-size: 25px;
            }
        }

        .tdPerfilDiv {
            justify-content: flex-end;
            align-items: center;

            p {
                text-transform: uppercase;
            }
        }

        .toggleTdDiv {
            display: flex;
            justify-content: flex-end;
        }

        .tdDivActionsRepresentatives {
            justify-content: flex-end;
            gap: 25px;

            .svgactionsRepresentatives {
                font-size: 27px;
            }
        }
    }

    .tdContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        min-height: 14px;
    }

    .tdParticipacao {
        height: 28px;
    }

    .tdParticipacaoRepre {
        float: right;

        span {
            max-width: 70px;
            text-align: center;
        }
    }

    .tdClients {
        position: relative;
        min-height: 35px;
    }
    .divClients {
        position: absolute;
        max-width: 40px;
        top: 0;
        right: 0;

        .countClients {
            margin-bottom: 10px;
        }
    }
}
