@import 'styles/_variables';

.mainContainerModalDetailOrderPdf {
    font-family: $font-familyV2;

    width: 1400px;
    position: absolute;
    left: 999999px;
}
.titleNameOrderDetailModal {
    padding: 6px 0px;
}
.NewlistModalOrderDetailsPDF {
    margin-top: 15px;
    overflow-x: auto;

    .newTableModalOrderDetailsPDF {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }
            }
        }
    }
}

.containerHeaderOrderDetailModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.containerOrderDetailModalInfosParent {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.containerOrderDetailModalInfos {
    display: flex;
    flex-direction: column;

    width: 45%;
}
.containerOrderDetailModalInfosRowObs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
}
.containerOrderDetailModalfooter {
    border-top: 1px solid #c7c7c7;
    padding: 10px 5px;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.divRowClientOrderDetail {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.OrderDetailModalInfosTitle {
    color: $orange;
    margin-bottom: 10px;
}

// @media only screen and (max-width: 740px) {
//     .containerHeaderOrderDetailModal {
//         flex-direction: column;
//     }

//     .containerOrderDetailModalInfosParent{
//         display: flex;
//         flex-direction: column;
//         gap: 10px;
//     }
//     .containerOrderDetailModalInfos{
//         width: 100%;
//     }
//     .containerOrderDetailModalInfosRowObs{
//         flex-direction: column;
//         gap: 5px;
//     }
//     .containerOrderDetailModalfooter{
//         display: flex;
//         flex-direction: column;
//         gap: 5px;

//     }
//     .divRowClientOrderDetail{
//         display: flex;
//         flex-direction: column;
//         gap: unset;

//     }
// }
// @media screen and (max-width: 900px) {
//     .NewlistModalOrderDetails {

//         table {
//             border: 0;
//         }

//         table caption {
//             font-size: 1.3em;
//         }

//         table thead {
//             border: none;
//             clip: rect(0 0 0 0);
//             height: 1px;
//             margin: -1px;
//             overflow: hidden;
//             padding: 0;
//             position: absolute;
//             width: 1px;
//         }

//         table tr {
//             border-bottom: 3px solid #ddd;
//             display: block;
//             margin-bottom: .625em;
//         }

//         table td {
//             border-bottom: 1px solid #ddd;
//             display: block;
//             font-size: .8em;
//             text-align: right !important;
//         }

//         table td::before {
//             /*
//           * aria-label has no advantage, it won't be read inside a table
//           content: attr(aria-label);
//           */
//             content: attr(data-label);
//             float: left;
//             font-weight: bold;
//             //text-transform: uppercase;
//         }

//         table td:last-child {
//             border-bottom: 0;
//         }

//     }

// }
