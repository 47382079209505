@import 'styles/_variables';
@import 'styles/_mixins';

$first-col-width: 120px;

.offerObs {
    height: 35px !important;
}

.wrapper {
    width: 100%;

    font-family: $font-familyV2;
    font-weight: 300;
    margin-bottom: 15px;

    .showbrands {
        background-color: #0677C9;
        color: #fff;
        padding: 4px 5px;
        text-align: center;
        border-radius: 7px;
        font-weight: 400;
        font-size: 13px;
        margin-right: 20px;
        cursor: pointer;
    }
    .showbrandsDisabled{
        background-color: #c7c7c7;
        color: #fff;
        padding: 4px 5px;
        text-align: center;
        border-radius: 7px;
        font-weight: 400;
        font-size: 13px;
        margin-right: 20px;
        cursor: not-allowed;
    }

    .codigoOffer {
        padding: 0px 4px;
        width: 115px;
        display: flex;
        align-items: center;
        justify-content: center;
        //word-wrap: break-word;
        word-break: break-word;
        text-align: center;

        p{

            font-weight: 300 !important;
            font-size: 12px;
        }
    }

    .prodNameOffer {
        //font-family: $font-familyV2;
        font-size: 13px;
        font-weight: 500 !important;
        width: 100%;
        text-justify: auto;

    }

    .infOfferItem {
        display: flex;
        align-items: center;
        font-family: $font-familyV2;
        font-size: 13px !important;
        font-weight: 300 !important;
        margin-right: 80px;
        display: flex;
        justify-content: flex-start;

        width: 260px;

    }

    .circle {
        width: 130px !important;
        display: flex;
        align-items: center;
    }

    .circle svg {
        padding-left: 10px;
        padding-right: 10px;
        color: $ternary-color;
        visibility: hidden;
        width: 15px;
    }

    .currencyOffer {
        width: 100px;
        text-align: center;
        height: 20px;
        font-weight: 300;
        font-size: 14px;
    }

    &:focus-within {
        .circle svg {
            visibility: visible;
        }
    }

    .itemUnity {
        font-size: 14px;
        font-weight: 300;
        color: black !important;
        height: 35px !important;

        &.saved {
            background-color: #9cf5d1;
        }
    }

    .offerObs {
        height: 35px !important;
    }
}

a .header {

    :global {
        .col {
            border-bottom: 1px solid #dee2e6;
            font-weight: $bold  !important;

            &:nth-child(2) {
                min-width: 42%;
            }

            &:nth-child(3) {
                min-width: 0%;
            }

            &:nth-child(4),
            &:nth-child(5) {
                max-width: 10%;
            }

            &:nth-child(6) {
                min-width: 10%;
            }
        }
    }
}

.main {
    height: 45px !important;
    flex-direction: row;
    justify-content:space-between;
    border-bottom: 6px solid #fff;
    padding: 5px 0px;

    :global {
        .col {
            color: $gray-2;
            font-weight: 600;
            height: 40px;

            &:nth-child(2) {
                //  min-width: 40%;
                white-space: nowrap;
                font-size: 13px;
            }

            &:nth-child(3) {
                justify-content: flex-start;
                //   min-width: 17.3%;
            }

            &:nth-child(4) {
                // white-space: nowrap;
            }

            &:nth-child(5) {
                //   max-width: 12%;
            }
        }
    }
}

.price-actions {
    // position: sticky;
    //right: -1.4rem;


    display: flex;
    align-items: center;
    flex-direction: row;
    //height: 100%;
    margin-left: 8px;

    justify-content: center;



}

.copy {
    margin-right: 2px !important;
    visibility: visible !important;
    color: #53b2e0 !important;
    //right: -1.4rem;
    z-index: 1;
    font-size: 20px !important;

    position: relative;
    cursor: pointer;
}

.previous {

    margin-right: 0 !important;
    color: $ternary-color  !important;
    font-size: 20px !important;
    //right: -1.4rem;
    position: relative;
    cursor: pointer;
    z-index: 1;
}

.brand {
    //background-color: red;
    // color: red;

    .saved {
        background-color: #9cf5d1;
        color: black;
        border: 1px solid white;
    }

    :global {
        .col {
            color: $gray-2;
            font-weight: 600;


            &:nth-child(2) {
                // min-width: 15%;

            }

            &:nth-child(3) {
                justify-content: flex-end;
                //      min-width: 10%;
            }

            &:nth-child(4),
            &:nth-child(5) {
                //    max-width: 15%

            }

            &:nth-child(6) {
                //    max-width: 15%;
            }
        }
    }
}

.hearth {
    font-family: $font-familyV2;
    font-weight: 500;
    font-size: 13px;
    color: #ff7110;

    :global {
        .on {
            svg {
                color: $gray-2  !important;
            }
        }
    }
}
