.dropdown {
  @include border-radius;
  border: 1px solid $dropdown-border-color;
  color: $dropdown-color;
  font-size: 1.5rem;
  padding: 8px;
  width: 20vw;
  height: 4.38vh;
  background-color: $gray-light;
}
