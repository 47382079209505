@import '../../styles/variables';
@import '../../styles/mixins';

.status {
    color: $status-color;
    font-size: 13px;
    font-weight: 400;
    font-family: $font-familyV2;
    @media screen and (max-width: 900px) {
        font-size: 14px;
    }
    &.typed {
        color: #2ACD72;;
    }
    &.ordered {
        color: #17A2B8;
    }
    &.not-ordered {
        color: #101010;
    }
    &.not-offered {
        color:
        #101010;
    }
    &.not-typed {
        color: #9555FF;
    }
    &.frozen {
        color: #605F5F;
    }
    &.canceled {
        color: #CD2A2A;
    }
}
