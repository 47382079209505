@import '../../styles/variables';
@import '../../styles/mixins';

.status {
    color: $status-color;
    font-family: $font-familyV2;
    font-weight: 400;
    font-size: 12px;
    // text-align: center;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &.confirmed {
        color: #30AA4C;
    }
    &.not-confirmed {
        color: #ff7110;
    }
    &.canceled {
        color: $red;
    }
    &.waitingConfirmation {
        color: #9555FF; ///<<<<
    }
    &.typed {
        color: black; ///<<<<
    }
    &.ordered {
        color: #5281FF; ///<<<<
    }

    &.waitingPayment {
        color: #ff7110; ///<<<<
    }

}
