@import 'styles/_variables';

@keyframes slideInFromRight {
    0% {
        transform: translatex(4%);
        opacity: 0;
    }

    100% {
        transform: translatex(0);
        opacity: 1;
    }
}

.buttonv3 {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 5px;
    border-radius: 5px;
    padding: 13px 15px;

    transition: margin 100ms ease-in-out;
    animation: 0.5s ease 0s 1 slideInFromRight;

    //@at-root: #FAFAFC;
    background-color: #ffff;
    border: 1px solid #e5e5e5;
}

.buttonFilled {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 5px;
    border-radius: 5px;
    padding: 13px 15px;

    transition: margin 100ms ease-in-out;
    animation: 0.5s ease 0s 1 slideInFromRight;
    background-color: $orange;
    border: none;
    &:active {
        background-color: $orange-4;
        box-shadow: 0 0 2px $orange;
    }
}

.textFilled {
    font-family: $font-familyV2;
    font-weight: 600;
    letter-spacing: 1.1px;
    color: #fff;
    line-height: 1.5em;
    font-size: 13px;
}

.nonFilledText {
    font-family: $font-familyV2;
    font-weight: 600;
    color: #505050;
    font-size: 13px;
}

.buttonv3:active {
    box-shadow: 0 0 2px $orange;
}
