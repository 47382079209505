@import 'styles/_variables';

.provider-edit {
    padding: 25px 0;

    .nav-item {
        color: $gray-2;
        &.active {
            color: $secondary-color;
            font-weight: 500;
            svg {
                color: $secondary-color;
            }
        }
        svg {
            margin-right: 4px;
        }
        display: flex;
    }
    .title {
        margin-bottom: 20px;
    }
    .form {
        .feedbacks {
            box-sizing: border-box;
        }
        .form-group {
            box-sizing: border-box;
            .dropdown {
                width: 100%;
            }
        }
    }
}

.produto{
    padding: 5px 10px 5px 10px !important;
}

.detalhes-azul{
    color: #0050b3;
    font-size: 12px;
}

.btn-large{
    margin-top: 20px;
    height: 40px !important;
}

.btn-azul{
    border: 1px solid #003267 !important;
    background: #003267 !important;
}

.btn-laranja{
    border: 1px solid #ff7110 !important;
    background: #ff7110 !important;
}

.btn-vermelho{
    border: 1px solid #cf0902 !important;
    background: #cf0902 !important;
}

.btn-verde{
    border: 1px solid #008e6a !important;
    background: #008e6a !important;
}

.btn-acoes-deletar{
    font-size: 18px;
    margin-right: 10px;
    color: #b60101
}

.btn-acoes-editar{
    font-size: 18px;
    margin-right: 10px;
    color: #008e6a
}

.hidden{
    display: none;
}


.listDetailCampaign {
    width: 100%;
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: auto;
    height: 470px;



    .newTableDetailCampaign {
        overflow-y: auto;

        width: 100%;

        thead {
            // position: sticky;
            // top: 0;

            th:first-child {
                padding-left: 10px;

            }

            tr {

                // padding: 12px 0px !important;
                background-color: #FFFEFC;
                border: 1px solid #E5E5E5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605F5F;
            }

            th {
                padding: 12px 10px;
                text-justify: center;
            }
        }

        tbody {
            td {
                padding: 8px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605F5F;

                text-align: left;
                justify-content: center;
                vertical-align: middle;


            }

            td:last-child {
                border-right: none;

            }

            tr:nth-child(even) {
                background-color: #F7F7F7;

            }

            tr {
                tr {
                    margin-top: 40 !important;

                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;

                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }



            }
        }


    }
}
