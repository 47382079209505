@import '../../styles/variables';
@import '../../styles/mixins';

.print-only {
    display: none;
}

@media print {
    .print-only {
        display: block;
    }

    .row {
        margin-bottom: 0 !important;
    }
}

.actions {
    display: flex;

    :global {
        svg {
            font-size: 1.6rem;
            margin-left: 2px;
            padding: 4px;
            cursor: pointer;

            &:hover {
                filter: hue-rotate(75deg);
            }

            &:first-child {
                color: $primary-color;
            }

            &:nth-child(2) {
                color: $secondary-color;
            }

            &:nth-child(3) {
                color: $ternary-color;
            }
        }
    }
}

.order-list-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    :global(.date-selection) {
        font-size: 14px;
        color: $gray-5;
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
}

.NewListOrders {
    margin-top: 20px;
    overflow-x: auto;
    height: 50vh;

    .newTableOrders {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;

            }

            tr {

                // padding: 12px 0px !important;
                background-color: #FFFEFC;
                border: 1px solid #E5E5E5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605F5F;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605F5F;

                text-align: left;
                justify-content: center;
                vertical-align: middle;


            }

            td:last-child {
                border-right: none;

            }

            tr:nth-child(odd) {
                background-color: #F7F7F7;

            }

            tr {
                tr {
                    margin-top: 40 !important;

                }

                td:first-child {
                    padding-left: 10px;
                }


            }
        }





    }
}


.hideOnMobile {
    display: default;

    @media screen and (max-width: 900px) {
        background-color: pink;
        display: none !important;
    }
}

.showOnlyInMobile {
    display: none;

    @media screen and (max-width: 900px) {
        display: default;
    }
}

.rowIdStatus {
    display: flex;
    justify-content: space-between;

    span {
        font-weight: 500;
    }
}

.storeNameMobile {
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    align-items: flex-end;
    // border-bottom: 1px solid linear-gradient(to right, $primary-color, transparent);
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, $primary-color -30%, transparent 95%) 100% 0%;
    padding: 4px 8px;

    // background-image: linear-gradient(to right, $primary-color -100%, transparent 30%);
    svg {
        color: $primary-color;
        font-size: 17px;

    }

    p {
        // padding-top: 3px;
        // padding: 2px 5px;
        font-size: 0.8rem;
        margin-left: 6px;
    }
}

.ratingOnActions {
    display: none;

    @media screen and (max-width: 900px) {
        display: flex;
        // background-color: red;
        // height: 30px;
        padding: 0;

        font-size: 17px !important;

        svg {
            font-size: 25px;
        }

        p {}

        // width: 50px;
    }
}


@media screen and (max-width: 900px) {
    .NewListOrders {
        height: 70vh;

        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            // text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }

        .actions {
            justify-content: flex-end;
            gap: 20px;
            align-items: center;

            svg {
                font-size: 30px;
            }
        }
    }


}

.trMobile {
    @media screen and (max-width: 900px) {

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.06);
        padding: 7px 0;
        margin: 2px;

        td {
            // padding: 0 50px;
        }
    }
}
