@import '../../styles/variables';

.header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    .title {
        display: flex;
        font-size: 32px;
        color: $grayTitle;
        font-weight: 500;
        padding: 10px 0;
        margin-bottom: 12px;
    }
    .icon {
        font-size: 30px;
        color: $primary-color;
        margin-right: 5px;
        display: inline;
        align-items: center;
    }
}
