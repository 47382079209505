@import "styles/_variables";


.mainDiv {
    margin-top: 50px;
    min-height: 350px;
}


@keyframes slideInFromTop {
    0% {
        transform: translatey(-2%);
        opacity: 0;
    }

    100% {
        transform: translatey(0);
        opacity: 1;
    }
}



.firstContainer {
    min-height: 0;
    max-height: 100vh;
    animation: 0.5s ease 0s 1 slideInFromTop;
}

.container {
    display: grid;

    //transition: grid-template-columns 100ms ease-in-out;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(320px, 500px));
    column-gap: 100px;
    animation: 0.5s ease 0s 1 slideInFromTop;
}

.selectSection {
    margin: 0 auto;
    min-width: 320px;
    max-width: 500px;
}

.footerContainer {

    display: grid;

    //transition: grid-template-columns 100ms ease-in-out;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(320px, 500px));
    column-gap: 100px;
    margin-bottom: 50px;

    //margin: 15px auto;

    //width: 85%;
    //display: flex;
    //justify-content: flex-end;

    // display: grid;
    // justify-content: center;
    // grid-template-columns: repeat(auto-fill, minmax(320px, 500px));
    // column-gap: 100px;
    // padding-bottom: 70px;

}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 15px;
    margin-top: 60px;
    animation: 0.5s ease 0s 1 slideInFromTop;
}

.label {
    color: #898989;
    //padding: 24px 11px 4px 0px;
    font-family: $font-familyV2;
    font-size: 20px;
}

.inputSelect {
    width: 100%;
    padding: 12px;
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;

    outline-width: 0px;
}

.inputSelect:focus {
    border-color: $orange;
}

.containerSelect {
    width: 100%;
    padding: 0px 0px;


}

//table ///

@media only screen and (max-width: 1300px) {
    .page-table-content-overflow {
        overflow-x: auto;
    }
}



.tableContainerDiv {
    padding: 50px 30px;
    min-width: 1400px
}

.headerTableDiv {
    display: flex;
    flex-direction: row;
    //  padding: 12px 45px;
    border: 1px solid #e5e5e5;
    width: 1400px;
    margin: 0 auto;


}

.headerTableDiv {}

.textHeaderContainer {


    padding: 14px 0px;
    width: 300px;

    text-align: center;


}

.textHeaderContainer:last-child {

    max-width: 250px;
    min-width: 200px;
}

.textHeader {
    // padding-left: 60px;

}


//tablebody ?????????????????????
.tableContainerDiv {
    padding: 50px 30px;
}


.bodyTableDiv {
    display: flex;
    flex-direction: row;
    width: 1400px;
    margin: 0 auto;


    //  padding: 12px 45px;
    //justify-content: space-around;

}

.textBodyContainer {
    //min-width: 240px;
    //max-width: 320px;
    width: 300px;
    padding: 14px 0px;
    border: 1px solid red;
    text-align: center;


    margin: 10px 0px; //////
}

.textBodyContainer:last-child {
    max-width: 250px;
    min-width: 200px;
}


.textBody {

    //padding-left: 60px;
    //margin: 0 auto;

    //vertical-align: middle
}

.bodyTableDiv .textBodyContainer:nth-child(1n+2) {

    border-left: 1px solid #DDE5E9;
}

.bodyTableDiv:nth-child(odd) {
    background-color: #f7f7f7;
    //border-left: 1px solid #DDE5E9;
}

////////////////////////////////////////



.tableDiv {
    //border: 1px solid #ccc;
    animation: 0.5s ease 0s 1 slideInFromTop;

    border-collapse: collapse;
    margin: 15px auto;
    padding: 0;
    width: 85%;
    margin-bottom: 50px;


    table-layout: fixed;

}

.tableHeader {
    border: 1px solid #E5E5E5;
    background-color: #FFFEFC;
}


table tr {
    margin: 0 auto;

}

.headerTableTr {
    text-align: center;
    padding: 0.90em;
}

.headerTableTh {
    padding: 10px;
    margin-bottom: 15px;
    font-family: $font-familyV2;
    font-weight: 400;
    font-size: 16px;
}

.bodyTableTd {

    font-family: $font-familyV2;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    padding: 15px 0px;
    overflow: auto;

}

.bodyTableTr {
    height: 50px;
    animation: 0.5s ease 0s 1 slideInFromTop;
}

.bodyTableTr:nth-child(odd) {
    background-color: #f7f7f7;

}

.bodyTableTd:nth-child(1n+2) {
    border-left: 1px solid #e5e5e5;
    // background-color: red;

}

.bodyTableTd::-webkit-scrollbar {
    display: none;
}

.bodyTableTd {

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.spacer {
    height: 10px;
}

.headerSpacer {
    height: 10px;
}

.buttonTable {
    font-size: 20px;
    margin: 0px 5px;
    cursor: pointer;
}

.buttonTable:nth-child(2) {
    color: #CD2A2A;
}

.buttonTable:nth-child(3) {
    color: $orange;
}

.addButtonContainer {
    animation: 0.5s ease 0s 1 slideInFromTop;
    margin: 15px auto;

    width: 85%;
    display: flex;
    justify-content: flex-end;
}

.titleContainer {
    min-width: 692px;
    max-width: 1067px;
    display: flex;
    flex-direction: row;
    //width: 64%;
    margin: 0 auto;

    //animation: 0.5s ease 0s 1 slideInFromTop;
    //margin: 15px auto;
    //width: 100px;
    //margin-left: ;
    //display: flex;
    //justify-content: flex-start;
}

.addUserButton {
    border: 1px solid #E5E5E5;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 17px 15px;
    margin: 10px 0px 10px 0px;
    cursor: pointer;
}

.addUserButton:active {
    margin: 11px 0px 9px 0px
}

.addUserButton:hover {
    box-shadow: 0 0 3px #E5E5E5;
}

.addUserText {
    font-family: $font-familyV2;
    font-weight: 600;
    font-size: 14px;
}

.addUserIcon {
    margin-bottom: 3px;
    color: $orange;
    margin-right: 6px;
    font-size: 18px;
}

.titleText {
    font-family: $font-familyV2;
    color: #606060;
    font-weight: 400;
    font-size: 22px;
}

@media only screen and (max-width: 1400px) {
    .tableDiv {
        overflow-x: auto;
        width: 1050px;
    }

    .titleContainer {
        width: 60% !important;
        min-width: 360px;
    }
}




.NewListAddUserVendaDireta {
    padding: 0px 20px;
    margin-top: 20px;
    overflow-x: auto;


    table {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;

            }

            tr {

                // padding: 12px 0px !important;
                background-color: #FFFEFC;
                border: 1px solid #E5E5E5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605F5F;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605F5F;

                text-align: left;
                justify-content: center;
                vertical-align: middle;


            }

            td:last-child {
                border-right: none;

            }

            tr:nth-child(even) {
                background-color: #F7F7F7;

            }

            tr {
                tr {
                    margin-top: 40 !important;

                }

                td:first-child {
                    padding-left: 10px;
                }



            }
        }




    }
}





@media screen and (max-width: 900px) {
    .NewListAddUserVendaDireta {


        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;

        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;


        }

        table td:last-child {
            border-bottom: 0;
        }


        .list-actionsGrupoP {
            justify-content: flex-end;
            gap: 22px;

            svg {
                font-size: 25px;
            }
        }


    }


}
