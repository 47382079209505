$light: 100;
$regular: 400;
$medium: 500;
$bold: 700;

$font-family: 'Open Sans', sans-serif;
$font-familyV2: 'Rubik', sans-serif;
$font-style: normal;
$font-size: 17px;
$border-radius: 5px;

$input-height: 39px;
$input-width: 20rem;

$green-light: #00a65a;
$green: #226f54;
$orange: var(--primary, #ff7110);
$orange-2: #f39c12;
$orange-3: var(--primary-opacity, #fdf0e9);
$orange-4: var(--primary-header, #ff9954);

$white: #fff;
$gray-light: #f5f5f5;
$gray-light-2: #dddddd;
$gray-light-3: #d9d9d9;
$gray-light-4: #cacaca;
$gray-light-5: #f5f5f5;
$gray-light-6: #f8f8f8;
$gray-light-7: #eeeeee;
$gray: #949393;
$grayTitle: #605f5f;

$gray-2: #707070;
$gray-3: #d2d6de;
$gray-4: #bebebe;
$gray-5: #757575;
$gray-6: #7a7879;
$gray-dark: #353a40;
$gray-dark-2: #333333;
$blue-light: #e7eefe;
$blue-light-2: #17a2b8;
$blue: #070707;
$black: #000;
$red: #f5222d;
$blue1: #4267b2;

$primary-color: $orange;
$primary-color2: $orange-4;
$secondary-color: $blue1;
$ternary-color: #20ce88;

// selection
$selection-bg-color: $green;
$selection-color: $white;

// header
$header-bg-color: $orange;
$header-color: $white;

// sidebar
$sidebar-bg-color: $white;
$sidebar-item-link-color: $blue;
$sidebar-item-hover-color: $orange;
$sidebar-item-hover-bg-color: $orange;
$sidebar-sublinks-bg-color: $gray-light;
$sidebar-sublinks-color: $blue;

// advise
$advise-bg-color: $blue-light;
$advise-color: $gray;

// button
$button-border-color: $orange;
$button-color: $orange;
$button-primary-bg-color: $orange;
$button-primary-border-color: $orange;
$button-primary-color: $white;

// table
$table-color-th: $gray-6;
$table-color-td: $gray-6;
$table-border-color-td: $gray-light;

// status
$status-color: $white;
$status-confirmed-bg-color: $green-light;
$status-not-confirmed-bg-color: $orange-2;
$status-waiting-confirmation-bg-color: $orange;
$status-canceled-bg-color: $red;

// dropdown
$dropdown-color: $gray-dark;
$dropdown-border-color: $gray-light-2;

// input
$placeholder-input-color: $gray-dark;
$input-color: $black;
$input-border-color: $gray-light-2;

// pagination
$pagination-color: $gray-dark-2;
$pagination-active-bg-color: $orange;
$pagination-active-border-color: $orange;
$pagination-active-color: $white;

////////// PAGES //////////

// sign-in
$sign-in-box-bg-color: $white;
$sign-in-box-header-border-color: $gray-light-4;
$sign-in-box-header-title-color: $orange;
$sign-in-box-body-form-field-bg-color: $gray-light-3;
$sign-in-box-body-form-button-bg-color: $orange;
$sign-in-box-body-form-button-color: $white;

// direct sale order
$direct-sale-order-color: $gray-dark;
$direct-sale-order-action-border-color: $gray-light-2;
$direct-sale-order-action-info-color: $blue-light-2;
$direct-sale-order-action-print-color: $gray-dark-2;

// direct-sale-order-details
$direct-sale-order-overlay-bg-color: rgba($black, 0.3);
$direct-sale-order-content-bg-color: $white;
$direct-sale-order-content-header-bg-color: $orange-2;
$direct-sale-order-content-header-color: $white;
$direct-sale-order-content-body-color: $white;
$direct-sale-order-content-body-border-color: $gray-light-2;
$direct-sale-order-content-body-status-bg-color: $green-light;

// order-details
$order-overlay-bg-color: rgba($black, 0.3);
$order-content-bg-color: $white;
$order-content-header-bg-color: $orange;
$order-content-header-color: $white;
$order-content-body-color: $white;
$order-content-body-border-color: $gray-light-2;
$order-content-body-status-bg-color: $green-light;

// More information Modal
$more-information-modal-button-actived: $orange;
$more-information-modal-button-inactived: $gray;
$more-information-modal-button-inactived-color: $white;

// direct sale parameter
$direct-sale-parameter-save-button: $green-light;
$direct-sale-parameter-title-button: $white;
$direct-sale-parameter-header_background: $gray-light-5;
$direct-sale-parameter-header_client: $gray;
$direct-sale-parameter-form-label: $gray-2;
$direct-sale-parameter-form-color-select: $black;

// buying companies
$buying-companies-hover-icon: $orange;
$buying-companies-action-border-color: $gray-light-2;
$buying-companies-action-info-color: $blue-light-2;
