@import 'styles/_variables';

.header-info-modal {
    background-color: $direct-sale-parameter-header_background;
    padding: 25px 0;

    .name {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        color: $primary-color;
    }

    .lottie {
        width: 5rem;
    }
}
.titleProductOnGroupsChoose{
    font-family: $font-familyV2;
    font-size: 24px;
    font-weight: 400;
    color: #898989;
}
.groups-container {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
}

.table {
    border: none;
    thead th,
    tbody td {
        font-size: 12px;
        padding: 5px 10px !important;
    }
}

.modal-dialog {
    min-width: 90vw;
    .modal-body {
        height: fit-content;
        max-height: 90vh;
    }
}

.actions {
    display: flex;
    justify-content: center;
    button {
        white-space: nowrap;
    }
}

.status {
    display: block;
    font-weight: $bold;
    text-align: center;
    padding: 3px 9px;
    color: white;
    border-radius: 3px;
    font-size: 10px;
    margin: 5px 0;
    &.active {
        background-color: #00a65a;
    }
    &.pending {
        background-color: #f3ae76;
    }
    &.inactive {
        background-color: #dd4b39;
    }
}

.condition-body {
    margin: 20px 0;

    .form-condition {
        justify-content: flex-start;
        margin: 0 25px;

        .form-label-direct-sale-parameter {
            font-size: 1.3rem;
            color: $direct-sale-parameter-form-label;
        }

        .form-input-direct-sale-parameter {
            margin: 10px 0px;
        }
        .form-select-direct-sale-parameter {
            margin: 10px 0px;
            color: $direct-sale-parameter-form-color-select;
        }

        .form-check-label {
            margin: 15px;
        }

        .save {
            background-color: $direct-sale-parameter-save-button;
            border-color: $direct-sale-parameter-save-button;
            color: $direct-sale-parameter-title-button;
        }
    }
}
