@keyframes slideInFromRight {
    0% {
        transform: translatex(4%);
        opacity: 0;
    }

    100% {
        transform: translatex(0);
        opacity: 1;
    }
}

.card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    // padding-right: 60px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #e5e5e53c;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    height: 100%;
    transition: margin 100ms ease-in-out;
    animation: 1.2s ease 0s 1 slideInFromRight;
}

.card-title {
    font-size: 20px;
    font-weight: 600;
    color: #757575;
    margin-bottom: 8px;
}

.card-total {
    font-size: 22px;
    font-weight: 600;
    color: #212121;
    margin-bottom: 8px;
}

.card-totalPrice {
    font-size: 22px;
    font-weight: 600;
    color: #212121;
    margin-bottom: 6px;
}

.card-metrics {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.metric {
    font-size: 1rem;
    display: flex;
    align-items: center;
    color: #757575;
}

.metric.positive {
    color: #4caf50;
}

.metric.negative {
    color: #f44336;
}

.metric-icon {
    margin-right: 4px;
    font-size: 14px;
}

@media screen and (max-width: 1700px) {
    .card {
        padding: 10px;
    }

    .card-title {
        font-size: 18px;
    }

    .card-total {
        font-size: 18px;
    }

    .metric {
        font-size: 13.5px;
    }
}
