@import '../../styles/variables';
@import '../../styles/mixins';

.entregaRotadiv {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.divStatusVendedor {
}

.direct-sale-order {
    .integrated {
        color: $ternary-color;
        font-size: 1rem;
    }

    .not-integrated {
        color: $red;
        font-size: 1.2rem;
    }

    .date {
        white-space: nowrap;
    }

    .actions {
        svg {
            font-size: 1.6rem;
            margin-left: 5px;
            padding: 4px;
            cursor: pointer;
            border: 1px solid white;

            &:hover:not(.disabled) {
                filter: hue-rotate(75deg);
            }

            &:first-child {
                color: $primary-color;
            }

            &:nth-child(2) {
                color: $secondary-color;
            }

            &:nth-child(3) {
                color: $ternary-color;
            }
        }
    }

    .direct-list-actions {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        .date-selection {
            font-size: 14px;
            color: $gray-5;
            display: flex;
            align-items: center;
            margin-top: 10px;
        }
    }

    .list {
        margin-top: 20px;

        .table {
            tbody {
                tr {
                    .actions {
                        .action {
                            @include border-radius;
                            border: 1px solid
                                $direct-sale-order-action-border-color;
                            margin: 0 5px 0 0;
                            padding: 3px 5px 0;

                            &.info {
                                color: $direct-sale-order-action-info-color;
                            }

                            &.print {
                                color: $direct-sale-order-action-print-color;
                            }

                            .material-icons {
                                font-size: 1.8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.externalOrder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 1px;
    margin-top: 2px;
    border-radius: 5px;
    background-color: rgba(48, 170, 76, 0.4);
}

.actionsTdDSO {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & :first-child {
        color: #17a2b8 !important;
    }

    & :nth-child(2) {
        color: $orange;
    }

    & :nth-child(3) {
        color: #30aa4c;
    }
}

.callFieldControlButton {
    cursor: pointer;
}

.listDirectSalesOrder {
    margin-top: 10px;
    overflow-x: auto;

    .NewTableDirectSalesOrder {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
                vertical-align: middle;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 400;
                font-size: 12px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;

                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }

                .link-salesman {
                    align-items: center;
                    background-color: #00a65a;
                    border-radius: 4px;
                    color: #ffffff;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    width: 30px;
                }

                .status {
                    display: block;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        background-color: #00a65a;
                    }

                    &.pending {
                        background-color: #f3ae76;
                    }

                    &.inactive {
                        background-color: #dd4b39;
                    }
                }
            }
        }

        .actions {
            > * {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
                // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17a2b8 !important;
                }

                &:nth-child(2) {
                    color: $orange;
                }

                &:nth-child(3) {
                    color: #30aa4c;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}

.divTdAcoesDSO {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    svg {
        font-size: 22px !important;
    }
}

.divTdAcoes {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.print-content {
    display: none;
}

.pixPaymentContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 5px;
}

.divIntegrationDSO {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    svg {
        font-size: 23px;
    }
}

@media screen and (max-width: 900px) {
    .entregaRotadiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 25px;
        // align-items: center;
        // justify-content: flex-end;
        // text-align: center;
    }

    .divStatusVendedor {
        // background-color: pink;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        justify-content: flex-end;
    }

    .listDirectSalesOrder {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //   text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }
    }

    td .orderIdContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .pixPaymentContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 5px;
    }

    .externalOrder {
        padding: 2px 7px;
    }

    .TdAcoes {
    }

    .divTdAcoesDSO {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;

        svg {
            font-size: 25px !important;
        }
    }

    .divIntegrationDSO {
        display: flex;
        gap: 22px;
        justify-content: flex-end;

        svg {
            font-size: 22px;
        }
    }
}

.desktopTr {
    display: default;

    @media screen and (max-width: 900px) {
        display: none !important;
    }
}

.mobileTr {
    display: none !important;

    @media screen and (max-width: 900px) {
        display: block !important;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.06);
        padding: 7px 0;
        margin: 2px;

        td {
            // padding: 0 50px;
        }
    }
}

.storeNameMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    align-items: center;
    // border-bottom: 1px solid linear-gradient(to right, $primary-color, transparent);
    border-bottom: 2px solid;
    border-image: linear-gradient(
            to right,
            $primary-color -30%,
            transparent 95%
        )
        100% 0%;
    padding: 5px 5px;
    gap: 4px;

    // background-image: linear-gradient(to right, $primary-color -100%, transparent 30%);
    .buttonStoreName {
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        width: 32px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: $primary-color;

        &:active {
            // background-color: ;

            opacity: 0.7;
            translate: 1px 1px;
        }
    }
}

.dateSellerStatusMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.infoStack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    p {
        font-weight: 300;
        font-size: 0.8rem;
    }

    span {
        font-weight: 400;
        font-size: 0.85rem;
    }
}
.IntegracaoAcoes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
.divTdAcoesDSOMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;

    // width: 100%;
    flex: 1;
    svg {
        font-size: 22px !important;
    }
}
.divIntegrationDSOMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    svg {
        font-size: 23px;
    }
}
.buttonAction {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 6px; // border: 1px solid ;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

    svg {
        font-size: 1rem;
        // color: rgba(255,0,0,0.7);
    }

    &:active {
        // background-color: ;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        opacity: 0.7;
        translate: 0px 1px;
    }
}
.firstTdMobile {
    padding: 0 !important;
}
