@import '../../styles/variables';

.custom-button {
    border: 1px solid $orange-3;
    white-space: nowrap;
    color: #202020 !important;
    cursor: pointer;
    font-family: $font-familyV2;
    font-weight: 400;
    padding: 15px 12px;
    border-radius: 8px;

    &.active {
        border-color: none;
        background-color: $orange-3;
        color: #303030 !important;
        font-weight: 400;
        font-family: $font-familyV2;

        border-radius: 8px;
        padding: 15px 12px;

        svg {
            color: $orange !important;
        }
    }

    &:hover {
        border-color: none;
        background-color: $orange-3;
        border-radius: 8px;
        color: $orange !important;
    }

    svg {
        color: $orange;
        font-size: 20px;
        margin-right: 8px !important;
    }

    display: flex;
    align-items: center;
}

@media screen and (max-width: 520px) {
    .custom-button {
        font-size: 13px;
        padding: 12px 9px;
    }
}
