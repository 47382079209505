@import '../../styles/variables';
@import '../../styles/mixins';

.modal-header {
    background-color: $primary-color;
    box-sizing: border-box;
    color: #fff;
    font-size: 15px;
    [class='modal-title'] {
        display: flex;
        align-items: center;
        justify-content: space-between;
        [class='material-icons'] {
            margin-right: 6px;
        }
    }
    button[class='close'] {
        color: white;
    }
}

.modal-body {
    max-height: calc(100vh - 20rem);
    overflow-y: scroll;
}

.subtitle {
    font-size: 11px;
    font-weight: 400;
}

.modal-footer {
    :global {
        button {
            margin-left: 5px;
        }
    }
}
