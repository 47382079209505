@import 'styles/_variables';
@import 'styles/_mixins';

.loader {
    margin: auto;
}

.container {
    // box-sizing: border-box;
}

.header {
    font-weight: $bold;
    padding: 10px 5px;
}

@media print {
    .break {
        page-break-after: always;
    }
}

.prod-obs {
    color: $primary-color;
    font-size: 10px;
}
.prod-name {
    display: flex;
    flex-direction: column;
    strong {
        padding-top: 10px;
        color: $ternary-color;
    }
}

.logo {
    display: flex;
    align-items: center;
    justify-content: left;
    img {
        width: 150px;
        margin-right: 20px;
    }
}

.provider,
.client,
.obs,
.summary,
.products {
    th.title {
        color: $primary-color;
    }
    td {
        padding: 5px 0 !important;
        padding-left: 10px !important;
    }
}

.resume {
    padding: 0;
    border: none;
    margin: 0;
    td {
        border: none;
        padding: 0 !important;
    }
}
