@import '../../styles/variables';

.inputItemIdFilter {
    width: 135px;
}

.topButtonsProducts {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.sectionTOp {
    @media only screen and (max-width: 740px) {
        width: 100%;

        > div {
            width: 100%;
        }
    }
}

.navtabProdCustom {
    @media only screen and (max-width: 740px) {
        > a {
            width: 100% !important;
        }
    }
}

.itemOption {
    text-transform: capitalize;
    background-color: #ff711010;
    // color: red;
    font-weight: 500;
    margin-left: 15px;
    border-bottom: 1px solid black;
}

.subItemOption {
    font-weight: 300;
    text-transform: capitalize;
    margin-left: 15px;

    &::before {
        content: '';
        background-color: red;
    }
}

.productsTable {
    width: 100%;
    min-width: 1000px;
    overflow-x: auto;

    thead {
        background-color: #fffefc;
        border: 1px solid #e5e5e5;

        th {
            padding: 12px 5px;
        }
    }

    tbody {
        td {
            padding: 12px 5px;
        }

        tr:nth-child(odd) {
            background-color: #f7f7f7;
        }
    }
}

.switchButtonLabel {
    font-family: $font-familyV2;
    font-size: 10px;
    padding: 0px 40px;
}

.descColumProd {
    display: flex;
    flex-direction: column;

    p {
    }

    span {
        font-size: 12px;
        font-weight: 300;
        color: #c7c7c7;
    }
}

.testetrackstle {
}

.ProductActionIcon {
}

.NewlistProducts {
    margin-top: 20px;
    overflow-x: auto;

    .newTableProducts {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }
            }
        }
    }
}

.TdCodigoClubProducts {
    width: 130px;
}

.listActionsProducts {
    display: flex;
    justify-content: center;
    align-items: center;

    :global(button) {
        width: fit-content;
        padding: 0 8px;
    }
}

.inputItemProductPage {
    width: 180px;
}

.containerSelectProductPage {
    width: 200px;
}

// .desktopTr{
//     display: default;
// }
// .mobileTr{
//     display: none;
// }

@media only screen and (max-width: 740px) {
    // .desktopTr{
    //     display: none;
    // }
    // .mobileTr{
    //     display: default;

    // }

    .inputItemIdFilter {
        width: 100%;
    }

    .inputItemProductPage {
        width: 100%;
    }

    .containerSelectProductPage {
        width: 100%;
    }
}

.nav-tabs {
    display: flex;
    gap: 15px;
}

.nav-item {
    border: none;
    border-color: none;
    color: #202020 !important;
    cursor: pointer;
    font-family: $font-familyV2;
    font-weight: 400;
    padding: 15px 12px;

    &.active {
        border-color: none;
        background-color: $orange-3;
        color: #303030 !important;
        font-weight: 400;
        font-family: $font-familyV2;

        border-radius: 8px;
        padding: 15px 12px;

        svg {
            color: $orange !important;
        }
    }

    &:hover {
        border-color: none;
        background-color: $orange-3;
        border-radius: 8px;
        color: $orange !important;
    }

    svg {
        color: $orange;
        margin-right: 11px !important;
    }

    display: flex;
    align-items: center;
}

.title {
    margin-bottom: 20px;
}

.form {
    .feedbacks {
        box-sizing: border-box;
    }

    .form-group {
        box-sizing: border-box;

        .dropdown {
            width: 100%;
        }
    }
}

.ContainerButtonsMobileSetOrderBy {
    display: none;
}

.containerPaginationProducts {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    div {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.ThClubProductsOrderby {
    cursor: pointer;

    svg {
        margin-left: 5px;
    }
}

@media only screen and (max-width: 740px) {
    .nav-tabs {
        flex-direction: column;
    }
}

@media screen and (max-width: 900px) {
    .NewlistProducts {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }

        .listActionsProducts {
            justify-content: flex-end;
            gap: 22px;
        }

        .TdCodigoClubProducts {
            width: unset;
        }
    }

    .ContainerButtonsMobileSetOrderBy {
        display: flex;
        font-family: 'Rubik';
        justify-content: space-evenly;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        color: #505050;
        margin-bottom: 10px;
    }

    .ButtonMobileSetOrderBy {
        border: 1px solid #e5e5e5;
        padding: 12px 10px;
        border-radius: 8px;

        svg {
            margin-left: 5px;
            color: $orange;
        }
    }
}

.sectionMobileTrProd {
    width: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.imageProductMobileTr {
    // width: 32px;
    // height: 100%;
    width: 110px;
    object-fit: contain;
    // height: 32px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        // transform: scale(1.5);
        // transition: all 1.5s ease;
    }
}

.divAcoesProdMobile {
}

.spanSwitchProdInvactive {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    position: absolute;
    bottom: 7px;
    right: 5px;

    span {
        font-size: 14px;
        font-weight: 400;
    }
}

.spanSwitchProdActive {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    position: absolute;
    bottom: 7px;
    left: 8px;

    span {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
    }
}
