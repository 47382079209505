.filtersContainer2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;
    align-items: flex-start;
}

.firstColumnFilters {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    // justify-content: space-around;
}
.secondColumnFilters {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    // justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.filterItem {
    flex: 1 1 200px;
    max-width: 250px;
}

.buttonsContent {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    margin-top: 1.5rem;
}

@media only screen and (max-width: 740px) {
    .filtersContainer2 {
        display: flex;
        // flex-direction: column;
        flex-wrap: nowrap;

        gap: 15px;
        margin-top: 15px;
        align-items: unset;
    }

    .filterItem {
        flex: none;
        width: 100%;
        max-width: unset;
    }
}
