.checkbox {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
    align-items: center;

    &input {
        display: none;

        &:checked + .box {
            background-color: $orange;
            border-color: $orange;
        }

        &:checked + .box .check-icon {
            transform: scale(1);
            opacity: 1;
        }
    }

    &.box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        top: 2px;
        border: solid 1px $gray-3;
        background-color: #fff;
        border-radius: 4px;

        &.check-icon {
            font-size: 12px;
            color: #fff;
            opacity: 0;
        }
    }
}
