.loader {
    align-items: center;
    //   background-color: rgba(0,0,0, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 100;
}
