@import 'styles/_variables';


.userImageStyleMyConfigs {

    //width: 400px;
    height: 120px;
    cursor: pointer;
   // &:hover {
   //     transform: scale(1.5);
   //     transition: all 1.5s ease;
   // }
}
.containerInputItensfornecedor {
   // min-width: 400px;
    //width: 100%;
   margin: 15px;
    //margin-right: 30px;
}

.provider-edit {
    padding: 25px 0;

    select[readonly] {
        //background: #eee; /*Simular campo inativo - Sugestão @GabrielRodrigues*/
        pointer-events: none;
        touch-action: none;
        background-color: rgba(239, 239, 239, 0.3);
        border-color: #e5e5e5;
        border-width: 1px;
        border-style: solid;
        border-radius: 8px;
        opacity: 1;

    }

    .nav-item {
        color: $gray-2;

        &.active {
            color: $secondary-color;
            font-weight: 500;

            svg {
                color: $secondary-color;
            }
        }

        svg {
            margin-right: 4px;
        }

        display: flex;
    }

    .title {
        margin-bottom: 20px;
    }

    .form {
        .feedbacks {
            box-sizing: border-box;
        }

        .form-group {
            box-sizing: border-box;

            .dropdown {
                width: 100%;
            }
        }
    }
}

.twoInputsSection{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 20px;

}

.mainProviderInfoContainer{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 1px;
        width: 100%;

}

@media only screen and (max-width: 740px) {
    .twoInputsSection {
       flex-direction: column;
    }

    .mainProviderInfoContainer{
        flex-direction: column;
    }

}
