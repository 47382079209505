@import 'styles/_variables';
@import 'styles/_mixins';

.modal-dialog {
    min-width: 70vw;
    .modal-body {
        height: fit-content;
        max-height: 70vh;
    }
}

.table {
    border: none;
}
.tableContainerCLientsDetail{
   //style={{'display':'flex', 'flexDirection':'row',  }}
   display: flex;
   flex-direction: row;

}
.clientDetailcolumn{

    height: 25px;
    padding: 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100% !important;
  
}
.clientDetailcolumn:nth-child(odd){

    padding: 17px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100% !important;
    background-color: #F7F7F7;
}
.clientDetailSecondColumn{
    height: 25px;
    padding: 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100% !important;
}
.clientDetailSecondColumn:nth-child(odd){
    padding: 17px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100% !important;
    background-color: #F7F7F7;
}


@media only screen and (max-width: 940px) {
    .tableContainerCLientsDetail{
    
        display: flex;
        flex-direction: column;
     
     }
     .clientDetailSecondColumn:nth-child(odd){
        padding: 17px 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100% !important;
        background-color: unset;
    }
     .clientDetailSecondColumn:nth-child(even){
        padding: 17px 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100% !important;
        background-color: #F7F7F7;
    }
    
}