@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.col {
    // padding-left: 7px;
    // padding-right: 0;
}

.filtersDirectSalesOrderDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;


}


.datePickerDirectSalesOrder {
    width: 135px;
    position: relative;
    @media only screen and (max-width: 740px) {
        width: 100% !important;
    }
}


.directSalesOrderDivInputsSelects {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 15px;
}

.directSalesOrderItemInputsSelects {
    width: 250px;

}

@media only screen and (max-width: 740px) {
    .filtersDirectSalesOrderDiv {
        display: flex;
        flex-direction: column;
        align-items: unset;
        width: 100%;
        gap: 15px;
        margin-bottom: 10px;


    }

    .datePickerDirectSalesOrder {
        width: 100%;
    }
    .directSalesOrderDivInputsSelects {
        flex-direction: column;
        align-items: unset;
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
    }
    .directSalesOrderItemInputsSelects {
        width: 100%;

    }
}
