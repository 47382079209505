@import '../../styles/variables';
@import '../../styles/mixins';

.iconButtonFilter {
    color: $orange;
    margin-right: 8px;
    font-size: 18px;
    margin-bottom: 1px;
}

.filtersContent {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;

    .viewFilters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .p {
            font-size: 20px;
            font-weight: 600;
            color: #605f5f;
        }
    }
}

.titleSection {
    font-size: 20px;
    font-weight: 600;
    color: #605f5f;
    margin-bottom: 1rem;
}

.cotacoesContent {
    margin-bottom: 2rem;
}

.cardsContent {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    gap: 2rem; /* Espaçamento entre os cards */
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.cards {
    // max-width: 300px;
}

.productsContent {
    margin-bottom: 4rem;
}

.buttonsContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .toggleButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }

    .buttonExport {
        min-width: 114px;
    }
}

.error-message {
    width: 100%;
}

.sectionDataDate {
    display: flex;
    justify-content: center;
    // margin-bottom: 1rem;

    .ViewDataDate {
        font-size: 20px;
        border-color: none;
        background-color: $orange-3;
        color: $orange !important;
        font-family: $font-familyV2;
        font-weight: 400;
        padding: 15px 12px;
        border-radius: 8px;

        transition: margin 100ms ease-in-out;
        animation: 1.2s ease 0s 1 slideInFromRight;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translatex(4%);
        opacity: 0;
    }

    100% {
        transform: translatex(1);
        opacity: 4;
    }
}

// TABELA
.NewListItemTacticalPanel {
    margin-top: 10px;
    overflow-x: hidden;

    table {
        width: 100%;
        transition: margin 100ms ease-in-out;
        animation: 0.8s ease 0s 1 slideInFromRight;

        thead,
        tfoot {
            tr {
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th,
            td {
                padding: 12px 10px;
                text-align: center;

                &:first-child {
                    text-align: left;
                    padding-left: 10px;
                }

                &:last-child {
                    text-align: center;
                    padding-right: 10px;
                }
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;
                justify-content: center;
                vertical-align: middle;
                text-align: center;

                &:last-child {
                    border-right: none;
                    text-align: center;
                }

                &:first-child {
                    text-align: left;
                }
            }

            tr:nth-child(even) {
                background-color: #f7f7f7;
            }
        }

        .rankingContent {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
        }
        .tdParticipationPanel {
            width: 100%;
            display: flex;
        }
    }
}

@media screen and (max-width: 1700px) {
    .cardsContent {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
}

@media screen and (max-width: 1424px) {
    .cardsContent {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
}

@media screen and (max-width: 900px) {
    // .cardsContent {
    //     justify-content: flex;
    // }
    .NewListItemTacticalPanel {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }

        .list-actionsGrupoP {
            justify-content: flex-end;
            gap: 22px;

            svg {
                font-size: 25px;
            }
        }

        .tdParticipationPanelMobile {
            height: 30px;
        }
        .tdRanking {
            height: 40px;
        }

        .rankingContent {
            justify-content: flex-end;
            align-items: center;
            float: right;
        }
        .tdParticipationPanel {
            max-width: 90px;
            justify-content: flex-end;
            align-items: center;
            float: right;
        }
    }
}

@media screen and (max-width: 800px) {
    .cardsContent {
        gap: 1rem;
        justify-content: center;
    }

    .buttonsContent {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 585px) {
    .toggleButtons {
        flex-wrap: wrap;
    }

    .sectionDataDate {
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 400px) {
    .cardsContent {
        gap: 1rem;
    }
}
