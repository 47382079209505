@import 'styles/_variables';

@keyframes customFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes customFadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes customScaleIn {
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes customScaleOut {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0.9);
        opacity: 0;
    }
}

.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    animation: customFadeIn 0.5s forwards;
    z-index: 9999;
}

.custom-modal-overlay-hidden {
    animation: customFadeOut 0.5s forwards;
    opacity: 0;
}

.custom-modal-box {
    background: white;
    border-radius: 5px;
    width: 100%;
    max-width: 500px;
    margin: 0 8px;
    transform: scale(1);
    opacity: 1;
    animation: customScaleIn 0.3s forwards;
}

.custom-modal-hidden {
    animation: customScaleOut 0.3s forwards;
    transform: scale(0.9);
    opacity: 0;
}

.custom-modal-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: $primary-color;
    border-top: 5px;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.3rem;

    svg {
        color: white;
    }
    h2 {
        color: white;
        margin: 0;
        font-size: 1rem;
        font-weight: 500;
    }
}

.custom-modal-close-button {
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
    color: #aaaaaa;

    display: none;
}

.custom-modal-close-button:hover svg {
    transform: scale(1.1);
    color: #ccc;
}

.custom-modal-content {
    padding: 20px;
}
