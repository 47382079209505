@import '../../styles/variables';
@import '../../styles/mixins';

.date-edit {
    //@include c-input-base;
    cursor: pointer;
    //height: $input-height;
    padding: 12px 15px;
    color: #898989;
    width: 100%;
    text-align: left;
    margin-top: 5px;
    font-size: 1rem;
    font-weight: 400;
    background-color: #fff;
    font-family: $font-familyV2;
    border-radius: 8px !important;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    //box-sizing: border-box;
    &:disabled {
        background-color: #fff;
    }
}

.dat-picker {
    z-index: auto;
    * {
        // z-index: 999999999;
    }
}
.DatePicker {
    width: 100%;
    .DatePicker__calendarContainer {
        left: 10rem;
        z-index: inherit;
    }
    .inline {
        margin: 0;
        border: none;
        height: 100%;
        padding: 0;
        background-color: #fff;
        width: 86px;
        //color: $primary-color;
        color: $primary-color;
        text-decoration: underline;
        cursor: pointer;
    }
}

.c-icon-dp {
    color: $primary-color;
    font-size: 19px;
    position: relative;
    left: 3px;
    top: 1px;
}
.callendarIconStyle {
    color: $orange;
    font-size: 18px;
    position: absolute;
    right: 11px;
    top: 34%;
}
