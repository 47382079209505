@import 'styles/_variables';
@import 'styles/_mixins';

.arrows-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .dualIcon {
        display: flex;
        flex-direction: column;

        .iconUp {
            margin-bottom: -12px;
        }
        .iconDown {
            margin-bottom: 0;
        }
    }

    .arrow-icon {
        color: $orange;
        font-size: 1.3rem;
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.3s ease, transform 0.3s ease;

        &.hidden {
            opacity: 0;
            pointer-events: none;
            transform: translateY(
                -10px
            );
        }
    }



    // Foco e acessibilidade
    .arrow-icon:focus {
        outline: none;
    }
}
