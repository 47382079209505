@import '../../../../styles/variables';

.InputsDivEditModal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}
.modalEditTittle {
    font-size: 24px;
    font-weight: 400;
    font-family: $font-familyV2;
    color: #898989;
}

@media only screen and (max-width: 950px) {
    .InputsDivEditModal {
        display: flex;

        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}
