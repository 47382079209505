@import 'styles/_variables';

.provider-edit {
    padding: 25px 0;

    .currency {
        height: 20px;
        margin: auto;
        width: 9rem;

        font-size: 12px;
        font-weight: 400;
        &.saved {
            border-color: #239444;
            color: #239444;
        }
    }

    .nav-item {
        color: $gray-2;
        &.active {
            color: $secondary-color;
            font-weight: 500;
            svg {
                color: $secondary-color;
            }
        }
        svg {
            margin-right: 4px;
        }
        display: flex;
    }
    .title {
        margin-bottom: 20px;
    }
    .form {
        .feedbacks {
            box-sizing: border-box;
        }
        .form-group {
            box-sizing: border-box;
            .dropdown {
                width: 100%;
            }
        }
    }
}
.conpaing-info {
    margin-bottom: 25px;
    font-family: $font-familyV2;
    .title-campaing {
        font-size: 20px;
        color: #5281ff;
        font-weight: 400;
        padding: 10px 0;
    }

    .info-campaing {
        font-size: 16px;
        color: #707070;
        font-weight: 300;
        padding: 5px 0;

        strong {
            margin-right: 3px;
            color: #707070;
            font-weight: 400;
        }
    }
}

.preco {
    color: #0050b3;
    font-size: 12px;
    font-weight: 700 !important;
}

.unit {
    color: $orange;
    font-size: 12px;
    font-weight: 300 !important;
    &.saved {
        color: $orange !important;
    }
}

.produto-cadastrado {
    color: #30aa4c !important ;
    font-size: 12px;
    text-decoration: underline;
    font-weight: 300;
}
.btn-right {
    text-align: right;
    position: fixed;
    right: 35px;
    top: 97px;
    margin-top: -2.5em;
}

.NewlistEditCampaign {
    margin-top: 20px;
    overflow-x: auto;

    .newTableEditCampaign {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }
            td:last-child {
                border-right: none;
            }
            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .NewlistEditCampaign {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            // text-transform: uppercase;
            vertical-align: middle;
        }

        table td:last-child {
            border-bottom: 0;
            svg {
                font-size: 22px;
            }
        }

        .products-link {
            //border: 1px solid $primary-color;

            border-radius: 3px;
            display: flex;

            margin: unset;
            padding: 5px 10px;
            color: #30aa4c;

            svg {
                margin-right: 5px;
            }

            p {
                margin-top: 1px;
            }

            &:hover {
                color: white;
                background-color: rgba(48, 170, 76, 0.3);
            }
        }
        .preco {
            div {
                display: flex;
                justify-content: flex-end;
            }
        }
        .tdTogleActive {
            div {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
