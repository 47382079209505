@import 'styles/_variables';

.titleSelectLevels {
    font-family: $font-familyV2;
    font-size: 18px;
    font-weight: 400;
    color: #898989;
}

.loaderSpinner {
    display: flex;
    justify-content: conter;
    align-items: center;
    width: 100%;
    height: 68vh;
}
