@import 'styles/_variables';
@import 'styles/_mixins';

.profile {
    color: white;
    padding-top: 5px;
    align-items: flex-end;
    display: flex;
    margin: 5px 0px;
    label {
        width: 100%;
        border-radius: 5px;
        cursor: pointer;
        padding: 0;
        align-items: center;
        display: flex;
        font-size: 15px;
        justify-content: center;
        &.master {
            color: #0050b3;
        }
        &.supervisor {
            color: #00a65a;
        }
        &.salesman {
            color: #f3ae76;
        }
    }
}

.btn-filtro-campanha{
    margin-top: 20px;
    height: 35px !important;
}