.textarea-field {
  @include border-radius;
  @include placeholder {
    color: $placeholder-input-color;
  }
  border: 1px solid $input-border-color;
  box-sizing: border-box;
  color: $input-color;
  font-family: $font-family;
  font-size: 1.4rem;
}