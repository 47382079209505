@import 'styles/_variables';
@import 'styles/_mixins';

.resourceNameTitle {
    font-family: $font-familyV2;
    font-weight: 400;
    color: #505050;
    font-size: 24px;
}
.resourceNameInfoIcon {
    font-size: 16px;
    color: $orange;
    margin-right: 5px;
}
.SelectAllLabeToggle {
    font-family: $font-familyV2;
    font-weight: 400;
    color: #505050;
    font-size: 18px;
    margin-right: 15px;
}

.toggle-wrapper {
    display: flex;
    align-items: center;
    svg {
        margin-right: 10px;
    }
}
.toggle {
    margin: 20px 0 0 0;
    small {
        margin-right: 10px;
        color: $secondary-color;
    }
}
.subjects-field {
    padding: 5px 2px;
    display: flex;
    align-items: center;
    input {
        height: 20px;
    }
    svg {
        cursor: pointer;
        color: $ternary-color;
        margin-right: 5px;
    }
}

.modal {
    min-width: 45vw !important;
}

.perm-desc {
    margin-right: 10px;
    color: $secondary-color;
}

.perm {
    text-align: left;
    margin: 0;
    padding-top: 10px;
    font-size: 1.2em;
    color: $secondary-color;
}

.loader {
    margin: auto;
}

.controls {
    .lock {
        color: $primary-color;
    }
    .save {
        color: green;
    }
    svg {
        color: $primary-color;
        border: 1px solid;
        border-radius: 50%;
        padding: 3px;
        cursor: pointer;
        font-size: 1.5rem;
    }
}

.permissions {
    .permission {
        margin-top: 10px;
        padding-right: 1rem;
        text-align: center;
    }
}

.header {
    font-weight: $bold;
    padding: 10px 5px;
}

@media print {
    .break {
        page-break-after: always;
    }
}

.prod-obs {
    color: $primary-color;
    font-size: 10px;
}
.prod-name {
    display: flex;
    flex-direction: column;
    strong {
        padding-top: 10px;
        color: $ternary-color;
    }
}

.logo {
    display: flex;
    align-items: center;
    justify-content: left;
    img {
        width: 150px;
        margin-right: 20px;
    }
}

.provider,
.client,
.obs,
.summary,
.products {
    th.title {
        color: $primary-color;
    }
    td {
        padding: 5px 0 !important;
        padding-left: 10px !important;
    }
}

.resume {
    padding: 0;
    border: none;
    margin: 0;
    td {
        border: none;
        padding: 0 !important;
    }
}
