@import 'styles/_variables';

.newListRepresentativesCLientsModal {
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden !important;
    height: 70vh;

    .newTableRepresentatives {
        width: 100%;

        .tdPerfilDiv {
            display: flex;
            align-items: center;
        }
        .seller {
            color: #807368;
            margin-bottom: 3px;
            margin-right: 5px;
        }

        .tdSellerCustomers {
            display: flex;

            flex-direction: row;
            gap: 0.5rem;
            align-items: center;

            svg {
                font-size: 19px;
                color: rgba(65, 103, 242, 0.846);
                cursor: pointer;
            }
        }

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }
        }
    }
}

@media screen and (max-width: 1005px) {
    .tdCell {
        min-height: 28px;

        div {
            display: flex;
            align-items: center;

            justify-content: flex-end;
        }
    }

    .newListRepresentativesCLientsModal {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }
    }

    .tdSellerCustomers {
        justify-content: flex-end;
    }
    .acoesButton {
        display: flex;
        justify-content: flex-end;
    }

    .tdpart {
        min-height: 33px;
    }

    .tdContentPart {
        max-width: 60px;
        min-height: 30px;
        flex-direction: flex-end;
        float: right;

        div {
            margin-bottom: 8px;
        }
    }
}
