@import 'styles/_variables';
@import 'styles/_mixins';

$first-col-width: 120px;


.wrapperMobile {
    width: 100%;

    font-family: $font-familyV2;
    font-weight: 300;


    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.06);
    // padding: 7px 0;


    .showBrandsAndNAmeDiv {
        display: flex;
        padding-left: 30px;
        justify-content: space-between;
        align-items: center;

    }

    .prodIdTableMobile {
        font-family: 'Rubik';
        font-weight: 500;
        font-size: 14px;
        color: #605F5F;

    }


    .TdEmbePreco {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 4px 40px 4px 25px;
        font-family: 'Rubik';
        font-weight: 400;
        font-size: 14px;
        color: #605F5F;
    }

    .divWrapInputsMobile {
        display: flex;
        justify-content: space-between;
        padding: 1px 2px;
        gap: 6px;


    }

    .divLastOfferMobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 5px;
        gap: 10px;

        p {
            font-family: 'Rubik';
            font-weight: 400;
            font-size: 14px;
            color: #605F5F;
        }

        span {
            cursor: default;
            line-height: normal;
            padding: 1px 4px;
            border-radius: 3px;
            font-family: $font-familyV2;
            font-size: 15px !important;
            font-weight: 500;

            &.higher {
                color: #30AA4C;
                //background-color: #30AA4C !important;
            }

            &.lower {
                color: #CD2A2A !important;
                // background-color: #CD2A2A !important;
            }
        }
    }

    .lower {
        color: #CD2A2A !important;
        // background-color: #CD2A2A !important;
    }

    .showBrandsAndNAmeDiv::before {
        margin-top: 20px;
    }

    .lastPriceMobile {
        display: flex;
        justify-content: center;

        span {
            cursor: default;
            line-height: normal;
            padding: 1px 4px;
            border-radius: 3px;
            font-family: $font-familyV2;
            font-size: 15px !important;
            font-weight: 500;

            &.higher {
                color: #30AA4C;
                //background-color: #30AA4C !important;
            }

            &.lower {
                color: #CD2A2A !important;
                // background-color: #CD2A2A !important;
            }
        }

        svg {
            color: gray;
            font-size: 11px !important;
        }
    }



    .showbrands {
        background-color: #0677C9;
        color: #fff;

        padding: 6px 15px;
        text-align: center;
        border-radius: 7px;
        font-weight: 400;
        font-size: 16px;
        // margin-right: 20px;
        cursor: pointer;
    }

    .showbrandsDisabled {
        background-color: #c7c7c7;
        color: #fff;
        padding: 4px 5px;
        text-align: center;
        border-radius: 7px;
        font-weight: 400;
        font-size: 13px;
        margin-right: 20px;
        cursor: not-allowed;
    }

    .codigoOffer {
        padding: 0px 4px;
        width: 115px;
        display: flex;
        align-items: center;
        justify-content: center;
        //word-wrap: break-word;
        word-break: break-word;
        text-align: center;

        p {

            font-weight: 300 !important;
            font-size: 12px;
        }
    }

    .prodNameOffer {
        //font-family: $font-familyV2;
        font-size: 13px;
        font-weight: 500 !important;
        width: 100%;
        text-justify: auto;

    }

    .infOfferItem {
        display: flex;
        align-items: center;
        font-family: $font-familyV2;
        font-size: 13px !important;
        font-weight: 300 !important;
        margin-right: 80px;
        display: flex;
        justify-content: flex-start;

        width: 260px;

    }

    .circle {
        width: 130px !important;
        display: flex;
        align-items: center;
    }

    .circle svg {
        padding-left: 10px;
        padding-right: 10px;
        color: $ternary-color;
        visibility: hidden;
        width: 15px;
    }

    .currencyOffer {

        text-align: center;
        height: 20px;
        font-weight: 300;
        font-size: 18px;
        width: 130px;
    }

    &:focus-within {
        .circle svg {
            visibility: visible;
        }
    }

    .itemUnity {
        font-size: 14px;
        font-weight: 300;
        color: black !important;
        height: 35px !important;
        min-width: 50px;

        // text-align: center;
        &.saved {
            background-color: #9cf5d1;
        }
    }

    .offerObs {
        height: 35px !important;
    }
}

a .header {

    :global {
        .col {
            border-bottom: 1px solid #dee2e6;
            font-weight: $bold !important;

            &:nth-child(2) {
                min-width: 42%;
            }

            &:nth-child(3) {
                min-width: 0%;
            }

            &:nth-child(4),
            &:nth-child(5) {
                max-width: 10%;
            }

            &:nth-child(6) {
                min-width: 10%;
            }
        }
    }
}

.main {
    height: 45px !important;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 6px solid #fff;
    padding: 5px 0px;

    :global {
        .col {
            color: $gray-2;
            font-weight: 600;
            height: 40px;

            &:nth-child(2) {
                //  min-width: 40%;
                white-space: nowrap;
                font-size: 13px;
            }

            &:nth-child(3) {
                justify-content: flex-start;
                //   min-width: 17.3%;
            }

            &:nth-child(4) {
                // white-space: nowrap;
            }

            &:nth-child(5) {
                //   max-width: 12%;
            }
        }
    }
}

.price-actions {
    // position: sticky;
    //right: -1.4rem;


    display: flex;
    align-items: center;
    flex-direction: row;
    //height: 100%;
    margin-left: 8px;

    justify-content: center;



}

.copy {
    margin-right: 2px !important;
    visibility: visible !important;
    color: #53b2e0 !important;
    //right: -1.4rem;
    z-index: 1;
    font-size: 20px !important;

    position: relative;
    cursor: pointer;
}

.previous {

    margin-right: 0 !important;
    color: $ternary-color !important;
    font-size: 20px !important;
    //right: -1.4rem;
    position: relative;
    cursor: pointer;
    // z-index: 1;
}

.saved {
    background-color: #9cf5d1;
    color: black;
    border: 1px solid white;
}
.brand {
    //background-color: red;


    :global {
        .col {
            color: $gray-2;
            font-weight: 600;


            &:nth-child(2) {
                // min-width: 15%;

            }

            &:nth-child(3) {
                justify-content: flex-end;
                //      min-width: 10%;
            }

            &:nth-child(4),
            &:nth-child(5) {
                //    max-width: 15%

            }

            &:nth-child(6) {
                //    max-width: 15%;
            }
        }
    }
}

.hearth {
    font-family: $font-familyV2;
    font-weight: 300;
    font-size: 12px;

    :global {
        .on {
            svg {
                color: $gray-2 !important;
            }
        }
    }
}

.divDerivadoMarcaMobile {

    display: flex;
    justify-content: space-between;
    align-items: center;

}

@media screen and (max-width: 900px) {


    .wrapperMobile {



        tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .9em;
            text-align: right !important;
        }

        td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //   text-transform: uppercase;
        }

        td:last-child {
            border-bottom: 0;
        }
    }



}






// .offerObs {
//     height: 35px !important;
// }
.currencyOffer{
    height: 35px !important;

}
.crosshairContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: rgba(255, 113, 16, 0.3);
    // border: 1px solid rgba(255, 113, 16, 0.4);
    // // padding: 2px;
    // height: 26px;
    // border-radius: 6px;
    // margin-right: 3px;
    svg{
        margin-right: 4px !important;
        margin-left: 4px !important;
    }
    // height: 25px;
    // width: 30px;
    // line-height: 25px !important;
}

// .wrapperMobile {
//     width: 100%;

//     font-family: $font-familyV2;
//     font-weight: 300;
//     margin-bottom: 15px;

//     .showBrandsAndNAmeDiv {
//         display: flex;
//         padding-left: 30px;
//         justify-content: space-between;
//         align-items: center;

//     }

//     .prodIdTableMobile {
//         font-family: 'Rubik';
//         font-weight: 500;
//         font-size: 14px;
//         color: #605F5F;

//     }


//     .TdEmbePreco {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         padding: 4px 40px 4px 25px;
//         font-family: 'Rubik';
//         font-weight: 400;
//         font-size: 14px;
//         color: #605F5F;
//     }

//     .divWrapInputsMobile {
//         display: flex;
//         justify-content: space-between;
//         padding: 1px 2px;
//         gap: 6px;


//     }

//     .divLastOfferMobile {
//         display: flex;
//         justify-content: space-evenly;
//         align-items: center;
//         padding: 1px 5px;
//         gap: 10px;

//         p {
//             font-family: 'Rubik';
//             font-weight: 400;
//             font-size: 14px;
//             color: #605F5F;
//         }

//         span {
//             cursor: default;
//             line-height: normal;
//             padding: 1px 4px;
//             border-radius: 3px;
//             font-family: $font-familyV2;
//             font-size: 15px !important;
//             font-weight: 500;

//             &.higher {
//                 color: #30AA4C;
//                 //background-color: #30AA4C !important;
//             }

//             &.lower {
//                 color: #CD2A2A !important;
//                 // background-color: #CD2A2A !important;
//             }
//         }
//     }
//     .lower {
//         color: #CD2A2A !important;
//         // background-color: #CD2A2A !important;
//     }

//     .showBrandsAndNAmeDiv::before {
//         margin-top: 20px;
//     }

//     .lastPriceMobile {
//         display: flex;
//         justify-content: center;

//         span {
//             cursor: default;
//             line-height: normal;
//             padding: 1px 4px;
//             border-radius: 3px;
//             font-family: $font-familyV2;
//             font-size: 15px !important;
//             font-weight: 500;

//             &.higher {
//                 color: #30AA4C;
//                 //background-color: #30AA4C !important;
//             }

//             &.lower {
//                 color: #CD2A2A !important;
//                 // background-color: #CD2A2A !important;
//             }
//         }

//         svg {
//             color: gray;
//             font-size: 11px !important;
//         }
//     }



//     .showbrands {
//         background-color: #0677C9;
//         color: #fff;

//         padding: 6px 15px;
//         text-align: center;
//         border-radius: 7px;
//         font-weight: 400;
//         font-size: 16px;
//         // margin-right: 20px;
//         cursor: pointer;
//     }

//     .showbrandsDisabled {
//         background-color: #c7c7c7;
//         color: #fff;
//         padding: 4px 5px;
//         text-align: center;
//         border-radius: 7px;
//         font-weight: 400;
//         font-size: 13px;
//         margin-right: 20px;
//         cursor: not-allowed;
//     }

//     .codigoOffer {
//         padding: 0px 4px;
//         width: 115px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         //word-wrap: break-word;
//         word-break: break-word;
//         text-align: center;

//         p {

//             font-weight: 300 !important;
//             font-size: 12px;
//         }
//     }

//     .prodNameOffer {
//         //font-family: $font-familyV2;
//         font-size: 13px;
//         font-weight: 500 !important;
//         width: 100%;
//         text-justify: auto;

//     }

//     .infOfferItem {
//         display: flex;
//         align-items: center;
//         font-family: $font-familyV2;
//         font-size: 13px !important;
//         font-weight: 300 !important;
//         margin-right: 80px;
//         display: flex;
//         justify-content: flex-start;

//         width: 260px;

//     }

//     .circle {
//         width: 130px !important;
//         display: flex;
//         align-items: center;
//     }

//     .circle svg {
//         padding-left: 10px;
//         padding-right: 10px;
//         color: $ternary-color;
//         visibility: hidden;
//         width: 15px;
//     }

//     .currencyOffer {

//         text-align: center;
//         height: 20px;
//         font-weight: 300;
//         font-size: 16px;
//     }

//     &:focus-within {
//         .circle svg {
//             visibility: visible;
//         }
//     }

//     .itemUnity {
//         font-size: 14px;
//         font-weight: 300;
//         color: black !important;
//         height: 35px !important;
//         min-width: 50px;
//         // text-align: center;
//         &.saved {
//             background-color: #9cf5d1;
//         }
//     }

//     .offerObs {
//         height: 35px !important;
//     }
// }

// a .header {

//     :global {
//         .col {
//             border-bottom: 1px solid #dee2e6;
//             font-weight: $bold !important;

//             &:nth-child(2) {
//                 min-width: 42%;
//             }

//             &:nth-child(3) {
//                 min-width: 0%;
//             }

//             &:nth-child(4),
//             &:nth-child(5) {
//                 max-width: 10%;
//             }

//             &:nth-child(6) {
//                 min-width: 10%;
//             }
//         }
//     }
// }

// .main {
//     height: 45px !important;
//     flex-direction: row;
//     justify-content: space-between;
//     border-bottom: 6px solid #fff;
//     padding: 5px 0px;

//     :global {
//         .col {
//             color: $gray-2;
//             font-weight: 600;
//             height: 40px;

//             &:nth-child(2) {
//                 //  min-width: 40%;
//                 white-space: nowrap;
//                 font-size: 13px;
//             }

//             &:nth-child(3) {
//                 justify-content: flex-start;
//                 //   min-width: 17.3%;
//             }

//             &:nth-child(4) {
//                 // white-space: nowrap;
//             }

//             &:nth-child(5) {
//                 //   max-width: 12%;
//             }
//         }
//     }
// }

// .price-actions {
//     // position: sticky;
//     //right: -1.4rem;


//     display: flex;
//     align-items: center;
//     flex-direction: row;
//     //height: 100%;
//     margin-left: 8px;

//     justify-content: center;



// }

// .copy {
//     margin-right: 2px !important;
//     visibility: visible !important;
//     color: #53b2e0 !important;
//     //right: -1.4rem;
//     z-index: 1;
//     font-size: 20px !important;

//     position: relative;
//     cursor: pointer;
// }

// .previous {

//     margin-right: 0 !important;
//     color: $ternary-color !important;
//     font-size: 20px !important;
//     //right: -1.4rem;
//     position: relative;
//     cursor: pointer;
//     // z-index: 1;
// }

// .brand {
//     //background-color: red;

//     .saved {
//         background-color: #9cf5d1;
//         color: black;
//         border: 1px solid white;
//     }

//     :global {
//         .col {
//             color: $gray-2;
//             font-weight: 600;


//             &:nth-child(2) {
//                 // min-width: 15%;

//             }

//             &:nth-child(3) {
//                 justify-content: flex-end;
//                 //      min-width: 10%;
//             }

//             &:nth-child(4),
//             &:nth-child(5) {
//                 //    max-width: 15%

//             }

//             &:nth-child(6) {
//                 //    max-width: 15%;
//             }
//         }
//     }
// }

// .hearth {
//     font-family: $font-familyV2;
//     font-weight: 300;
//     font-size: 12px;

//     :global {
//         .on {
//             svg {
//                 color: $gray-2 !important;
//             }
//         }
//     }
// }





// @media screen and (max-width: 900px) {
//     .wrapperMobile {
//         //border: 1px solid #F8F8F8;
//         border-radius: 5px;
//         padding: 5px 4px 15px 4px;
//         width: 100%;
//         //border-bottom: 3px solid #ddd;
//         display: block;
//         margin-bottom: .625em;
//         width: 100%;
//         background-color: #fff;

//         .itemNameMobileTable {
//             padding: 12px 0;
//             display: flex;
//             color: #605F5F;
//             font-family: 'Rubik';
//             font-weight: 600;
//             font-size: 14px;
//         }

//         -webkit-box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);
//         box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);

//         section {
//             background-color: #F7F7F7;
//             // padding: 5px;
//             //border-radius: 8px;
//             margin-top: 5px;
//         }

//         table {
//             border: 0;
//         }

//         table caption {
//             font-size: 1.3em;
//         }

//         table thead {
//             border: none;
//             clip: rect(0 0 0 0);
//             height: 1px;
//             margin: -1px;
//             overflow: hidden;
//             padding: 0;
//             position: absolute;
//             width: 1px;
//             display: none;
//         }

//         td {

//             padding: 8px 2px;

//             border-bottom: 1px solid #ddd;
//             display: block;
//             font-size: .8em;
//             text-align: right !important;
//         }

//         td::before {
//             /*
//           * aria-label has no advantage, it won't be read inside a table
//           content: attr(aria-label);
//           */
//             content: attr(data-label);
//             font-family: 'Rubik';
//             font-weight: 400;
//             font-size: 14px;
//             color: #605F5F;
//             float: left;
//             margin-left: 15px;
//             //text-transform: uppercase;
//         }

//         table td:last-child {
//             border-bottom: 0;
//         }


//         .divDerivadoMarcaMobile {
//             ///////////
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             padding-left: 5px;
//             gap: 15px;
//             color: #605F5F;
//             font-family: 'Rubik';
//             font-weight: 600;
//             font-size: 14px;

//             .svg {
//                 font-size: 25px !important;
//             }
//         }

//         .dataLabelCenterMarca::before {
//             margin-left: 15px;
//             margin-top: 4px;
//         }
//         .dataLabelCenterObsMobile::before {

//             margin-top: 12px;
//             margin-left: 15px;
//         }

//         .brand {
//             align-items: center;
//             display: flex;
//             gap: 25px;
//         }

//         .dataLabelCenter::before {

//             // margin-top: 10px;
//         }

//         .dataLabelCenterCodigo::before {
//             margin-left: 15px;
//             margin-top: 6px;
//         }

//         .currencyOffer {
//             min-width: 95px;
//             max-width: 130px;
//             text-align: center;
//             height: 33px;
//             font-weight: 300;
//             font-size: 16px;

//         }

//         .saved {
//             background-color: #9cf5d1;
//             color: black;
//             border: 1px solid white;
//         }

//         .price-actions {
//             // position: sticky;
//             //right: -1.4rem;


//             display: flex;
//             align-items: center;
//             flex-direction: row;
//             gap: 20px;
//             //height: 100%;
//             justify-content: center;

//         }

//         .previous {



//             font-size: 26px !important;

//         }

//         .copy {
//             font-size: 26px !important;
//         }
//     }


// }
