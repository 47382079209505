@import 'styles/_variables';

.datePickerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: $font-familyV2;
}

.label {
    color: #898989;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 4px;
}

.inputContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    background: white;
    cursor: pointer;
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    font-size: 17px;
    outline-width: 0px;
    color: #898989;
}

.dateText {
    flex: 1;
    text-align: left;
    user-select: none;
}

.icon {
    font-size: 18px;
    color: $orange;
}

.dropdown {
    margin-top: 5px;
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    padding: 10px;
    padding-bottom: 0;
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    font-size: 17px;
    outline-width: 0px;
    color: #898989;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    visibility: hidden;
    width: 100%;
    z-index: 1000;
}

.dropdownOpen {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.dropdownItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 8px;

    p {
        font-size: 16px;
        color: #898989;
        font-weight: 400;
    }
}

.selectDatePicker {
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 5px 10px;
    background: white;
    cursor: pointer;
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    font-size: 16px;
    outline-width: 0px;
    color: #898989;
}
