.products-edit {
    padding: 0 10px 0 0;
    .title {
        margin-bottom: 10px;
    }
    .actions {
        display: flex;
        margin-bottom: 10px;
        justify-content: flex-start;
    }
}
.HeaderButtonsContainer{
   display: flex;
   flex-direction: row;
   gap: 14px;
   flex-wrap: wrap;
   justify-content: flex-end;

}
