@import "styles/_variables";

.containerInputV2{
    width: 100%;
    position: relative;

}
.iconContainer{
    position: absolute;
    right: 10px;
    bottom: 14px;
    color: #e5e5e5;
    font-size: 20px;
}
.inputContainer{
    margin-top: 5px;
    width: 100%;
    display: flex;
    position: relative;
}
.input{
    width: 100%;
    padding: 12px;
    color: #898989;
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
}
.input:focus{
    border-color: $orange;
}
.input:invalid{
    border-color: blue;
}
.labelInputV2{
    color: #898989;
    //padding: 24px 11px 4px 0px;
    font-family: $font-familyV2;
    font-size: 20px;

}
.labelContainer{

    display: flex;
    flex-direction: row;
    align-items: center
}
.icon{
    font-size: 13px;
    color: $orange;
    margin-left: 7px;
    //margin-bottom: 7px;
}
