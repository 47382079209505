@import "styles/_variables";

.c-btn {
    margin: 5px;
    border: 1px solid $primary-color;
    color: white;
    background: $primary-color;
    font-size: 14px;
    font-weight: $regular;
    padding: 0px 16px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    &:hover {
        -webkit-box-shadow: -4px 3px 16px 3px rgba(0, 0, 0, 0.26);
        box-shadow: -4px 3px 16px 3px rgba(0, 0, 0, 0.26);
        color: white;
    }
    &-outline {
        color: $primary-color;
        background: white;
        &:hover {
            -webkit-box-shadow: -4px 3px 16px 3px rgba(0, 0, 0, 0.26);
            box-shadow: -4px 3px 16px 3px rgba(0, 0, 0, 0.26);
            color: $primary-color;
        }
    }
    &-secondary {
        border: 1px solid $secondary-color;
        background: $secondary-color;
        color: white;
        font-weight: $bold;
    }
    &-save,
    &-ternary {
        background: $status-confirmed-bg-color;
        border: 1px solid $status-confirmed-bg-color;
        color: white;
        &:hover {
            color: white;
        }
    }
    &-remove {
        background: $red;
        border: 1px solid $red;
        color: white;
        &:hover {
            color: white;
        }
    }
    svg {
        margin-right: 4px;
    }
    .material-icons {
        font-size: $font-size;
    }
    &.input-height {
        height: $input-height;
    }

}
