@import 'styles/_variables';

.provider-edit {
    padding: 25px 0;

    .nav-item {
        color: $gray-2;
        &.active {
            color: $secondary-color;
            font-weight: 500;
            svg {
                color: $secondary-color;
            }
        }
        svg {
            margin-right: 4px;
        }
        display: flex;
    }
    .title {
        margin-bottom: 20px;
    }
    .form {
        .feedbacks {
            box-sizing: border-box;
        }
        .form-group {
            box-sizing: border-box;
            .dropdown {
                width: 100%;
            }
        }
    }
}
