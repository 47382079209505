.participationBadge {
    width: 100%;
    color: white;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 5px;
    text-align: center;

    &.high {
        background-color: #eaf7ed;
        color: #17a2b8;
    }

    &.low {
        background-color: #faeaea;
        color: #cd2a2a;
    }

    &.medium {
        background-color: #fff1e7;
        color: #ff7110;
    }
}
