@import '../../styles/variables';
@import '../../styles/mixins';

.modal-header {
    background-color: $primary-color;
    box-sizing: border-box;
    color: #fff;
    font-size: 15px;
    .modal-title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 15px !important;
        svg {
            margin-right: 6px;
            margin-top: 4px;
        }
    }
    button.close {
        color: white;
    }
}

.modal-body {
    max-height: calc(100vh - 20rem);
    overflow-y: scroll;
}

.subtitle {
    font-size: 11px;
    font-weight: 400;
}

.modal-footer {
    button {
        margin-left: 5px;
    }
}

.btn_register {
    background-color: $green-light;
    border: 1px solid $green-light;
}
