@import "styles/_variables";
.editImageContainerIcon{
    padding: 7px 9px;
    border-radius:8px;
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.2);
    margin-top: -20px;
    margin-left:60px;
    background-color: #fff;
    &:hover{
        box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.2);
    }
}
.editImageIcon{
    // position:absolute;
    // color: red ;
    color: $primary-color;
    right: 10px;
}

.form {

    .form-group {
        .image {
            margin-top: 5px;
            max-width: 200px;
            width: 200px;
            height: 150px;
            border-radius: 12px;
            &:hover {
                transform: scale(1.3);
                transition: all 1.2s ease;
            }
        }
        .current-image {
            display: grid;
            grid-gap: 2px;
            // background-color: red;
            width: 300px;
            border-radius: 30px;
        }
        .label-image{
            font-family: $font-familyV2;
            display: block;

            font-size: 22px;
            font-weight:400;
            color: #898989;
            margin-bottom: 10px !important;
        }
    }
}
.imageteste{
    border-radius: 50px;
    width: 100px;
    height: 100px;
}
.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    width: 100%;
    .sectionImage{


    }

    &:hover {
        -webkit-box-shadow: -4px 3px 16px 3px rgba(0, 0, 0, 0.26);
        box-shadow: -4px 3px 16px 3px #00000042;
        color: white;
    }
}
.divInputUpImage{
    // background-color: red;
    position: relative;
}
.labelInputImageICon{
    position: absolute;
    left:80px;
    bottom: -8px;
    cursor: pointer;

}

.file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: $primary-color;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    &:hover {
        -webkit-box-shadow: -4px 3px 16px 3px rgba(0, 0, 0, 0.26);
        box-shadow: -4px 3px 16px 3px #00000042;
        color: white;
    }
}

.file-input__label svg {
    height: 16px;
    margin-right: 4px;
}
