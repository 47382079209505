@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.tableContainerRequested {
}

.divInputFilter {
    width: 290px;
    @media only screen and (max-width: 901px) {
        width: 100%;
    }
}
.voltarFirstRow {
    @media only screen and (max-width: 901px) {
        display: none;
    }
}
.voltarSecondRow {
    display: none;
    @media only screen and (max-width: 901px) {
        display: block;
    }
}
.firstRowButtons {
    display: flex;
    align-items: flex-end;
    gap: 10px;

    @media only screen and (max-width: 901px) {
        width: 100%;
        gap: 5px;
        justify-content: space-between;
    }
}
.sectionToggleVoltar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.filtersContainerExcel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;

    // Move o último elemento para a direita
    & > *:last-child {
        margin-left: auto;
    }
}

.checkboxInputs {
    margin-top: 1.4rem;
}

.buttonsImportExport {
    // width: 100%;
    // max-width: 800px;
    margin-top: 1.4rem;
    display: flex;
    flex-direction: row;

    gap: 16px;
}

section.products {
    .prod-list-loader {
        margin: auto;
    }

    .product-header > div,
    .product-deriv > div {
        font-size: 11px;
        padding: 0px 4px;
        align-items: center;
        text-transform: uppercase;
        display: flex;
    }

    div.table {
        // border-top: 1px solid rgb(222, 226, 230);
    }

    .t-head {
        background-color: #fffefc;
        border: 1px solid #e5e5e5;
        font-family: $font-familyV2;
    }

    .t-head > .col {
        color: #707070;
        border: none;
        font-size: 13px;
        padding: 16px 0 16px 16px;
        text-align: left;
        text-transform: uppercase;
        vertical-align: middle;

        &.actions {
            text-align: center;
        }
    }

    .t-body {
        height: 50vh;
        width: 100%;
        //position: relative;

        & > .col {
            font-weight: $light;
            padding: 9px 0;
            text-transform: uppercase;
            vertical-align: middle;
        }

        &.actions {
            text-align: center;
            // width: 5rem;
        }

        div.row {
            height: fit-content;
        }
    }

    .t-head .col:first-child,
    .t-body .col:first-child {
        max-width: 150px;
        min-width: 150px;
        text-align: center;
        justify-content: center;
    }

    .actions {
        .checkbox {
            font-size: 13px;
            margin: 0;
        }

        button {
            height: 38px;
            margin-left: 10px;

            svg {
                cursor: pointer;
                font-size: 17px;
            }
        }
    }

    .remove-offer {
        cursor: pointer;
        color: $red;
    }

    .swal2-title {
        font-size: 20px !important;
    }

    .swal2-popup {
        width: 23rem !important;
    }

    .typing-confirmation-alert {
        p {
            text-align: center;
            font-size: 0.8rem;
            margin-top: 9.4px;
        }
    }

    .wrap {
        display: flex;
        width: fit-content;
        justify-content: flex-end;

        div {
            margin-left: 10px;

            .wrap-amount {
                text-align: center;
                width: 60px;
            }

            .wrap-description {
                text-align: center;
                width: 160px;
            }
        }
    }

    .show-brands {
        height: 21px;
        font-size: 9px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .product-header:first-child {
        // border-top: 1px solid rgb(222, 226, 230);
    }

    .product-header > div {
        &.requested-quantity {
            color: $primary-color;
            font-weight: 700;

            p {
                text-align: end;
                padding-right: 1.6rem;
            }
        }
    }

    .product-deriv > div {
        line-height: 40px;

        .form-control,
        .c-input {
            height: 35px;
        }

        .brand {
            display: flex;
            align-items: center;

            svg {
                margin-right: 9px;
                cursor: pointer;
            }
        }

        svg {
            margin-right: 9px;
            font-size: 18px;
            color: #f5222d;
            cursor: pointer;
        }
    }

    .last-price-tooltip {
        background-color: $secondary-color;
        color: white;
        display: flex;
        align-items: center;

        span {
            font-size: 10px;
        }
    }

    .last-price {
        display: flex;
        justify-content: center;

        span {
            cursor: default;
            line-height: normal;
            padding: 1px 4px;
            border-radius: 3px;
            font-family: $font-familyV2;
            font-size: 15px !important;
            font-weight: 500;

            &.higher {
                color: #30aa4c;
                //background-color: #30AA4C !important;
            }

            &.lower {
                color: #cd2a2a;
                // background-color: #CD2A2A !important;
            }
        }

        svg {
            color: gray;
            font-size: 11px !important;
        }
    }

    // .product-deriv:focus-within {
    //     background: #e7fbe9;
    // }

    table div.t-head > div {
        padding: 8px 16px;
    }

    .table .col {
        box-sizing: border-box;

        &.last-price {
            input {
                width: 70px;
            }
        }
    }
}

.tableContainerRequestedMobile {
    display: none;
}

@media screen and (max-width: 1343px) {
    .buttonsImportExport {
        margin-top: 0;
    }
}

@media only screen and (max-width: 1240px) {
    .tableContainerRequested {
        overflow: auto;
    }
}

@media only screen and (max-width: 900px) {
    .tableContainerRequested {
        display: none;
    }
    .checkboxInputs {
        margin-top: 0;
    }
    .buttonsImportExport {
        margin-top: 0;
    }

    .tableContainerRequestedMobile {
        margin-top: 20px;
        display: inline-block;
        width: 100%;
    }
}
