@import '../../styles/variables';
@import '../../styles/mixins';

.togleFiltersContainerRequest {}

.request-detail {
    .nav-pills {
        .nav-link {
            background: white;
            padding-left: 4px;
            font-size: 1.7rem;
            opacity: 0.2;
            color: $gray-5;

            &.active {
                opacity: 1;
            }
        }
    }

    .tab-pane.active {
        animation: slide-down 1s ease-out;
    }

    @keyframes slide-down {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    section {
        margin-top: 2rem;

        .title {
            padding: 5px;
            margin-bottom: 1rem;
            font-size: 17px;
            @include section-title;
            font-weight: $bold;
        }

        .table {
            margin: 5px;
        }
    }

    .product-header {
        background: $gray-light-6;
    }
}
