@import 'styles/_variables';

.line {
    height: 150px; /*Altura da linha*/
    width: 1px;
    background: #dddddd;
    margin-left: 140px;
    margin-right: 140px;
    margin-top: 10%;
}

.request {
    border-right: none;
    border-left: none;

    th {
        color: #4267b2;
    }
    td,
    th {
        text-align: left;
        padding-left: 9px !important;
        b {
            color: #707070;
        }
    }
}

.total {
    color: $ternary-color !important;
    font-weight: 600 !important;
}

.container {
    // height: fit-content !important;
}

.charts {
    height: fit-content !important;
}
.descriptionTableContainerAG{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 25px;


}
@media only screen and (max-width: 840px) {
    .descriptionTableContainerAG{
        display: flex;
        flex-direction: column;

        gap: 25px;


    }
}
@media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .pagebreak {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 10mm;
  }
