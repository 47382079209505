@import 'styles/_variables';

.edit-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.info {
    color: #707070;
}

.info:hover {
    color: $orange;
}

.save {
    color: $orange;
}

.actionsnewCurrencyStyle {
    box-shadow: none !important;
}

.products-edit {
    padding: 0 10px 0 0;

    .title {
        margin-bottom: 20px;
    }

    .actions {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-start;
    }
}

.formOnModal {
    padding: 15px;

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 15px;
    }
}
