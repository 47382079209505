@import 'styles/_variables';

.NewListItemAnalytics {
    margin-top: 20px;
    overflow-x: auto;
    height: 67vh;

    table {
        width: 100%;

        .center-text {
            text-align: center;
        }

        thead,
        tfoot {
            tr {
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th,
            td {
                padding: 12px 10px;
                text-align: center;

                &:first-child {
                    text-align: left;
                    padding-left: 10px;
                }

                &:last-child {
                    text-align: center;
                    padding-right: 10px;
                }
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;
                justify-content: center;
                vertical-align: middle;
                text-align: left;

                &:last-child {
                    border-right: none;
                    text-align: center;
                }

                &:first-child {
                    text-align: left;
                }
            }

            tr:nth-child(even) {
                background-color: #f7f7f7;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .NewListItemAnalytics {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }

        tFoot {
            display: none;
        }

        .list-actionsGrupoP {
            justify-content: flex-end;
            gap: 22px;

            svg {
                font-size: 25px;
            }
        }
    }
}
