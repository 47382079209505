@import "../../../styles/variables";
@import "../../../styles/mixins";

.imageProduct{
    width: 90px;
    height: 80px;
    border-radius: 100%;
    cursor: pointer;
    &:hover {
        transform: scale(1.5);
        transition: all 1.5s ease;
    }
}

.infosContainerReq{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 15px;
    flex-wrap: wrap;
    margin: 15px;
}
.infosContainerReqItem{
    padding: 10px 15px;
    border-radius: 8px;
    p{
        font-size: 18px;
        font-weight:500;
    }
}

.listProductsBrands {
    margin-top: 20px;
    overflow-x: auto;


    .tableProductsBrands {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;

            }

            tr {

                // padding: 12px 0px !important;
                background-color: #FFFEFC;
                border: 1px solid #E5E5E5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605F5F;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605F5F;

                text-align: left;
                justify-content: center;
                vertical-align: middle;


            }

            td:last-child {
                border-right: none;

            }

            tr:nth-child(odd) {
                background-color: #F7F7F7;

            }

            tr {
                tr {
                    margin-top: 40 !important;

                }

                td:first-child {
                    padding-left: 10px;
                }




            }
        }


    }
}
