@import 'styles/_variables';

.content-info {
    .button-tab {
        background-color: $more-information-modal-button-inactived;
        border: 1px solid $more-information-modal-button-inactived;
        color: $more-information-modal-button-inactived-color;
        font-size: 1.4rem;
        padding: 15px;
        border-radius: 8px;
    }

    .actived {
        background-color: $more-information-modal-button-actived;
        border: 1px solid $more-information-modal-button-actived;
    }
}
