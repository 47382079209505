@import 'styles/_variables';

.filtersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;
    align-items: flex-end;
}

.filterItem {
    flex: 1 1 200px;
    //margin-left: 10px;
    max-width: 250px;
}

.filterButton {
    height: 47px !important;
    // margin-left: 10px;
}

.tabsContainer {
    display: flex;
    flex: row;
    // background-color: blue;
    flex: 1;
    gap: 10px;
    // align-items: center;
    // margin-left: 10px;
    // margin-right: 10px;
    //margin-bottom: 10px;
}

.tabButton {
    // border: 1px solid;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 740px) {
    .tabsContainer {
        width: 100%;
        margin-left: 0px;
        height: 47px;
        flex: 1;
    }

    .filterButton {
        margin-left: 0px;
    }

    .filtersContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        gap: 15px;
        margin-top: 15px;
        align-items: unset;
    }

    .filterItem {
        flex: none;
        //margin-left: 10px;
        width: 100%;
        max-width: unset;
    }
}
