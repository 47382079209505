.div_buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 5px;
    margin-left: 1rem;
}

.divtoggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    // margin-top: 16px;

    .pedidoContent {
        color: #212529;
        font-family: 'Rubik', sans-serif;
        font-size: 16px;
        font-weight: 300;
    }
}

.checkBoxDiv {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: flex-end;
    // height: 35px;
}

@media (max-width: 765px) {
    .checkBoxDiv {
        // height: 40px;
    }
}
