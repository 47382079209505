@import 'styles/_variables';
@import 'styles/_mixins';

.request-products-filters {
    padding: 12px;
    animation: fadeIn 0.3s ease both;
    border-radius: 5px;
    > .row {
        height: 70px;
    }
    select,
    input {
        height: 34px;
        font-size: 12px;
    }

    .c-search-icon {
        bottom: calc(50% - 15px);
    }

    .filter-title {
        @include section-title;
        margin-bottom: 13px;
        font-size: 14px;
        font-weight: 400;
        color: $primary-color;
    }
    .checkbox {
        font-size: 12px;
    }
}
