@import 'styles/_variables';
@import 'styles/_mixins';

.modal {
    min-width: 40vw;
}

.comment {
    display: flex;
    align-items: center;
    span {
        margin-left: 5px;
        color: #4267B2;
        font-size: 13px;
    }
    svg {
        color: $green-light;
    }
}
