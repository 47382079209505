@import 'styles/_variables';

.headerContainerDesktop {
    min-height: 35px;
    height: 8vh;
    background-color: $orange-4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    position: fixed;
    z-index: 99;
    overflow: visible;
}

.HeaderLogo {
    width: 122px;
    max-height: 50px;
    border-radius: 8px;
    // object-fit: contain;
}

.logoContainer {
    margin-left: 4vw;
}

.iconsContainer {
    display: flex;
    flex-direction: row;
}

.socialMediaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
    padding-left: 15px;
    margin-right: 15px;
    border-left: 1px solid white;
    min-height: 35px;
}

.userDescriptionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-left: 15px;
}

.avatarIcon {
    font-size: 30px;
    min-height: 30px;
    color: #fff;
}

.headerIcons {
    cursor: pointer;
    margin-left: 7px;
    width: 25px;
}

.dynamicIconWhatsSVG {
    cursor: pointer;
    margin-left: 10px;
    width: 25px;
}

.iconBadgeText {
    font-family: $font-familyV2;
    font-size: 13px;
}

.iconBadge {
    margin-left: 12px;
    padding: 1px 12px 1px 32px;
    height: 26px;
    min-width: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    position: relative;
    cursor: pointer;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

.avatarOnBadge {
    position: absolute;
    left: -10px;
}

.suportNumbersContainer {
    background-color: #fff;
    border-radius: 12px;
    position: absolute;
    width: 200px;
    bottom: -120px;
    left: 0;
    z-index: 2;
    -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.21);
    padding: 5px;
    animation: slideInFromTopSupport 0.3s;
}

@keyframes slideInFromTopSupport {
    0% {
        transform: translatey(-4%);
        opacity: 0;
    }

    100% {
        transform: translatey(0);
        opacity: 1;
    }
}

.SuportNumberDiv {
    margin-bottom: 7px;
    // background-color: $orange-4;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.userNameHeader {
    font-family: $font-familyV2;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

.userDescriptionText {
    font-family: $font-familyV2;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

.marginTopSet {
    margin-top: 5px;
}

.containerHeader {
    height: 8vh;
}

@media only screen and (max-width: 740px) {
    .headerContainerDesktop {
        display: none;
    }

    .containerHeader {
        height: unset;
    }

    .HeaderLogo {
        max-height: 47px;
    }

    .testess {
        display: flex;
        flex-direction: row;
    }

    .marginTopSet {
        margin-top: 85px;
    }
}
.removepaddinMobile {
    @media only screen and (max-width: 900px) {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}
.newContainerMainRoot {
    // padding: 0;
    // height: 100%;

    width: 100%;
    padding: 0px 10px 20px 0px;
    @media only screen and (max-width: 900px) {
        padding: 0px 5px 10px 0px;
    }
    .row {
        // width: 100%;
        // margin: 0;
        // padding: 0;
        .body {
            min-height: 100vh;
            //max-width: calc(100vw - 56px);
            max-width: calc(98vw - 56px);
            padding: 24px 42px;

            // background-color: red !important;
        }

        &.env {
            display: flex;
            justify-content: center;
            background-color: $red;
            color: white;
            align-items: center;

            p {
                padding: 10px;
                text-align: center;
            }
        }
    }
}
