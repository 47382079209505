@import 'styles/_variables';

.products-add {
    padding: 0 10px 0 0;

    .title {
        margin-bottom: 20px;
    }

    .actions {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-start;
    }
}

.createdProductContainer {
    display: flex;
    flex-direction: row;
    // border: 1px solid #c6c6c6;
    padding: 10px 15px;
    border-radius: 6px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    width: 100%;

    @media only screen and (max-width: 740px) {
        flex-direction: column;
        align-items: center;
        width: 95%;
    }
}

.createdProductImage {
    width: 150px;
    object-fit: contain;
    border-radius: 8px;
    margin-right: 15px;
    border-radius: 6px;
    @media only screen and (max-width: 740px) {
        width: 130px;
        margin-left: 0;
        margin-bottom: 15px;
    }
}

.infoRowNewProd {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    gap: 4px;

    h5 {
        margin-left: 20px;
        text-align: center;
        color: $orange;
    }

    p {
        font-weight: 500;
    }

    span {
        font-weight: 400;
    }
}
.sectionInfosCreatedProdFlags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}
.sectionInfosCreatedProd {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    gap: 20px;

    @media only screen and (max-width: 740px) {
        flex-direction: unset;
        // display: grid;
        // flex-wrap: wrap;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        // grid: 1fr 1fr;
    }
}

.InfosContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    gap: 15px;
    flex: 1;

    @media only screen and (max-width: 740px) {
        width: 100%;
        gap: 20px;
    }
}

.titleRowNewProd {
    h5 {
        font-size: 24px;
        font-weight: 500;
    }

    border-bottom: 1px solid #cecece;
}

.buttonsContainerCreatedProduct {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    gap: 15px;
    margin-top: 15px;

    section {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        gap: 15px;
    }

    @media only screen and (max-width: 740px) {
        flex-direction: column;

        section {
            width: 95%;
            flex-direction: column;
        }
    }
}

.createdContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 850px;
    margin: 0 auto;

    @media only screen and (max-width: 740px) {
        // flex-direction: row;
        max-width: unset;
        width: 100%;
    }
}
