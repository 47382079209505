.buying-company-add {
    padding: 0 10px 0 0;
  
    .title {
      margin-bottom: 20px;
    }
  
    .actions {
      display: flex;
      margin-bottom: 20px;
      justify-content: right;
    }
  
    .divider {
      margin: 20px 0 40px;
    }
  }