@import 'styles/_variables';

.ranking-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $orange-3;
    border-radius: 4px;
    width: 30px;
    height: 28px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.ranking-number {
    font-size: 16px;
    font-weight: 600;
    color: $orange;
    text-align: center;
}
