@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.representative-clients {
    .actions {
        display: flex !important;
        margin-bottom: 20px !important;
        justify-content: flex-start !important;
        width: 100%;
    }

    .info-actions {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;

        .counters {
            width: 370px;

            .statuses {
                display: flex;
                justify-content: space-between;

                .status {
                    display: flex;
                    align-items: center;
                    font-weight: $regular;

                    &.active {
                        color: #00a65a;
                    }

                    &.pending {
                        color: #f3ae76;
                    }

                    &.inactive {
                        color: #dd4b39;
                    }

                    .material-icons {
                        font-size: 1.5rem;
                        margin-right: 5px;
                    }
                }
            }
        }

        .actions {
            .btn {
                margin-left: 10px;
            }
        }
    }

    // .list {
    //     margin-top: 20px;
    //     .table {
    //         thead {
    //             th:first-child {
    //                 padding-left: 10px;
    //             }
    //         }
    //         tbody {
    //
    //             tr {
    //                 td:first-child {
    //                     padding-left: 10px;
    //                 }
    //                 .actions {
    //                     .action {
    //                         margin: 0 5px 0 0;
    //                         padding: 3px 5px 0;
    //                         &.info {
    //                             color: $buying-companies-action-info-color;
    //                         }
    //                         .material-icons {
    //                             font-size: 1.8rem;
    //                         }
    //                     }
    //                 }
    //                 .link-salesman {
    //                     align-items: center;
    //                     background-color: #00a65a;
    //                     border-radius: 4px;
    //                     color: #ffffff;
    //                     display: flex;
    //                     height: 30px;
    //                     justify-content: center;
    //                     width: 30px;
    //                 }
    //                 .status {
    //                     display: block;
    //                     height: 20px;
    //                     width: 10px;
    //                     &.active {
    //                         background-color: #00a65a;
    //                     }
    //                     &.pending {
    //                         background-color: #f3ae76;
    //                     }
    //                     &.inactive {
    //                         background-color: #dd4b39;
    //                     }
    //                 }
    //                 td{
    //                     line-height: 1.5;
    //                 }
    //             }
    //         }
    //         .info {
    //             cursor: pointer;
    //             color: $secondary-color;
    //             &:hover {
    //                 color: $buying-companies-hover-icon;
    //             }
    //         }
    //         .people {
    //             cursor: pointer;
    //             color: $secondary-color;
    //             &:hover {
    //                 color: $buying-companies-hover-icon;
    //             }
    //         }
    //     }
    // }
}

.td_Participacao {
    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    min-width: 70px;
    height: 100%;
}

.div_participation {
    width: 100%;
    color: white;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 5px;
    text-align: center;

    // opacity: 0.7;

    &.high {
        background-color: #eaf7ed;
        color: #17a2b8;
    }

    &.low {
        background-color: #faeaea;
        color: #cd2a2a;
    }

    &.medium {
        background-color: #fff1e7;
        color: #ff7110;
    }
}

.tooltip-icon {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip-icon .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: $secondary-color;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;

    bottom: 115%;
    left: 50%;
    margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-icon:hover .tooltiptext {
    visibility: visible;
}

.rowRepresentante {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.repInfoDiv {
    display: flex;
    flex-direction: column;
    min-width: 130px;

    h6 {
        font-size: 16px;
        font-family: $font-familyV2;
        font-weight: 400;
        color: #707070;
        margin-bottom: 5px;
    }

    p {
        font-size: 14px;
        font-family: $font-familyV2;
        font-weight: 300;
        color: #a1a1a1;
    }
}

.tdSellers-customer {
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: center;
}
.divSellers-customer {
    width: 100%;
    max-height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.countSeller-customer {
    width: 100%;
    max-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 5px 5px 4px;
    border-radius: 5px;
    background-color: $orange;
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-top: 4px;
    cursor: pointer;
}

.newListCLientsRepresentatives {
    margin-top: 20px;
    overflow-x: auto;

    .newTable {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            // tr:nth-child(4n+4) {
            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;

                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }

                .link-salesman {
                    align-items: center;
                    background-color: #00a65a;
                    border-radius: 4px;
                    color: #ffffff;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    width: 30px;
                }

                .status {
                    display: block;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        background-color: #00a65a;
                    }

                    &.pending {
                        background-color: #f3ae76;
                    }

                    &.inactive {
                        background-color: #dd4b39;
                    }
                }
            }
        }

        .actions {
            > * {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
                // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17a2b8 !important;
                }

                &:nth-child(2) {
                    color: #ff7110;
                }

                &:nth-child(3) {
                    color: #30aa4c;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}

.td_telefone {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 900px) {
    .newListCLientsRepresentatives {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            // text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }
        .actions {
            justify-content: flex-end;
            gap: 20px;
            align-items: center;
            svg {
                font-size: 30px;
            }
        }
    }
    .td_telefone {
        min-height: 29px;
        display: flex;

        div {
            float: right;
            margin-top: -2px;
        }
    }

    .td_Participacao {
        div {
            justify-content: flex-end;
        }
    }

    .div_participation {
        width: unset;
        padding: 5px 20px;
    }

    .divSellers-customer {
        justify-content: flex-end;
        height: 11px;
    }
    .countSeller-customer {
        margin-top: 0;
        margin-bottom: 10px;
    }
}
