@import 'styles/_variables';

.header-info-modal {
    background-color: $direct-sale-parameter-header_background;
    padding: 25px 0;

    .name {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        color: $primary-color;
    }

    .lottie {
        width: 5rem;
    }
}

.condition-body {
    margin: 20px 0;

    .form-condition {
        justify-content: flex-start;
        margin: 0 25px;

        .form-label-direct-sale-parameter {
            font-size: 1.3rem;
            color: $direct-sale-parameter-form-label;
        }

        .form-input-direct-sale-parameter {
            margin: 10px 0px;
        }
        .form-select-direct-sale-parameter {
            margin: 10px 0px;
            color: $direct-sale-parameter-form-color-select;
        }

        .form-check-label {
            margin: 15px;
        }

        .save {
            background-color: $direct-sale-parameter-save-button;
            border-color: $direct-sale-parameter-save-button;
            color: $direct-sale-parameter-title-button;
        }
    }
}

.containerInputsParams{

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 300px));
    gap: 30px;
    justify-content: center;
    align-items: end;

}
.formContainerSellparams{
   // display: flex;
   // flex-direction: column;
   // pad: 30px;

}
@media only screen and (max-width: 740px) {
    .containerInputsParams{

        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: unset;

    }
    .formContainerSellparams{


    }

}

.listRepresentatives {
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden !important;


    .newTableRepresentatives {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;

            }

            tr {

                // padding: 12px 0px !important;
                background-color: #FFFEFC;
                border: 1px solid #E5E5E5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605F5F;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605F5F;

                text-align: left;
                justify-content: center;
                vertical-align: middle;


            }

            td:last-child {
                border-right: none;

            }

            tr:nth-child(odd) {
                background-color: #F7F7F7;

            }

            tr {
                tr {
                    margin-top: 40 !important;

                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;

                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }

                .link-salesman {
                    align-items: center;
                    background-color: #00a65a;
                    border-radius: 4px;
                    color: #ffffff;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    width: 30px;
                }

                .status {
                    display: block;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        background-color: #00a65a;
                    }

                    &.pending {
                        background-color: #f3ae76;
                    }

                    &.inactive {
                        background-color: #dd4b39;
                    }
                }
            }
        }


        .svgactionsRepresentatives {
            font-size: 20px;
            margin-left: 3px;
            cursor: pointer;
        }


        .actionsRepresentatives {
            svg {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
                // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17A2B8 !important;
                }

                &:nth-child(2) {
                    color: #FF7110;
                }

                &:nth-child(3) {
                    color: #30AA4C;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .ContainerInputsModalRoutes {
        flex-wrap: wrap;
    }

    .listRepresentatives {


        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;

        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;


        }

        table td:last-child {
            border-bottom: 0;
        }


        .list-actionsGrupoP {
            justify-content: flex-end;
            gap: 22px;

            svg {
                font-size: 25px;
            }
        }

        .tdPerfilDiv {
            justify-content: flex-end;
            align-items: center;

            p {
                margin-top: 2px !important;
            }
        }

        .toggleTdDiv {
            display: flex;
            justify-content: flex-end;
        }

        .tdDivActionsRepresentatives {
            justify-content: flex-end;
            gap: 25px;

            .svgactionsRepresentatives {
                font-size: 27px;

            }
        }
    }


}
