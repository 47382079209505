@import '../../../styles/variables';
@import '../../../styles/mixins';

.products-add {
    padding: 0 10px 0 0;

    .title {
        margin-bottom: 20px;
    }

    .actions {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-start;
    }
}

.list-actionsGrupoP {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 17px;
    :global(button) {
        width: fit-content;
        padding: 0 8px;
    }
    svg {
        font-size: 18px;
    }
}

.tbl-actions {
    text-align: center !important;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: $secondary-color;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;

    bottom: 115%;
    left: 50%;
    margin-left: -60px;
}

.iconsActionsExclude {
    cursor: pointer;
    color: '#ff1010' !important;
}

.iconsActionsEdit {
    cursor: pointer;
    color: $orange !important;
}

.listProdusctsGroup {
    margin-top: 20px;
    overflow-x: auto;

    .newTableProdusctsGroup {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(even) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .listProdusctsGroup {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            //text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }

        .list-actionsGrupoP {
            justify-content: flex-end;
            gap: 22px;
            svg {
                font-size: 25px;
            }
        }
    }
}
