@import 'styles/_variables';

@keyframes slideInFromRight {
    0% {
        transform: translatex(4%);
        opacity: 0;
    }

    100% {
        transform: translatex(0);
        opacity: 1;
    }
}

.upload-button {
    transition: margin 100ms ease-in-out;
    animation: 0.5s ease 0s 1 slideInFromRight;

    .button {
        font-family: $font-familyV2;
        font-weight: 600;
        letter-spacing: 1.1px;
        color: #fff;
        line-height: 1.5em;
        font-size: 13px;
        display: flex;
        gap: 8px;
        flex-direction: row;
        align-items: center;

        padding: 5px;
        border-radius: 5px;
        padding: 13px 15px;

        background-color: $orange;
        border: none;

        &:active {
            background-color: orange-4;
            box-shadow: 0 0 2px $orange;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .hidden-input {
        display: none;
    }
}
