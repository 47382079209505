@import 'styles/_variables';

.section-form-modal {
    gap: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    max-width: 600px;

    form {
        width: 100%;
    }
}

.toggleButton {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.form-group {
    width: 100%;
}

.divButtons {
    display: flex;
    width: 100%;
    justify-content: end;
}

.c-btn-success {
    display: flex;
    gap: 3px;

    background-color: $green-light;
    border: 1px solid $green-light;
}

.c-btn-cancel {
    background-color: $red;
    border: 1px solid $red;
}
