@import "styles/_variables";

@keyframes slideInFromRight {
  0% {
    transform: translatex(4%);
    opacity: 0;
  }

  100% {
    transform: translatex(0);
    opacity: 1;
  }
}

.buttonCancel {

  margin: 10px 0px 10px 10px;

  padding: 5px;
  border-radius: 5px;

  padding: 14px 54px;
  background-color: #ffff;
  border: 1px solid $orange;
  font-family: $font-familyV2;
  font-weight: 500;
  color: $orange;
  transition: margin 100ms ease-in-out;
  animation: 0.5s ease 0s 1 slideInFromRight;
}

.buttonSave {
  margin: 10px 0px 10px 10px;

  padding: 5px;
  border-radius: 5px;

  padding: 15px 55px;
  background-color: $orange;
  color: #ffff;

  font-family: $font-familyV2;
  font-weight: 500;

  transition: margin 120ms ease-out;

  animation: 0.5s ease 0s 1 slideInFromRight;

}

.buttonCancel:active,
.buttonSave:active {
  margin: 11px 0px 9px 10px
}

.buttonCancel:hover,
.buttonSave:hover {
  box-shadow: 0 0 2px $orange;

}