@import '../../styles/variables';
@import '../../styles/mixins';

.wrapper {
    color: orange;
    margin-right: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}

.icon {
    float: left;
    &:hover {
        filter: hue-rotate(90deg);
    }
}
