@import 'styles/_variables';

.header-info-modal {
    background-color: $direct-sale-parameter-header_background;
    padding: 25px 0;

    .name {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        color: $primary-color;
    }

    .lottie {
        width: 5rem;
    }
}

.condition-body {
    margin: 20px 0;

    .form-condition {
        justify-content: flex-start;
        margin: 0 25px;

        .form-label-direct-sale-parameter {
            font-size: 1.3rem;
            color: $direct-sale-parameter-form-label;
        }

        .form-input-direct-sale-parameter {
            margin: 10px 0px;
        }
        .form-select-direct-sale-parameter {
            margin: 10px 0px;
            color: $direct-sale-parameter-form-color-select;
        }

        .form-check-label {
            margin: 15px;
        }

        .save {
            background-color: $direct-sale-parameter-save-button;
            border-color: $direct-sale-parameter-save-button;
            color: $direct-sale-parameter-title-button;
        }
    }
}

.btn-modal{
    text-align: right;
}
