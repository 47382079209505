@import 'styles/_variables';
@import 'styles/_mixins';

.togleFiltersContainer {
    display: none;
}

.requests {
    .alert-danger {
        z-index: -1;
    }

    .remove-seller {
        color: $red;
        margin-right: 5px;
        cursor: pointer;
    }

    .participation {
        width: 100%;
        color: white;
        font-weight: 400;
        font-size: 14px;
        border-radius: 4px;
        padding: 4px 5px;

        // opacity: 0.7;
        cursor: pointer;

        &.high {
            background-color: #eaf7ed;
            color: #17a2b8;
        }

        &.low {
            background-color: #faeaea;
            color: #cd2a2a;
        }

        &.medium {
            background-color: #fff1e7;
            color: #ff7110;
        }
    }

    .actions {
        > * {
            font-size: 15px;
            margin-left: 5px;
            padding: 4px;
            cursor: pointer;
            border: 1px solid white;

            svg {
                &:hover {
                    filter: hue-rotate(75deg);
                }
            }

            &:first-child {
                svg {
                    color: $primary-color !important;

                    &.req-info-icon {
                        color: #7fc9ff !important;
                    }
                }
            }

            &:nth-child(2) {
                svg {
                    color: $secondary-color;
                }
            }

            &:nth-child(3) {
                color: $ternary-color;
            }
        }
    }

    // .list {
    //     .table {
    //         tbody {
    //             .id {
    //                 text-decoration: underline;
    //                 color: $gray-dark-2;
    //                 font-weight: 600;
    //                 span {
    //                     display: flex;
    //                     align-items: center;
    //                     svg {
    //                         margin-left: 5px;
    //                         color: $secondary-color;
    //                         cursor: pointer;
    //                     }
    //                 }
    //             }
    //             .client-name {
    //                 color: $green-light;
    //                 font-size: 12px;
    //                 font-weight: $bold;
    //                 span {
    //                     font-weight: 400;
    //                     cursor: pointer;
    //                     background-color: $gray-5;
    //                     padding: 2px 9px;
    //                     display: block;
    //                     width: fit-content;
    //                     color: white;
    //                     border-radius: 2px;
    //                     white-space: nowrap;
    //                     &:hover {
    //                         box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    //                     }
    //                     svg {
    //                         padding-right: 5px;
    //                     }
    //                 }
    //             }
    //             .products-count {
    //                 color: $green-light;
    //                 font-size: 12px;
    //                 font-weight: $bold;
    //                 span {
    //                     font-weight: 400;
    //                     opacity: 0.9;
    //                     margin: auto;
    //                     cursor: pointer;
    //                     background-color: $ternary-color;
    //                     padding: 2px 9px;
    //                     display: block;
    //                     width: 4rem;
    //                     color: white;
    //                     border-radius: 2px;
    //                     white-space: nowrap;
    //                     &:hover {
    //                         box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    //                     }
    //                     svg {
    //                         padding-right: 5px;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}

.numCotacaoBox {
    display: flex;

    align-items: center;
    justify-content: center;
    padding: 3px 2px;
    background-color: $orange-3;
    color: $orange;
    border-radius: 4px;

    p {
        &:hover {
            text-decoration: underline;
        }
    }
}

.numCotacaoBoxIconContainer {
    color: $orange;
    margin-left: 5px;
    font-size: 16px;
    cursor: pointer;
    padding: 3px 4px 3px 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 250ms ease;

    &:hover {
        transition: 250ms ease;
        background-color: $orange;

        svg {
            color: #fff;
        }
    }
}

.numCotacaoBoxIcon {
    // color: #FF7110;

    font-size: 16px;
    cursor: pointer;
}

.tdStateName {
    font-weight: 400 !important;
}

.client-name {
    display: flex;
    flex-direction: column;
    color: #17a2b8;
    border-radius: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 400;
    padding: 7.5px 2px 7.5px 4px;
    text-transform: uppercase;
    background-color: rgba(23, 162, 184, 0.1);
    cursor: pointer;
    span {
        text-transform: capitalize;
        color: #605f5f;
    }
}

.productCountBox {
    font-weight: 400;
    min-width: 100px;
    display: flex;
    color: #30aa4c;
    align-items: center;
    justify-content: center;
    padding: 6px 2px;
    background-color: rgba(48, 170, 76, 0.1);
    cursor: pointer;
}

.productCountIcon {
    margin-right: 3px;
    font-size: 14px;
}

.removeSellerIcon {
    margin-right: 3px;
    font-size: 16px;
    color: #cd2a2a;
}

.tdParticipacao {
    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    min-width: 70px;
    height: 100%;
}

.divTdAcoes {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
        font-size: 20px;
    }
}

.listRequests {
    margin-top: 20px;
    overflow-x: none;
    overflow-y: auto;

    .newTableRequests {
        width: 100%;

        thead {
            th:first-child {
                padding-left: 10px;
            }

            tr {
                // padding: 12px 0px !important;
                background-color: #fffefc;
                border: 1px solid #e5e5e5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605f5f;
            }

            th {
                padding: 12px 10px;
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605f5f;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
            }

            td:last-child {
                border-right: none;
            }

            tr:nth-child(even) {
                background-color: #f7f7f7;
            }

            tr {
                tr {
                    margin-top: 40 !important;
                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;

                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }

                .link-salesman {
                    align-items: center;
                    background-color: #00a65a;
                    border-radius: 4px;
                    color: #ffffff;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    width: 30px;
                }

                .status {
                    display: block;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        background-color: #00a65a;
                    }

                    &.pending {
                        background-color: #f3ae76;
                    }

                    &.inactive {
                        background-color: #dd4b39;
                    }
                }
            }
        }

        .actions {
            > * {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
                // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17a2b8 !important;
                }

                &:nth-child(2) {
                    color: #ff7110;
                }

                &:nth-child(3) {
                    color: #30aa4c;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate3d(0, -20%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.requestTableMobile {
    display: none;
}

.requestTableDesktop {
    display: block;
}

@media screen and (max-width: 900px) {
    .togleFiltersContainer {
        display: flex;
    }

    .requestTableMobile {
        display: block;
    }

    .requestTableDesktop {
        display: none;
    }

    .listRequests {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right !important;
        }

        tbody td {
            div {
                display: flex;
                justify-content: flex-end;
                min-width: unset !important;
            }
        }

        table td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            // text-transform: uppercase;
            vertical-align: middle;
        }

        .tdParticipacao {
        }

        .participation {
            width: unset;
            padding: 5px 20px;
        }

        table td:last-child {
            border-bottom: 0;

            svg {
                font-size: 22px;
            }
        }

        .client-name {
            padding: 7.5px 14px 7.5px 14px;
        }

        .divTdAcoes {
            gap: 30px;

            svg {
                font-size: 25px !important;
            }
        }

        .numCotacaoBox {
            padding: 6px 20px;
        }
    }
}

.showOnlyInMobile {
    display: none;

    @media screen and (max-width: 900px) {
        display: default;
    }
}

.rowIdStatus {
    display: flex;
    justify-content: space-between;

    span {
        font-weight: 500;
    }
}

.client-name-mobile {
    display: flex;
    // color: #17A2B8;
    // border-radius: 5px;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    // padding: 7.5px 2px 7.5px 4px;
    text-transform: uppercase;
    // background-color: rgba(23, 162, 184, 0.1);
    // cursor: pointer;
}

.storeNameMobile {
    display: flex;
    justify-content: flex-start;
    font-weight: 600;

    // border-bottom: 1px solid linear-gradient(to right, $primary-color, transparent);
    border-bottom: 2px solid;
    border-image: linear-gradient(
            to right,
            $primary-color -30%,
            transparent 95%
        )
        100% 0%;
    padding: 5px 10px;
    gap: 12px;

    // background-image: linear-gradient(to right, $primary-color -100%, transparent 30%);
    .buttonStoreName {
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        width: 32px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: $primary-color;

        &:active {
            // background-color: ;

            opacity: 0.7;
            translate: 1px 1px;
        }
    }

    svg {
        color: #fff;
        font-size: 19px;
    }

    p {
        // padding-top: 3px;
        // padding: 2px 5px;
        font-size: 0.8rem;
        // margin-left: 1px;
    }
}

.centralizedRow {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.columnInfo {
    display: flex;
    flex-direction: column;
    align-content: center;

    p {
        font-size: 0.8rem;
        text-align: center;
    }

    span {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 400;
    }
}

.numCotacaoBox-mobile {
    display: flex;
    // background-color: pink;
    // align-items: stretch;
    // justify-content: ;
    // padding: 3px 2px;
    // background-color: $primary-color2;
    color: $primary-color;
    gap: 8px;

    .cotacaoButton-mobile {
        border: 1px solid $primary-color2;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 6px;
        border-radius: 5px; // border: 1px solid ;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);

        svg {
            font-size: 1rem;
        }

        &:active {
            // background-color: ;
            box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
            opacity: 0.7;
            translate: 0px 1px;
        }
    }

    p {
        font-weight: 400;

        &:hover {
            // text-decoration: underline;
        }
    }
}

.titleAndInfoRow {
    // background-color: pink;
    // width: 100%;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
        font-weight: bold;
        margin-right: 5px;
    }

    span {
        font-weight: 300;
    }
}

.estadoEntregadiv {
    display: flex;
    justify-content: space-between;
    // background-color: pink;
}

.productCountBoxMobile {
    font-weight: 400;
    min-width: 100px;
    display: flex;
    color: #30aa4c;
    border: 1px solid #30aa4c60;
    align-items: center;
    justify-content: center;
    padding: 6px 2px;
    background-color: rgba(48, 170, 76, 0.1);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    // cursor: pointer;
    &:active {
        // background-color: ;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        opacity: 0.7;
        translate: 0px 1px;
    }
}

.columData {
    margin-left: -30px;

    @media screen and (max-width: 900px) {
        margin-left: unset;
    }
}

.trMobile {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.06);
    padding: 7px 0;
    margin: 2px;
    td {
        // padding: 0 50px;
    }
}
.columnInfoVendedor {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.85rem;
    p {
        font-size: 0.8rem;
        text-align: center;
        font-weight: bold;
    }

    span {
        margin-top: 4px;
        text-align: center;
        font-size: 0.8rem;
        font-weight: 400;
    }
}
.trashButtonSeller {
    border: 1px solid rgba(255, 0, 0, 0.4);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 6px;
    border-radius: 5px; // border: 1px solid ;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);

    svg {
        font-size: 1rem;
        color: rgba(255, 0, 0, 0.7);
    }

    &:active {
        // background-color: ;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        opacity: 0.7;
        translate: 0px 1px;
    }
}
.buttonAction {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 6px; // border: 1px solid ;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

    svg {
        font-size: 1rem;
        // color: rgba(255,0,0,0.7);
    }

    &:active {
        // background-color: ;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        opacity: 0.7;
        translate: 0px 1px;
    }
}
