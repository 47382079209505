@import 'styles/_variables';
@import 'styles/_mixins';

#preview-products {
    .prod div {
        display: flex;
        align-items: center;
        svg {
            margin-right: 5px;
            cursor: pointer;
            color: $secondary-color;
            font-size: 1.1rem;
        }
    }
    .company-name {
        color: $gray-2;
        font-size: 24px;
        font-weight: 400;
        font-family: $font-familyV2;
        padding-left: 10px !important;
        text-transform: uppercase;
    }
    .divCompanyName{
        color: $gray-2;
        font-size: 24px;
        font-weight: 400;
        font-family: $font-familyV2;
       
        
    }
    .header {
        div.col {
            display: flex;
            align-items: center;
            input {
                height: 30px;
            }
            > * {
                padding-left: 20px;
            }
            > *:first-child {
                padding-left: 0 !important;
            }
        }
    }
}


.listProductsPreview {
    margin-top: 20px;
    overflow-x: auto;
   

    .newTableProductsPreview {
         width: 100%;

        thead {
            th:first-child {
                padding-left: 34px;

            }

            tr {

                // padding: 12px 0px !important;
                background-color: #FFFEFC;
                border: 1px solid #E5E5E5;
                font-size: 13px; //before 16px
                font-family: $font-familyV2;
                font-weight: 400;
                color: #605F5F;
            }

            th {
                padding: 12px 10px;
                
            }
        }

        tbody {
            td {
                padding: 6px 8px;
                font-family: $font-familyV2;
                font-weight: 300;
                font-size: 13px;
                color: #605F5F;

                text-align: left;
                justify-content: center;
                vertical-align: middle;
               
                
            }
            td:last-child{
                border-right: none;

            }
            tr:nth-child(even) {
                background-color: #F7F7F7;
            
            }

            tr {
                tr {
                    margin-top: 40 !important;

                }

                td:first-child {
                    padding-left: 10px;
                }

                .actions {
                    justify-content: center;
                    vertical-align: middle;
                    .action {
                        margin: 0 5px 0 0;
                        padding: 3px 5px 0;

                        &.info {
                            color: $buying-companies-action-info-color;
                        }

                        .material-icons {
                            font-size: 1.8rem;
                        }
                    }
                }

                .link-salesman {
                    align-items: center;
                    background-color: #00a65a;
                    border-radius: 4px;
                    color: #ffffff;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    width: 30px;
                }

                .status {
                    display: block;
                    font-weight: $bold;
                    text-align: center;
                    padding: 3px 9px;
                    color: white;
                    border-radius: 3px;
                    font-size: 10px;

                    &.active {
                        background-color: #00a65a;
                    }

                    &.pending {
                        background-color: #f3ae76;
                    }

                    &.inactive {
                        background-color: #dd4b39;
                    }
                }
            }
        }

        .actions {
            >* {
                font-size: 17px;
                margin-left: 5px;
                padding: 4px;
                cursor: pointer;
               // border: 1px solid white;

                &:hover {
                    filter: hue-rotate(75deg);
                }

                &:first-child {
                    color: #17A2B8  !important;
                }

                &:nth-child(2) {
                    color:#FF7110;
                }

                &:nth-child(3) {
                    color: #30AA4C;
                }
            }

            svg {
                font-size: 13px;
            }
        }

        .people {
            cursor: pointer;
            color: $secondary-color;

            &:hover {
                color: $buying-companies-hover-icon;
            }
        }
    }
}
